*{
  outline: none !important;
}
a{
  transition: $time;
  &:hover{
    opacity: $opacity;
  }
}
p{
  line-height: 1.7;
}
button,a{
  &:focus{
    box-shadow: none !important;
  }
  &.focus{
    box-shadow: none !important;
  }
}

hr{
  border-top: 2px solid $gray-300 !important;
}
.container{
  padding: 0 2rem !important;
}

section{
  margin-bottom: 6rem;
  @media screen and (max-width: 767px) {
    margin-bottom: 3rem;
  }
}
.quote{
  position: relative;
  padding-left: 88px;
  &:before{
    content: '';
    width: 64px;
    height: 46px;
    @include icon($url:'../img/quote.svg');
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
  @media screen and (max-width: 767px) {
    &:before{
      position: relative;
      width: 31px;
      height: 22px;
      margin-bottom: 1rem;
    }
  }
}

/* + fancybox */
.fancybox-bg{
  background: rgba(41, 42, 61, 0.8);;
}
.fancybox-toolbar{
  .fancybox-button--zoom,
  .fancybox-button--play,
  .fancybox-button--thumbs{
    display: none !important;
  }
  .fancybox-button.fancybox-button--close{
    top: 1.5rem;
    right: 1.5rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    position: relative;
    transition: $time;
    @include icon($url:'../img/close-fancy.svg');
    svg{
      display: none;
    }
  }
}
.fancybox-navigation{
  .fancybox-button {
    padding: 0;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    background-image: url("../img/arr-fancy.svg");
    background-size: contain !important;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5 !important;
    >div{
      display: none;
    }
    &.fancybox-button--arrow_left{
      left: 2rem;
      //transform: rotate(90deg);
    }
    &.fancybox-button--arrow_right{
      right: 2rem;
      transform: rotate(-180deg);
    }
  }
}
/* + scrol */
::-webkit-scrollbar-button {
  background-repeat: no-repeat;
  width: 5px;
  height: 0px;
  position: absolute;
}
::-webkit-scrollbar-track {
  background-color: $gray-500;
  position: absolute;
}
::-webkit-scrollbar-thumb {
  position: absolute;
  border-radius: 2px;
  background-color: $gray-700;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  width: 5px;
  border: 1px solid $gray-700;
}
::-webkit-resizer {
  background-repeat: no-repeat;
  width: 5px;
  height: 0px;
  position: absolute;
}
::-webkit-scrollbar {
  width: 5px;
  position: absolute;
}
/* - scrol */
@media screen and (max-width: 991px) {

  .fancybox-navigation{
    .fancybox-button {
      top: auto;
      bottom: 3rem;
      margin: auto;
      &.fancybox-button--arrow_left{
        left: 0;
        right: 95px;
      }
      &.fancybox-button--arrow_right{
        right: 0;
        left: 95px;
      }
    }
  }
}
/* - fancybox */
