@mixin icon($url, $bg-size:contain){
  background: url($url) no-repeat center;
  background-size: $bg-size;
}

@mixin absolute($top:0,$right:0,$bottom:0,$left:0,$width:100%,$height:100%){
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  width: $width;
  height: $height;
}

@mixin flex($justify-content:space-between,$align-items:center,$flex-wrap:wrap){
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-wrap: $flex-wrap;
}

@mixin lastChild-margin0{
  &:last-child{
    margin: 0;
  }
}
@mixin inline($vertical-align:middle){
  display: inline-block;
  vertical-align: $vertical-align;
}
@mixin dotted($nowrap:nowrap,$hidden:hidden,$ellipsis:ellipsis){
  white-space: $nowrap;
  overflow: $hidden;
  text-overflow: $ellipsis;
}
@mixin white-space{
  white-space: pre;
}
@mixin object-fit-cover{
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
@mixin turn($trans:rotate(180deg)){
  transform: $trans;
}
/* ----------site */
