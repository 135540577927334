$font-family-base: 'PT Root UI lightn';
$cg-light:'Cormorant Garamond light';
$cg-bold:'Cormorant Garamond bold';
$ui-bold:'PT Root UI bold';
$roboto:'Roboto';
$line-height-base:1.7;
$border-radius:0.375rem;
$body-color:#00023A;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1000px,
        xl: 1384px
);

  /* + color */
$gray-300:#EDEEF2;
$gray-500:#CBCCD7;
$gray-600:#CBCCD7;
$gray-700:#85869D;
$black:#292A3D;
$blue: #00023A;
$blue-light:#282A66;
$red:#EE5D49;
$green:#61D1A2;
$secondary:$gray-700;
/* - color */

/* + link */
$link-color:$black;
$link-hover-color:$gray-500;
$link-hover-decoration:none;
/* - link */

$radius:0.375rem;
$shadow: 0px 4px 8px rgba(62, 63, 78, 0.1);
$shadow2: 0px 12px 24px rgba(0, 2, 58, 0.14);
$time:0.3s;
$opacity:0.8;

/* button */
$input-btn-font-size:1rem;
$input-btn-padding-y:1.1875rem;
$input-btn-padding-x:1.5rem;
$btn-font-family:$ui-bold;
$btn-active-box-shadow:0;
$btn-border-radius:0;
$btn-border-radius-sm:0;
$btn-padding-y-sm:0.6875rem;
$btn-padding-x-sm:0.6875rem;
$btn-font-size-sm:1rem;

  /* - button */

/* + select */
$custom-select-padding-y:1.5rem;
$custom-select-padding-x:1.625rem;

/* - select */

/* + input */
$input-height:2rem;
$input-padding-x:0rem;
$input-padding-y:0rem;
$input-font-size:1rem;
$input-line-height:1;
$input-color:$black;
$input-placeholder-color:$gray-700;
$input-border-radius:0;
$input-border-width:0;
$input-font-family:$font-family-base;
$input-focus-box-shadow:0;
/* - input */

/* + moadl */
$modal-backdrop-opacity:0.8;
/* - modal */
