.ic{
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  margin: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.vk-ic{
  background-image: url("../img/vk-gray.svg");
}
.tw-ic{
  background-image: url("../img/tv-gray.svg");
  background-size: 84%;
}
.fb-ic{
  background-image: url("../img/fb-gray.svg");
  background-size: auto 90%;
}
.in-ic{
  background-image: url("../img/in-gray.svg");
  background-size: 84%;
}
.in-black-ic{
  background-image: url("../img/instagram-black.svg");
}
.point-ic{
  background-image: url("../img/point-gray.svg");
  background-size: auto 90%;
}
.list-ic{
  background-image: url("../img/list-gray.svg");
}
.arr-b-ic{
  background-image: url("../img/play-blue.svg");
  background-size: 10px;
  transform: rotate(90deg);
}
.arr-r-ic{
  background-image: url("../img/play-blue.svg");
  background-size: 10px;
}
.arr-t-ic{
  background-image: url("../img/play-blue.svg");
  background-size: 10px;
  transform: rotate(-90deg);
}
.check-ic{
  background-image: url("../img/ok.svg");
}
.plus-ic{
  background-image: url("../img/plus.svg");
}
.close-ic{
  background-image: url("../img/close.svg");
}
.time-ic{
  background-image: url("../img/time.svg");
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 0.5rem;
}