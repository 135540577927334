.btn {
  &.btn-outline-secondary{
    border-color:$gray-500 !important;
    &:hover{
      background-color: transparent !important;
      border-color: $blue-light !important;
      color: $blue-light !important;
    }
  }
  &.btn-primary {
    min-width: 160px;
    &:hover {
      background-color: $blue-light !important;
    }
  }
  &.btn-outline-light {
    min-width: 160px;
    border-color: $gray-700 !important;
    &:hover {
      border-color: #fff !important;
      background-color: transparent !important;
      color: #fff !important;
    }
  }
  &.btn-outline-primary {
    min-width: 160px;
    transition: $time;
    position: relative;
    &:before {
      content: '';
      opacity: 0;
      @include absolute;
      border: 1px solid $blue;
      transition: $time;
    }
    &:hover {
      &:before {
        opacity: 1;
      }
      background-color: transparent !important;
      color: $blue !important;
    }
  }
}
.boxBtn{
  width: 4.5rem;
  height: 4.5rem;
  border: 2px solid $gray-300;
  box-shadow: none;
  background-color: transparent;
  position: relative;
  transition: $time;
  display: block;
  background-image: none !important;
  &:before{
    content: '';
    @include absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    opacity: 1;
    transition: $time;
  }
  &:after{
    content: '';
    @include absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    opacity: 0;
    transition: $time;
  }
  &:hover{
    box-shadow: $shadow;
    &:before{
      opacity: 0;
    }
    &:after{
      opacity: 1;
    }
  }
  &.active{
    border-color: $blue;
    &:before{
      opacity: 0 !important;
    }
    &:after{
      opacity: 1 !important;
    }
  }
  &.list-ic{
    &:before{
      background-image: url(../img/list-gray.svg);
    }
    &:after{
      background-image: url(../img/list-blue.svg);
    }
  }
  &.point-ic{
    &:before{
      background-image: url(../img/point-gray.svg);
    }
    &:after{
      background-image: url(../img/point-blue.svg);
    }
  }
}
.pointBtn {
  width: 4.5rem;
  height: 4.5rem;
  border: 2px solid $gray-300;
  box-shadow: none;
  background-color: transparent;
  position: relative;
  transition: $time;
  display: block;
  &:before {
    content: '';
    @include absolute;
    @include icon($url: '../img/point-gray.svg', $bg-size: 1.375rem);
    opacity: 1;
    transition: inherit;
  }
  &:after {
    content: '';
    @include absolute;
    @include icon($url: '../img/point-blue.svg', $bg-size: 1.375rem);
    opacity: 0;
    transition: inherit;
  }
  &:hover {
    box-shadow: $shadow;
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }
}

.link {
  color: $gray-500;
  font-family: $ui-bold;
  transition: $time;
  &:hover {
    color: $black;
    &.white {
      color: #fff;
    }
  }
  &:active, &.active {
    color: $black;
    &.white {
      color: #fff;
    }
  }

}

.close {
  width: 1.5rem;
  height: 1.5rem;
  min-width: 16px;
  @include icon($url: '../images/cross-ic.svg');
  background-size: 16px;
  display: block;
  opacity: 1;
  &:hover{
    opacity: 1 !important;
    @include icon($url: '../images/cross-ic2.svg');
    background-size: 16px;
  }
}
.closeBtn {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
  border: 1px solid $gray-300;
  position: relative;
  transition: $time;
  background-color: #fff;
  &:before {
    content: '';
    @include absolute;
    @include icon($url: '../img/cross-gray.svg', $bg-size: 1.625rem);
    opacity: 1;
    transition: inherit;
  }
  &:after {
    content: '';
    @include absolute;
    @include icon($url: '../img/cross-blue.svg', $bg-size: 1.625rem);
    opacity: 0;
    transition: inherit;
  }
  &:hover {
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }
  @media screen and (max-width: 991px) {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.icBtn{
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  border: 1px solid $gray-300;
  position: relative;
  transition: $time;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  &.fb{
   &:before{
     background-image: url("../img/fb-gray.svg");
     background-size: 0.75rem;
   }
    &:after{
      background-image: url("../img/fb-blue.svg");
      background-size: 0.75rem;
    }
  }
  &.in{
    &:before{
      background-image: url("../img/in-gray.svg");
      background-size: 1.25rem;
    }
    &:after{
      background-image: url("../img/in-blue.svg");
      background-size: 1.25rem;
    }
  }
  &.vk{
    &:before{
      background-image: url("../img/vk-gray.svg");
      background-size: 1.5rem;
    }
    &:after{
      background-image: url("../img/vk-blue.svg");
      background-size: 1.5rem;
    }
  }
  &.tw{
    &:before{
      background-image: url("../img/tv-gray.svg");
         background-size: 1.25rem;
    }
    &:after{
      background-image: url("../img/tv-blue.svg");
         background-size: 1.25rem;
    }
  }
  &.play{
    @include icon($url:'../img/play-blue.svg', $bg-size:13px);
    &.white{
      @include icon($url:'../img/play-white.svg', $bg-size:13px);
    }
  }
  &:before{
    content: '';
    @include absolute;
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center;
    transition: inherit;
  }
  &:after{
    content: '';
    @include absolute;
    opacity: 0;
    background-repeat: no-repeat;
    background-position: center;
    transition: inherit;
  }
  &:hover{
    &:before{
      opacity: 0;
    }
    &:after{
      opacity:1;
    }
  }
}

.hamburger {
  padding: 0;
  opacity: 1 !important;
}
.hamburger-box {
  width: 36px;
  height: 30px;
  display: block;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 36px;
  height: 2px;
  background-color: $blue;
}
.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after{
  background-color: $blue;
}
.hamburger-inner {
  margin-top: -1px;
}
.hamburger-inner::before {
  top: -13px;
}
.hamburger--emphatic .hamburger-inner::after {
  top: 13px;
}

.playBtn{
  color: #fff;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: 0.875rem;
  font-family: $roboto;
  .play-ic{
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    margin-right: 0.75rem;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #fff;
    position: relative;
    &:before{
      content: '';
      background-image: url("../img/play-border.svg");
      @include absolute;
      background-size: 1rem;
      background-repeat: no-repeat;
      background-position: center;
      left: 2px;
      opacity: 1;
      transition: $time;
    }
    &:after{
      content: '';
      background: url('../img/play-white.svg');
      @include absolute;
      background-size: 1.125rem;
      background-repeat: no-repeat;
      background-position: center;
      left: 2px;
      opacity: 0;
      transition: $time;
    }

  }
  &:hover{
    .play-ic{
      &:before{
        opacity: 0;
      }
      &:after{
        opacity: 1;
      }
    }

  }
}

/* + tipEl */
.tipEl{
  display: flex;
  background-color: #080A11;
  padding: 8px 1.5rem 8px 1rem;
  max-width: 392px;
  width: calc(100% - 1rem);
  box-shadow: $shadow2;
  position: fixed;
  bottom: 1.5rem;
  z-index: 10;
  left: 0;
  right: 0;
  margin: auto;
  align-items: center;
  justify-content: space-between;

  .text{
    width: calc(100% - 32px);
    font-size: 12px;
    color: #fff;
    margin: 0;
    max-width: 288px;
    line-height: 1.4;
  }
  .closeBtn{
    background-color: transparent;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border: none;
    cursor: pointer;
    &:hover{
      background-color: transparent;
      &:before{
        opacity: 1;
      }
      &:after{
        opacity: 0;
      }
    }
  }
  @media screen and (max-width: 767px) {
    bottom: 0.5rem;
  }
}
/* - tipEl */

/* + successEl */
.successEl{
  font-family: $ui-bold;
  text-align: center;
  span{
    width: 3rem;
    height: 3rem;
    background-color: $blue;
    border-radius: 100%;
    margin-left: 2.25rem;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    background-image: url("../img/ok-white.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    &:before{
      content: '';
      @include absolute;
      width: 4.5rem;
      height: 4.5rem;
      border: 1px solid $blue;
      border-radius: 100%;
      margin: auto;
      left: -0.75rem;
    }
  }
  @media screen and (max-width: 1199px) {
    margin-top: 2rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 12px;
    span{
      width: 2rem;
      height: 2rem;
      margin-left: 1.5rem;
      &:before{
        width: 3.5rem;
        height: 3.5rem;
      }
    }
  }
}
/* - successEl */

/* + quantity */
.quantity{
  .input-group-prepend{
    .btn{
      @include icon($url:'../images/minus-ic.svg');
      background-size: 12px;
      &:hover{
        @include icon($url:'../images/minus-ic2.svg');
         background-size: 12px;
      }
    }
  }
  .input-group-append{
    .btn{
      @include icon($url:'../images/plus-ic.svg');
      background-size: 12px;
      &:hover{
        @include icon($url:'../images/plus-ic2.svg');
         background-size: 12px;
      }
    }
  }
  .input-group{
    width: 120px;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #EDEEF2;
  }
  .input-group-append,
  .input-group-prepend{
    .btn{
      border: none;
      width: 20px;
      min-width: 20px !important;
      height: 20px;
      padding: 0;
      strong{
        display: none;
      }
    }
  }
  .form-control{
    width: 52px !important;
    height: 24px;
    font-size: 16px;
    line-height: 20px;
    color: $black;
    padding: 0 6px;
  }
  &.text-pack{
    .input-group-append:before {
      content: 'ÑÐ¿';
    }
  }
  &.text-piece{
    .input-group-append:before {
      content: 'ÑÑ';
    }
  }
  &.text-pack, &.text-piece{
     .form-control {
      width: 20px !important;
      max-width: 20px !important;
      min-width: 20px !important;
      padding: 0;
      margin-left: 0.5rem;
      margin-right: 2px;
    }
    .input-group-append:before {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
      margin-right: 0.5rem;
      line-height: 20px;
    }
  }
}
/* - quantity */