@font-face {
  font-family: 'Cormorant Garamond light';
  src: url('../fonts/CormorantGaramond-Light.eot');
  src: url('../fonts/CormorantGaramond-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CormorantGaramond-Light.woff2') format('woff2'),
  url('../fonts/CormorantGaramond-Light.woff') format('woff'),
  url('../fonts/CormorantGaramond-Light.ttf') format('truetype'),
  url('../fonts/CormorantGaramond-Light.svg#CormorantGaramond-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Cormorant Garamond bold';
  src: url('../fonts/CormorantGaramond-Bold.eot');
  src: url('../fonts/CormorantGaramond-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CormorantGaramond-Bold.woff2') format('woff2'),
  url('../fonts/CormorantGaramond-Bold.woff') format('woff'),
  url('../fonts/CormorantGaramond-Bold.ttf') format('truetype'),
  url('../fonts/CormorantGaramond-Bold.svg#CormorantGaramond-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

//-------------------------
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Regular.woff2') format('woff2'),
  url('../fonts/Roboto-Regular.woff') format('woff'),
  url('../fonts/Roboto-Regular.ttf') format('truetype'),
  url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

//-------------------------
@font-face {
  font-family: 'PT Root UI bold';
  src: url('../fonts/PTRootUI-Bold.eot');
  src: url('../fonts/PTRootUI-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PTRootUI-Bold.woff2') format('woff2'),
  url('../fonts/PTRootUI-Bold.woff') format('woff'),
  url('../fonts/PTRootUI-Bold.ttf') format('truetype'),
  url('../fonts/PTRootUI-Bold.svg#PTRootUI-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

//-------------------------
@font-face {
  font-family: 'PT Root UI lightn';
  src: url('../fonts/PTRootUI-Light.eot');
  src: url('../fonts/PTRootUI-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PTRootUI-Light.woff2') format('woff2'),
  url('../fonts/PTRootUI-Light.woff') format('woff'),
  url('../fonts/PTRootUI-Light.ttf') format('truetype'),
  url('../fonts/PTRootUI-Light.svg#PTRootUI-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@import "./import-scss/variables.scss";
@import './import-scss/mixin';
@import './import-scss/reset';
@import './import-scss/icons';
@import './import-scss/font';
@import './import-scss/button';
@import './import-scss/form';
@import './import-scss/modal';
@import './import-scss/elements';
@import "~bootstrap/scss/bootstrap";
@import "~select2/src/scss/core.scss";
@import "~select2-bootstrap4-theme/src/select2-bootstrap4.scss";
@import "~air-datepicker/dist/css/datepicker.css";
@import "~hamburgers/dist/hamburgers.css";
@import "~animate.css/animate.css";
@import "~owl.carousel/dist/assets/owl.carousel.css";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";

/* + styleGuide */
.styleGuide {
  .itemSg {
    border: 2px solid;
    border-bottom: 0;
    border-color: #002B46;
    &:last-child {
      border-bottom: 2px solid;

    }
    .sg_input {
      display: none;
    }
    .sg_input:checked ~ .sg_label {
      background-color: #002b46;
      color: #fff;

      & ~ .box {
        display: block;
        background: #ccc;
      }
    }
    .box {
      display: none;
      background-color: #fff;
      padding: 20px 20px;
      .box-item {
        margin-bottom: 25px;
        max-width: 1200px;
        background-color: #fff;
        padding: 5px;
        textarea {
          margin-top: 10px;
        }
      }
    }
    .sg_label {
      width: 100%;
      font-size: 1.4rem;
      padding: 10px 30px;
      margin: 0;
      cursor: pointer;
    }
    textarea {
      width: 100%;
      min-height: 70px;
    }
  }

}

@media screen and (max-width: 768px) {
  .styleGuide .itemSg .box {
    padding: 0;
    .box-item {
      padding: 10px 10px;
    }
    .box-item > .box-item {
      padding: 0;
    }
    .navbar {
      width: 100vw;
      margin-left: -10px;
    }
  }
  .styleGuide .itemSg {
    border: 0;
  }

}

/* - styleGuide */
/* + loader */
.loader {
  display: flex;
  padding-bottom: 50px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 9999;
  position: fixed;
  .logo {
    @include icon($url: '../img/logo-full.svg');
    width: 250px;
    height: 100px;
    display: block;
  }
}

/* - loader */
/* + header */
header {
  width: 100%;
  position: absolute;
  top: 2rem;
  z-index: 5;
  transition: $time;
  &.main {
    top: 4.5rem;
    @media screen and (min-width: 768px) {
      position: absolute;
      &.hidden {
        transform: translate3d(0, calc(-100% - 4.5rem), 0);
      }
    }
  }
  &.white, &.main {
    .nav .nav-item .nav-link.active .text {
      color: #fff;
    }
    .logo {
      @include icon($url: '../img/logo.svg');
      width: 370px;
      height: 80px;
    }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      background-color: #fff;
    }
  }
  &.open {
    .logo {
      @include icon($url: '../img/logo.svg');
    }
    .nav .nav-item .nav-link.active .text {
      color: #fff;
    }
  }
  .d-flex {
    align-items: center;
    justify-content: space-between;
  }
  .hamburger {
    display: none;
  }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 36px;
    height: 2px;
    background-color: $black;
  }
  .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
    background-color: #fff;
  }
  .nav {
    align-items: center;
    margin-left: auto;
    .nav-item {
      padding: 0;
      margin-right: 1.25rem;
      &:last-child {
        margin-right: 0;
      }
      .nav-link {
        padding: 0.5rem 0.375rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .text-wrap {
          overflow: hidden;
          line-height: 1.1875rem;
          display: block;
          position: relative;

        }
        .text {
          color: #CBCCD7;
          font-family: $ui-bold;
          padding: 0;
          transition: $time;
          font-size: 1rem;
          display: block;
          &:before {
            content: attr(data-hover);
            //color: #fff;
            line-height: 1.1875rem;
            position: absolute;
            left: 0;
            top: 100%;
            transition: $time;
            transform: translate3d(0, 0, 0);
            font-family: "PT Root UI bold";
          }
        }
        &:hover {
          &.active .text-wrap .text {
            transform: translateY(0);
          }

          .text-wrap .text {
            transform: translateY(-100%);
          }
        }
        &.active {
          .text {
            color: $black;
          }
        }
      }
    }
  }
  .phone-h{
    color: #CBCCD7;
    padding-left: 1.5rem;
    margin-left: 1.5rem;
    border-left: 1px solid #CBCCD7;
    padding-right: 1.5rem;
    border-right: 1px solid #CBCCD7;
    line-height: 1.5rem;
    font-size: 1rem;
    font-family: 'PT Root UI lightn';
  }
  .basket {
    z-index: 1;
    position: relative;
    margin-left: 1.5rem;
    .basketLink {
      cursor: pointer;
      @include icon($url: '../img/basket-ic.svg');
      width: 1.5rem;
      height: 1.5rem;
      position: relative;
      display: block;
      &:hover ~ .tooltip-basket {
        display: block;
      }
      .count {
        line-height: 1rem;
        min-width: 1rem;
        text-align: center;
        font-size: 12px;
        color: $gray-700;
        position: absolute;
        right: -3px;
        bottom: -3px;
        border-radius: 100%;
        font-family: $ui-bold;
      }
    }
    .tooltip-basket {
      position: absolute;
      top: calc(100% + 0.5rem);
      background-color: #fff;
      box-shadow: 0px 12px 24px rgba(0, 2, 58, 0.14);
      right: 0;
      padding: 1.5rem 2rem;
      width: 360px;
      z-index: -10;
      display: none;
      &:hover {
        display: block;
      }
      &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        width: 100%;
        left: 0;
        height: 0.5rem;
        display: block;
      }
      .tooltipGoods {
        max-height: 336px;
        overflow: auto;
        width: calc(100% + 1rem);
        padding-right: 1rem;
        margin-bottom: 1.5rem;
        .item {
          display: flex;
          margin-bottom: 1.5rem;
          @include lastChild-margin0;
          .img-wrap {
            width: 64px;
            min-width: 64px;
            max-height: 96px;
            img {
              width: 100%;
              object-fit: contain;
            }
          }
          .wrap {
            padding-left: 1.5rem;
            width: calc(100% - 64px);
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            .name {
              margin: 0;
              line-height: 1.5;
              padding: 12px 0;
              width: 100%;
            }
            .signature {
              margin: 0;
              line-height: 1.5;
              padding: 3px 0;
              width: 100%;
            }
            .flex {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              width: 100%;
              p {
                margin: 0;
                line-height: 1.5;
              }
              .price {
                font-family: $ui-bold;
              }
              .pack {
                color: $gray-700;
              }
            }
          }
        }
      }
      .resultPrice {
        line-height: 2rem;
        text-align: right;
        margin-bottom: 1.5rem;
        font-family: $ui-bold;
      }
      .btn {
        width: 100%;
      }
    }
  }
  .logo {
    width: 223px;
    height: 48px;
    @include icon($url: '../img/logo-full.svg');
    display: block;
    transition: 0s;
  }
  .social {
    display: none;
  }
  @media screen and (max-width: 1199px) {
    &.main {
      top: 4rem;
    }
    &.white, &.main {
      .logo {
        width: 224px;
        height: 48px;
      }
    }

    .logo {
      width: 224px;
      height: 48px;
    }
  }
  @media screen and (max-width: 991px) {
    .phone-h {
      padding-left: .5rem;
      margin-left: .5rem;
      padding-right: .5rem;
      border: none;
    }
    .basket {
      margin-left: .5rem;
    }
    .nav .nav-item {
      margin-right: .5rem;
    }
  }
  @media screen and (max-width: 767px) {
    &.main {
      top: 3rem;
      .logo {
        width: 195px;
        height: 42px;
      }
      .nav {
        top: -3rem;
      }
    }
    .logo {
      width: 144px !important;
      height: 31px !important;
      z-index: 2;
      order: 1;
    }
    .nav {
      z-index: 1;
      display: none;
      position: absolute;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: -2rem;
      &:before {
        content: '';
        @include absolute;
        background-color: $blue;
        opacity: 1;
      }

      &.active {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 5rem;
      }
      .nav-item {
        width: 100%;
        margin: 0.5rem 0;
        text-align: center;
        .nav-link {
          display: inline-flex;
        }
      }
    }
    .nav.active ~ .social {
      display: block;
      position: absolute;
      z-index: 3;
      top: calc(100vh - 144px);
      left: 0;
      width: 100%;
      text-align: center;
      .icBtn {
        margin: 0 0.75rem;
      }
    }
    .hamburger {
      display: block;
      z-index: 2;
      order: 3;
    }
    .phone-h{
      display: none;
    }
    .nav.active ~ .phone-h{
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      display: block;
      margin: auto;
      width: calc(100% - 4rem);
      text-align: center;
      padding-bottom: 1.5rem;
      padding-top: 1.5rem;
      border-bottom: 1px solid #85869D;
      border-top: 1px solid #85869D;
      max-width: 476px;
      top: calc(100vh - calc(3rem + 37vh));
    }
    .basket {
      order: 2;
      margin-left: auto;
      margin-right: 1.5rem;
      .tooltip-basket {
        display: none !important;
      }
    }
  }
}

/* - header */
/* + footer */
footer {
  border-top: 1px solid $gray-300;
  padding-top: 1.5rem;
  background-color: #fff;
  .d-flex {
    justify-content: space-between;
    .col{
      width: 25%;
      padding: 0 0 0 1.5rem;
      border-left: 1px solid $gray-300;
      &:first-child{
        padding-left: 0;
        border: none;
        display: flex;
        align-items: center;
      }
    }
  }
  .btn.btn-outline-secondary{
    width: 112px;
    margin: auto 0;
    display: block;
  }
  .nav {
    width: 100%;
    padding: 0.25rem 0;
    .nav-item {
      width: 100%;
      margin: 0.5rem 0;
      &:first-child{
        margin-top: 0;
      }
      &:last-child{
        margin-bottom: 0;
      }
      .nav-link {
        padding: 0;
        line-height: 1.5rem;
        font-family: $ui-bold;
        color: $gray-500;
        transition: $time;
        &:hover {
          color: $black;
        }
      }
    }
  }
  .contact-f{
    padding: 0.25rem 0;
    .text{
      line-height: 1.5rem;
      font-size: 1rem;
      display: table;
      margin-bottom: 1rem;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .text-f {
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  .social-f{
    .social {
      display: block;
      width: auto;
      height: auto;
      .icBtn {
        display: table;
        border: none;
        padding-left: 26px;
        margin: 0 0 0.75rem;
        width: auto;
        height: auto;
        &.fb{
          &:before{
            background-image: url("../img/fb-ic2.svg");
            background-size: 20px;
          }
          &:after{
            background-image: url("../img/fb-ic2-2.svg");
            background-size: 20px;
          }
        }
        &:before {
          width: 20px;
          height: 20px;
          min-width: 20px;
          margin: auto;
          right: auto;
        }
        &:after{
          width: 20px;
          height: 20px;
          min-width: 20px;
          margin: auto;
          right: auto;
        }
        &:hover{
          span{
            color: $black;
          }
        }
        span{
          color: $gray-500;
          font-size: 16px;
          font-family: $ui-bold;
          transition: $time;
        }
      }
    }
  }
  .line-f {
    padding: 1.5rem 0;
    margin-top: 1.5rem;
    background-image: url("../img/pipples2.png");
    background-color: $blue;
    .signature {
      margin: 0;
      color: #fff;
      line-height: 1.5rem;
      a {
        color: inherit;
        transition: $time;
        &:hover {
          opacity: $opacity;
        }
      }
    }
    .link{
      margin-left: auto;
      line-height: 1.5rem;
    }
  }

  @media screen and (max-width: 1199px) {
    .d-flex{
      .col{
        &:nth-child(3){
          width: 35%;
          max-width: 35%;
          min-width: 35%;
        }
        &:nth-child(4){
          width: 15%;
          min-width: 15%;
          max-width: 15%;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .d-flex {
      .col {
        padding: 0 0 0 1rem;
        &:nth-child(1){
          width: 20%;
          max-width: 20%;
          min-width: 20%;
        }
        &:nth-child(2){
          width: 25%;
          min-width: 25%;
          max-width: 25%;
        }
        &:nth-child(3){
          width: 35%;
          max-width: 35%;
          min-width: 35%;
        }
        &:nth-child(4){
          width: 20%;
          min-width: 20%;
          max-width: 20%;
        }
      }
    }

  }
  @media screen and (max-width: 767px) {
    padding-top: 1.5rem;
    .d-flex {
      flex-wrap: wrap;
      .col{
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
        padding: 1.5rem 0;
        border: none;
        &:nth-child(1){
          padding: 0;
        }
        &:nth-child(3){
          position: relative;
          &:before{
            content: '';
            position: absolute;
            top: 0;
            left: -2rem;
            right: 0;
            margin: auto;
            background-color: $gray-300;
            display: block;
            height: 1px;
            width: calc(100% + 4rem);
          }
          &:after{
            content: '';
            position: absolute;
            bottom: 0;
            left: -2rem;
            right: 0;
            margin: auto;
            background-color: $gray-300;
            display: block;
            height: 1px;
            width: calc(100% + 4rem);
          }
        }
      }
    }
    .btn.btn-outline-secondary {
      width: 100%;
    }
    .nav {
      width: 100%;
      padding: 0;
    }
    .contact-f{
      padding: 0;
    }
    .text-right {
      width: 100%;
      margin-top: 2.5rem;
      position: relative;
      padding: 3rem 0;
      overflow: hidden;
      &:before {
        content: '';
        width: 100vw;
        left: calc((100vw - 540px) / (-2) - 32px);
        top: 0;
        position: absolute;
        height: 2px;
        background-color: $gray-300;
        display: block;

      }
    }
    .social {
      .icBtn:last-child {
        margin-bottom: 0;
      }
    }
    .line-f {
      padding: 1.5rem 0;
      margin-top: 0;
      .link{
        margin: auto;
      }
      .d-flex {
        flex-direction: column-reverse;
        justify-content: center;
      }
      .signature {
        width: 100%;
        text-align: center;
        &:first-child {
          margin-top: 1.25rem;
        }
      }
    }


  }
  @media screen and (max-width: 575px) {
    .text-right {
      &:before {
        content: '';
        width: calc(100% + 4rem);
        left: -2rem;

      }
    }
  }
}

/* - footer */
/* + main-img */
.main-img {
  width: 100%;
  height: 100vh;
  padding: 1rem;
  position: relative;
  z-index: 1;
  margin: 0 0 5rem;
  .img {
    position: absolute;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    top: 1rem;
    left: 1rem;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    z-index: -1;
  }
  .container {
    height: 100%;
    position: relative;
  }
  .playBtn {
    bottom: 4.5rem;
    position: absolute;
    left: 2rem;
  }
  .downBtn {
    height: 116px;
    width: 16px;
    @include icon($url: '../img/down-text.svg');
    display: block;
    position: absolute;
    border: 0;
    padding: 0;
    right: 2rem;
    bottom: 32vh;
    span {
      width: 1px;
      height: calc(32vh - 1rem);
      display: block;
      background-color: #fff;
      margin: auto;
      top: calc(100% + 1rem);
      left: 4px;
      right: 0;
      position: absolute;
    }
  }
  @media screen and (max-width: 991px) {
    margin: 0 0 4rem;
  }
  @media screen and (max-width: 767px) {
    margin: 0 0 3rem;
    .img {
      border-radius: 20px;
    }
  }
}

.socialMain {
  position: absolute;
  left: 2rem;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  li {
    list-style-type: none;
    display: block;
    margin: 1rem 0;
    a {
      color: $gray-300;
      margin: 0;
      display: block;
      font-family: $ui-bold;
      font-size: 0;
      &.fb-text {
        @include icon($url: '../img/Facebook-text.svg');
        height: 69px;
        width: 16px;
      }
      &.in-text {
        @include icon($url: '../img/Instagram.svg');
        height: 80px;
        width: 16px;
        margin-left: 1px;
      }
    }
  }
}

/* - main-img */

/* + sectionText */
.sectionText {
  hr {
    margin: 0;
  }
  .row.indent {
    padding: 6rem 0;
  }
  @media screen and (max-width: 1199px) {
    h1 {
      font-size: 2rem;
    }
  }
  @media screen and (max-width: 991px) {
    .row.indent {
      padding: 4rem 0;
    }
    .btn {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .btn {
      max-width: 280px;
      width: 100%;
      margin-bottom: 3rem;
    }
  }
  @media screen and (max-width: 767px) {
    .row.indent {
      padding: 1.5rem 0;
    }

  }
}

/* - sectionText */

/* + articleSlider */
.articleSlider {
  .owl-stage-outer {
    padding: 1rem 0;
    .owl-stage {
      display: flex;
    }
  }
  .img-wrap {
    position: relative;
    width: 40%;
    padding-top: 384px;
    img {
      @include absolute;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  .article-text {
    width: 60%;
    padding-left: 1.5rem;
    padding-right: 3rem;
    .text {
      height: 81px;
      min-height: 80px;
      overflow: hidden;
      width: 100%;
      margin-bottom: 1.5rem;
      padding-right: 12px;
      &.hidden {
        height: 81px;
        overflow: hidden;
        position: relative;
        word-break: break-all;
        &:before {
          content: '...';
          position: absolute;
          bottom: 0;
          right: 0;
          background: #fff;
        }
      }
    }
    .h2 {
      max-height: 114px;
      overflow: hidden;
      display: inline-block;
      width: 100%;
    }
  }
  .signature {
    margin-bottom: 0.5rem;
    width: 100%;
  }
  .item {
    width: calc(100vw - 6rem);
    max-width: 1318px;
    display: flex;
    align-items: center;
    box-shadow: $shadow;
    transition: $time;
    &:hover {
      box-shadow: 0px 6px 13px rgba(0, 2, 58, 0.14);
      .btn.btn-outline-primary {
        opacity: 1;
      }
    }
  }

  .btn.btn-outline-primary {
    @media screen and (min-width: 991px) {
      opacity: 0;
    }
  }

  .owl-nav {
    button {
      position: absolute;
      height: calc(100% - 4rem);
      width: calc((100vw - 1370px) / 2);
      top: 1rem;
      z-index: 5;
      opacity: 0;
      background-color: red !important;
      &.owl-prev {
        left: 0;
      }
      &.owl-next {
        right: 0;
      }
      @media screen and (max-width: 1400px) {
        width: calc((100vw - 1340px) / 2);
      }
      @media screen and (max-width: 1384px) {
        width: 1.5rem;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .item {
      flex-wrap: wrap;
      width: calc(100vw - 4rem);
    }
    .img-wrap {
      width: 100%;
      padding-top: 49%;
    }
    .article-text {
      width: 100%;
      padding: 1.5rem;
      display: flex;
      flex-wrap: wrap;
      .h2 {
        margin-bottom: 1rem !important;
        height: 48px;
      }
    }
    .text {
      display: none;
    }
  }
}

.owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  .owl-dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    margin: 0 1.5rem;
    background-color: $gray-500 !important;
    display: block;
    &.active {
      background-color: $blue !important;
    }
  }
  @media screen and (max-width: 767px) {
    margin-top: 0.5rem;
  }
}

/* - articleSlider */

/* + subscribeSection */
.subscribeSection {
  padding: 7.5rem 0;
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: calc(100% - ((100vw - 1350px) / 2 + 4.5rem));
    z-index: -1;
    background-image: url("../img/ripples.png");
    background-color: $gray-300;
  }
  .signature {
    margin-bottom: 0;
  }
  .h1.m-0 {
    margin-bottom: 0.25rem !important;
    max-width: 650px;
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .form-group {
      margin-bottom: 0;
      width: calc(100% - 184px);
    }
    .signature {
      width: 100%;
      margin-top: 1.5rem;
      color: $blue;
      a {
        font-family: $ui-bold;
        color: inherit;
      }
    }
  }
  @media screen and (max-width: 1384px) {
    &:before {
      width: calc(100% - 5.75rem);
    }
  }
  @media screen and (max-width: 1199px) {
    padding: 6rem 0;
    &:before {
      width: calc(100% - ((100vw - 960px) / 2 + 4.5rem));
    }
    .form {
      margin-top: 3rem;
      max-width: 696px;
      margin-left: auto;
      margin-right: 0;
    }
  }
  @media screen and (max-width: 991px) {
    &:before {
      width: calc(100% - ((100vw - 720px) / 2 + 4.5rem));
    }
    .form {
      max-width: 536px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 3rem 0;
    &:before {
      width: 100%;
    }
    .h1.m-0 {
      margin-bottom: 1.5rem !important;
    }
    .form {
      .form-group {
        width: 100%;
        order: 1;
      }
      .signature {
        order: 2;
        margin: 1.5rem 0;
      }
      .btn {
        order: 3;
        width: 100%;
        max-width: 350px;
      }
    }

  }
}

/* - subscribeSection */

/* + quoteSection */
.quoteSection {
  .h1 {
    max-width: 848px;
    margin: 0 auto !important;
  }
  .indent {
    padding: 6rem 0;
  }
  hr {
    margin: 0;
  }
  @media screen and (max-width: 767px) {
    .indent {
      padding: 2rem 0;
    }
    .h1 {
      padding: 0;
      font-size: 20px !important;
    }
  }
}

/* - quoteSection */

/* + commentsSlider */
.commentsSlider {
  .owl-stage-outer {
    padding: 1rem 0;
  }
  .img-wrap {
    width: 240px;
    height: 240px;
    position: relative;
    img {
      @include absolute;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  .sliderText {
    padding: 0 0 0 1.5rem;
    width: calc(100% - 240px);
    .accent {
      max-height: 54px;
      overflow: hidden;
      width: 100%;
    }
    .signature {
      display: inline-block;
      margin-bottom: 2rem;
      color: $gray-700;
      font-family: $ui-bold;
      @include dotted;
      max-width: 100%;
    }
    .text {
      height: 81px;
      overflow: hidden;
    }
  }
  .item {
    padding: 3rem;
    display: flex;
    align-items: center;
    box-shadow: $shadow;
    width: calc(100vw - 6rem);
    max-width: 1318px;
    transition: $time;
    &:hover {
      box-shadow: 0px 6px 13px rgba(0, 2, 58, 0.14);
    }
  }
  .owl-nav {
    button {
      position: absolute;
      height: calc(100% - 4rem);
      width: calc((100vw - 1370px) / 2);
      top: 1rem;
      z-index: 5;
      opacity: 0;
      background-color: red !important;
      &.owl-prev {
        left: 0;
      }
      &.owl-next {
        right: 0;
      }
      @media screen and (max-width: 1400px) {
        width: calc((100vw - 1340px) / 2);
      }
      @media screen and (max-width: 1384px) {
        width: 1.5rem;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .item {
      flex-wrap: wrap;
      width: calc(100vw - 4rem);
      padding: 1.5rem;
      position: relative;
    }
    .img-wrap {
      width: 72px;
      height: 72px;
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
    }
    .sliderText {
      width: 100%;
      padding-left: 88px;
      .text {
        width: calc(100% + 88px);
        margin-left: -88px;
        height: auto;
      }
      .signature {
        margin-bottom: 2rem;
      }
      .accent {
        font-size: 12px;
        max-height: 40px;
      }
    }

  }
}

/* - commentsSlider */

/* + section-static-map */
.staticImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 456px;
  background-size: cover;
  .btn.btn-primary {
    margin-top: 8rem;
    max-width: calc(100% - 3rem);
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 20px;
  }
  @media screen and (max-width: 767px) {
    background-position: 0;
  }
}

/* - section-static-map */

/* + concept */
.conFlex {
  display: flex;
  align-items: flex-start;

}

.sidebar {
  position: sticky;
  width: 336px;
  top: 0;
  padding-top: 3rem;
}

.menuConcept {
  padding: 0 0 6rem;
  margin: 0;

  li {
    list-style-type: none;
    padding: 13px 13px 13px 88px;
    position: relative;
    .link {
      color: $gray-500;
      font-size: 1rem;
      font-family: $ui-bold;
      line-height: 20px;
      display: inline-block;
      &:before {
        content: '';
        width: 62px;
        height: 1px;
        background-color: #fff;
        position: absolute;
        top: 1px;
        left: 0;
        bottom: 0;
        margin: auto;
        opacity: 0;
      }
      &.active {
        color: #fff;
        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.sideContent {
  position: relative;
  padding: 0 0 5rem 3rem;
  background-color: #fff;
  width: calc(100% - 336px);
  margin-bottom: 0;
  &:before {
    content: '';
    display: block;
    width: calc((100vw - 1384px + 47px) / 2);
    left: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: #fff;
  }
  .h2 {
    padding-top: 3rem;
  }
  .img-wrap {
    //width: 600px;
    width: 100%;
    height: 408px;
    margin-top: 3rem;
    position: relative;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      width: 600px;
      height: 100%;
    }
    &:before {
      content: '';
      position: absolute;
      height: 264px;
      width: calc(((100vw - 1384px + 47px) / 2) + (100% - 64px));
      top: 0;
      bottom: 0;
      margin: auto;
      right: calc(((100vw - 1384px + 47px) / (-2)));
      background-image: url('../img/ripples.png');
      background-color: $gray-300;
    }
  }
  .conceptBox {
    position: relative;
    &.hide-layer {
      .img-wrap {
        display: none !important;
      }
    }
    &:nth-child(odd) {
      .img-wrap {
        margin-left: auto;
        margin-right: 0;
        display: block;
        img {
          left: auto;
          right: 0;
        }
      }
    }
  }
}

.imgConcept {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 1384px) {
  .sideContent {

    &:before {
      width: 2rem;
    }
    .img-wrap {
      &:before {
        width: calc(2rem + (100% - 64px));
        right: -2rem;
      }
    }
  }

}

@media screen and (max-width: 1199px) {
  .title {
    &:before {
      width: 113px;
    }
  }
  .conFlex {
    width: calc(100vw - 4rem);
    margin-left: calc((100vw - 1000px) / (-2));
  }
  .sidebar {
    display: none;
  }
  .sideContent {
    width: 100%;
  }
  .imgConcept {
    position: absolute;
  }
  .sideContent {
    padding: 0 3rem 5rem 3rem;
    width: 100%;
    &:before {
      display: none;
    }
    .img-wrap:before {
      right: -3rem;
    }
  }
}

@media screen and (max-width: 991px) {
  .conFlex {
    width: 100%;
    margin-left: 0;
  }
  .sideContent {
    .img-wrap {
      height: 286px;
      &:before {
        height: 185px;
      }
      img {
        width: 420px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .sideContent {
    .img-wrap {
      height: 202px;
      &:before {
        height: 105px;
      }
      img {
        width: 296px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .title {
    text-align: center;
    padding: 0 0 10px;
    margin-top: 7.75rem;
    margin-bottom: 1.5rem !important;
    &:before {
      width: 36px;
      height: 1px;
    }
  }
  .imgConcept {
    height: 196px;
  }
  .sideContent {
    padding-left: 0;
    padding-right: 0;
    .img-wrap {
      height: 202px;
      &:before {
        right: auto;
        left: -2rem;
      }
      img {
        width: 296px;
        left: auto;
        right: 0;
        max-width: 100%;
        object-fit: contain;
        font-family: 'object-fit: contain;';
      }
    }
  }
}

.instagram {
  background-color: $gray-300;
  padding: 6rem 0;
  margin: 0;
  h2 {
    margin-bottom: 3rem !important;
  }
}

/* - concept */
.inSlider {
  .item {
    position: relative;
    padding-top: 100%;
    width: 200px;
    img {
      @include absolute;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
    a {
      @include absolute
    }
  }
  @media (max-width: 1384px) {
    width: calc(100% + 2rem);
  }
  @media (max-width: 1199px) {
    width: calc(100% + ((100vw - 937px) / 2));
  }

  @media (max-width: 991px) {
    width: calc(100% + ((100vw - 660px) / 2));
  }
  @media (max-width: 768px) {
    width: calc(100% + ((100vw - 479px) / 2));
  }
  @media (max-width: 575px) {
    width: calc(100% + 2rem);
  }
}

/* + catalog */
.catalog {
  margin-bottom: 1rem;
  .img-wrap {
    max-width: 392px;
    width: 100%;
    padding-top: 93%;
    display: block;
    margin: 0 auto;

    &:before {
      content: '';
      //width: 496px;
      //height: 496px;
      width: 77%;
      padding-top: 76.4%;
      height: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50%;
      right: -50%;
      margin: auto;
      border-radius: 100%;
      border: 2px solid $gray-300;
      transition: $time;
    }
    img {
      @include absolute;
      object-fit: contain;
      font-family: 'object-fit: contain;';
      transition: $time;
      &:last-child {
        opacity: 0;
      }
      &:first-child {
        opacity: 1;
      }
    }
  }
  .item {
    position: relative;
    margin-bottom: 6rem;
    .abs:hover ~ {
      .img-wrap:before {
        background-color: $gray-300;
      }
      img {
        &:first-child {
          opacity: 0;
        }
        &:last-child {
          opacity: 1;
        }
      }
    }
    .abs {
      @include absolute;
      z-index: 2;
    }
    .text {
      position: absolute;
      bottom: 120px;
      left: 0;
      max-width: 280px;
    }
  }
  @media (max-width: 575px) {
    margin-bottom: 0rem;
    .item {
      margin-bottom: 3rem;
      .text {
        bottom: 56px;
        font-size: 0.75rem;
      }
      .h2 {
        margin-bottom: 0.5rem !important;
      }
    }
    .img-wrap {
      padding-top: 378px;
      &:before {
        width: 296px;
        padding-top: 296px;
      }
    }
  }

}

/* - catalog */

/* + breadcrumb */
.breadcrumb {
  margin: 7.5rem 0 3rem;
  background-color: transparent;
  padding: 0 0 0 18px;
  position: relative;
  &:before {
    content: '';
    @include icon($url: '../img/arrBottom-gray.svg');
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(90deg);
  }
  .breadcrumb-item {
    a {
      display: block;
      font-family: $ui-bold;
      font-size: 0.75rem;
      color: $gray-500;
    }
  }
  @media (max-width: 767px) {
    margin: 6rem 0 1.5rem;
  }
}

/* - breadcrumb */

/* + card */
.goodsSection {
  margin-bottom: 4.5rem;
  .img-wrap {
    position: relative;
    width: 424px;
    height: 684px;
    display: block;
    margin: 0 auto;
    z-index: 1;
    &:before {
      content: '';
      @include absolute;
      left: -50%;
      right: -50%;
      z-index: -1;
      width: 536px;
      height: 536px;
      margin: auto;
      border-radius: 100%;
      background-color: $blue;
    }
    img {
      @include absolute;
      object-fit: contain;
      font-family: 'object-fit: contain;';
    }
  }
  .addBasket {
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      @include lastChild-margin0;
      .select2-container--bootstrap4 .select2-selection {
        border: 2px solid #EDEEF2;
      }
      .select-wrap {
        width: calc(100% - 152px);
        margin-bottom: 1rem;
        order: 1;
      }
      .select-wrap.hidden-select ~ .quantity{
        .input-group{
          margin-bottom: 0;
        }
      }
      .select-wrap ~ .quantity {
        order: 2;
        margin: 0;
        .input-group {
          margin-bottom: 1.5rem;
        }
      }
      .select-wrap ~ .roboto32 {
        order: 3;
        margin: 0;
        max-width: 216px;
      }

      .select-wrap ~ .btn{
        order: 4;
        width: 296px;
      }

      .roboto32{
        order: 1;
        margin-bottom: 0;
      }
      .quantity{
        order: 2;
        margin: 0 0.5rem;
        .input-group {
          width: 128px;
          padding: 22px 12px;
        }
        .btn.btn-outline-secondary{
          padding: 0 !important;
        }
      }
      .btn{
        order: 3;
        padding: 21.5px 1.5rem;
      }
    }
  }
  .officialDealer {
    margin-top: 3rem;
    border-top: 2px solid $gray-300;
    border-bottom: 2px solid $gray-300;
    padding: 1.5rem 0 20px;

    .link {
      display: inline-flex;
      align-items: center;
      line-height: 1.5;
      .ic {
        margin-right: 0.5rem;
      }
    }
  }
  @media (max-width: 1199px) {
    .img-wrap {
      width: 291px;
      height: 446px;
      &:before {
        width: 369px;
        height: 369px;
      }
    }
  }
  @media (max-width: 991px) {
    .img-wrap {
      width: 248px;
      height: 381px;
      margin-bottom: 1.5rem;
      &:before {
        width: 292px;
        height: 292px;
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 3rem;
    .addBasket {
      margin-top: 3rem;
      .flex {
        .select-wrap {
          width: 100%;
        }
        .select-wrap ~ .roboto32 {
          margin: 0 0 1.5rem;
        }
        .select-wrap ~ .btn{
          width: 100%;
        }
        .quantity{
          margin: 0 0 1.5rem;
          order: 1;
        }
        .roboto32{
          order: 2;
          margin: 0 0 1.5rem;
        }
        .btn{
          width: 100%;
        }
      }
    }
  }

}

.infoBox {
  margin-bottom: 4.5rem;
  .col-lg-6 {
    margin-bottom: 1.5rem;
  }
  .img-wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;
    img {
      @include absolute;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  .infoBox__text {
    padding-left: 40px;
    height: 432px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding-right: 1.5rem;
    word-break: break-word;
    &:before {
      content: '';
      border-bottom: 1px solid $gray-500;
      border-top: 1px solid $gray-500;
      border-right: 1px solid $gray-500;
      @include absolute;
      left: auto;
      right: 0;
      width: calc(((100vw - 1384px - 80px) / 2) + 1384px);
      z-index: -1;
    }
    p {
      max-width: 535px;
    }
  }
  .item-wrap {
    &:before {
      content: '';
      position: absolute;
      @include icon($url: '../img/logo-gray.svg');
      width: 567px;
      height: 536px;
      left: calc(100% - 283px);
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: -1;
    }
    .item {
      margin: 3rem 0;
      max-width: 536px;
    }
  }
  @media (max-width: 1199px) {
    .infoBox__text {
      padding-left: 0px;
      height: 385px;
    }
    .item-wrap {
      &:before {
        width: 424px;
        height: 400px;
        left: calc(100% - 200px);
      }
      .item {
        margin: 1.5rem 0;
        .h2 {
          margin-bottom: 0.5rem !important;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .infoBox__text {
      height: 289px;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
    .infoBox__text {
      height: auto;
      &:before {
        display: none;
      }
    }
    .col-lg-6 {
      margin-bottom: 3rem;
      &.order1 {
        order: 1;
      }
      &.order2 {
        order: 2;
      }
      &.order3 {
        order: 3;
      }
      &.order4 {
        order: 4;
      }
      &.indentMb {
        margin-bottom: 1.5rem;
      }
    }
    .item-wrap {
      &:before {
        bottom: 30px;
        width: 212px;
        left: auto;
        right: calc((100vw - 507px) / (-2));
        background-size: cover;
        background-position: left;
      }
      .item {
        margin: 0 0 1.5rem;
        &:last-child {
          margin-bottom: 0;
        }
        .h2 {
          margin-bottom: 1rem !important;
        }
      }
    }
  }
  @media (max-width: 575px) {
    .item-wrap {
      &:before {
        right: -1rem;
      }
    }
  }
}

.nextCard {
  position: relative;
  background-image: url(../img/pipples2.png);
  background-color: $blue;
  padding: 4.5rem 7rem;
  display: flex;
  align-items: center;
  transition: $time;
  &:hover {
    background-color: $blue-light;
  }
  .h2 {
    color: #fff;
    margin: 0 0 0 1.5rem !important;
    width: calc(100% - 4.5rem);
    span {
      font-size: 1rem;
      width: 100%;
      display: block;
      font-family: $ui-bold;
      margin-bottom: 0.5rem;
    }
  }
  .abs {
    @include absolute;
    z-index: 2;
  }
  @media (max-width: 991px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  @media (max-width: 575px) {
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }
}

/* - card */

/* + preOrder */
.preOrderSection{
  h2{
    line-height: 1.5;
    margin-bottom: 3rem !important;
  }
  margin-top: 11rem;
  @media screen and (max-width: 767px){
      margin-top: 7.75rem;
    h2 {
      margin-bottom: 1.5rem !important;
    }
  }
}
.preOrder{
  max-width: 424px;
  margin: 0 auto;
  .quantity.text{
    margin-bottom: 0;
    padding: 0;
  }
  .item{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    .select2-container--bootstrap4 .select2-selection--single {
      line-height: 1;
      padding: 14px 2.25rem 14px 1.5rem;
      height: 3rem !important;
      border: 2px solid #EDEEF2;
    }
    .select-wrap{
      width: calc(100% - 152px);
    }
    .quantity .input-group {
      width: 128px;
    }
    p{
      margin-bottom: 0;
    }
    .form-group{
      width: 100%;
      margin-bottom: 1.5rem;
      @include lastChild-margin0;
    }
  }
  .text{
    padding: 1rem 0;
    margin-bottom: 1.5rem;
  }
  .btn.btn-primary {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  hr{
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;
  }
  @media screen and (max-width: 767px){
    .item{
      margin-bottom: 1rem;
      .select-wrap{
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }
    hr {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .text {
      padding: 0;
    }
  }
}
/* - preOrder */

/* + buy */
.subSection {
  .d-flex {
    justify-content: space-between;
  }
  .successEl {
    text-align: left;
    margin-top: 1.5rem;
    &:first-child {
      margin-top: 0;
    }
    span {
      margin-left: auto;
      margin-right: 2.25rem;
    }
  }
  @media (max-width: 991px) {
    .d-flex {
      flex-wrap: wrap;
    }
  }
}
.addressSection{
  position: relative;
  .tipBox{
    max-width: calc(100vw - 70px);
    min-width: 290px;
  }
  @media (max-width: 991px) {
    .tipBox{
      max-width: 460px;
    }
  }

}
.partnersBox {
  width: 424px;
  background-color: $blue;
  padding: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 456px;
  h2 {
    color: #fff;
  }
  p {
    color: #fff;
    text-align: center;
  }
  .btn {
    margin-top: 3rem;
  }
  @media (max-width: 1199px) {
    width: 376px;
    padding: 1.5rem;
  }
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 3rem;
    padding: 3rem 1rem;
    height: auto;
    .btn {
      margin-top: 2rem;
    }
  }
}

.subForm {
  padding: 3rem;
  height: 456px;
  width: calc(100% - 424px - 1.5rem);
  background-color: $gray-300;
  background-image: url("../img/ripples.png");
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  h2 {
    margin-bottom: 0.5rem !important;
  }
  .form-wrap {
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .form-group {
      margin: 0;
      width: calc(100% - 160px - 1.5rem);
    }
    .signature {
      margin-top: 1.5rem;
      margin-bottom: 0;
      color: $black;
      width: 100%;
      a {
        font-family: $ui-bold;
        color: inherit;
      }
    }
  }
  @media (max-width: 1199px) {
    width: calc(100% - 376px - 1.5rem);
    padding: 1.5rem;
  }
  @media (max-width: 991px) {
    width: 100%;
    padding: 3rem 2rem;
    .form-wrap .form-group {
      width: calc(100% - 160px - 0.5rem);
    }
  }
  @media (max-width: 575px) {
    min-width: calc(100% + 4rem);
    margin-left: -2rem;
    display: block;
    height: auto;
    h2 {
      margin-bottom: 1.5rem !important;
    }
    .form-wrap {
      margin-top: 1.5rem;
      .form-group {
        width: 100%;
        margin-bottom: 1rem;
      }
      .btn.btn-primary {
        width: 100%;
        max-width: 350px;
      }
    }
  }
}

.tabEl {
  z-index: 3;
  position: relative;
  background-color: #fff;
  padding: 3rem 3rem 2.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 3rem auto 0;
  box-shadow: $shadow2;
  .nav {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    li {
      margin-left: 1.5rem;
    }
  }
  @media (max-width: 767px) {
    margin: 1.5rem auto 0;
    padding: 1.5rem;
  }
  @media (max-width: 575px) {
    flex-wrap: wrap;
    .select2-container--bootstrap4 {
      width: 100%;
      margin-bottom: 1rem;
    }
    .nav {
      width: 100%;
      justify-content: space-between;
      li {
        margin: 0;
        width: calc(50% - 12px);
        .boxBtn {
          width: 100%;
        }
      }
    }
  }
}

.addressList {
  .item {
    position: relative;
    padding: 3rem 0;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      width: calc(100% + (100vw - 1337px));
      left: calc((100vw - 1337px) / (-2));
      height: 2px;
      background-color: $gray-300;
    }
    p {
      margin: 0;
    }
    a {
      display: table;
      color: $gray-700;
      font-family: $ui-bold;
    }
  }
  @media (max-width: 1400px) {
    .item {
      &:before {
        width: calc(100% + 4rem);
        left: -2rem;
      }
    }
  }
  @media (max-width: 1199px) {
    .item {
      &:before {
        width: calc(100% + (100vw - 953px));
        left: calc((100vw - 953px) / (-2));
      }
    }
  }
  @media (max-width: 991px) {
    .item {
      &:before {
        width: calc(100% + (100vw - 674px));
        left: calc((100vw - 674px) / (-2));
      }
    }
  }
  @media (max-width: 767px) {
    .item {
      &:before {
        width: calc(100% + (100vw - 495px));
        left: calc((100vw - 495px) / (-2));
      }
    }
  }
  @media (max-width: 576px) {
    margin-top: 1.5rem;
    .item {
      padding: 1.5rem 0;
      &:before {
        width: calc(100% + 4rem);
        left: -2rem;
      }
    }
  }

}

.mapBox, #map {
  width: 100%;
  height: 648px;
  margin-top: -3rem;
  margin-bottom: -4.5rem;
  @media (max-width: 767px) {
    margin-top: -1.5rem;
    margin-bottom: 0;
  }
  @media (max-width: 575px) {
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }
}

/* - buy */

/* + blog */
.blogSection {
  padding: 3rem 0;
  margin: 0;
  position: relative;
  border-bottom: 2px solid $gray-300;
  .absLink {
    @include absolute;
    z-index: 2;
  }
  &:last-child {
    margin-bottom: 3rem;
    border-bottom: 0;
  }
  &:nth-child(even) {
    .img-wrap {
      order: 2;
      margin: 0 0 0 1.5rem;
    }
    .textBlog {
      order: 1;
    }
  }
  .flex {
    @include flex;
    flex-wrap: nowrap;
  }
  .img-wrap {
    position: relative;
    width: 648px;
    height: 432px;
    margin-right: 1.5rem;
    img {
      @include absolute;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  .textBlog {
    width: calc(100% - 648px - 1.5rem);
    .h2 {
      display: inline-block;
      margin-bottom: 3rem !important;
      span {
        font-size: 0.75rem;
        font-family: $font-family-base;
        display: block;
        margin-bottom: 0.5rem;
      }
    }
    .text {
      margin-bottom: 3rem;
      height: 81px;
      overflow: hidden;
      p {
        margin: 0;
      }
    }
  }
  @media (max-width: 1199px) {
    .img-wrap {
      width: 376px;
    }
    .textBlog {
      width: calc(100% - 376px - 1.5rem);
    }
  }
  @media (max-width: 767px) {
    padding: 0;
    margin-bottom: 1.5rem;
    border: none;
    &:nth-child(even) {
      .img-wrap {
        order: 1;
        margin: 0;
      }
      .textBlog {
        order: 2;
      }
    }
    .flex {
      flex-wrap: wrap;
      box-shadow: $shadow;
    }
    .img-wrap {
      width: 100%;
      margin: 0;
      height: auto;
      padding-top: 49%;
    }
    .textBlog {
      width: 100%;
      padding: 1.5rem;
      .text {
        display: none;
      }
      .h2 {
        margin-bottom: 1.5rem !important;
        width: 100%;
      }
    }
  }
}

/* - blog */

/* + article */
.article {
  .img-wrap {
    width: 100%;
    height: 432px;
    position: relative;
    margin-bottom: 1.5rem;
    img {
      @include absolute;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  .text-secondary {
    margin-bottom: 1.5rem;
  }
  @media (max-width: 767px) {
    .img-wrap {
      width: 100%;
      height: auto;
      padding-top: 49%;
    }

  }
}

.static {
  max-width: 872px;
  margin: 0 auto 0;
  img {
    margin: 1.5rem 0;
    max-width: 100%;
  }
  strong {
    font-family: $ui-bold;
  }
  .btn {
    margin: 3rem auto;
    display: table;
  }
}

.social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .text-secondary {
    margin: 0;
  }
  .icBtn {
    margin-left: 1.5rem;
  }
  @media (max-width: 767px) {
    .icBtn {
      margin-left: 1rem;
      min-width: 3rem;
    }
  }
  @media (max-width: 350px) {
    flex-wrap: wrap;
    .text-secondary {
      width: 100%;
      margin-bottom: 1rem;
      text-align: right;
    }
  }
}

/* - article */

/* + partnership */
.coopSection {
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: calc((100vw - 1300px) / 2);
    min-width: 3rem;
    background-color: $gray-300;
    height: calc(100% - 12rem);
    z-index: -1;
    background-image: url('../img/ripples.png');
    margin: auto;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      background-color: $gray-300;
      height: calc(100% - 12rem);
      z-index: -1;
      background-image: url('../img/ripples.png');
      margin: auto;
    }
  }
  @media (max-width: 1199px) {
    &:before {
      display: none;
    }
    .flex {
      padding: 2.5rem 0;
      &:before {
        height: 100%;
        width: 75%;
        left: 0;
        right: auto;
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 3rem;
    .flex {
      padding: 3rem 0 0;
      flex-wrap: wrap;
      &:before {
        width: 100%;
        height: 60%;
        bottom: auto;
      }
    }
  }
  @media (max-width: 575px) {
    .flex:before {
      width: calc(100% + 4rem);
      left: -2rem;
    }
  }

}

.textPar {
  width: calc(100% - 648px);
  padding: 0 1.5rem 0 3rem;
  h2 {
    margin-bottom: 4rem !important;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style-type: none;
      position: relative;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: '-';
        display: inline;
        font-size: inherit;
        margin-right: 6px;
      }
    }
  }
  @media (max-width: 1199px) {
    width: calc(100% - 376px);
    padding: 0 1.5rem 0 1.5rem;
    h2 {
      margin-bottom: 2rem !important;
    }
  }
  @media (max-width: 991px) {
    width: calc(100% - 296px);
  }
  @media (max-width: 767px) {
    width: 100%;
    h2 {
      margin-bottom: 1.5rem !important;
    }
  }
  @media (max-width: 575px) {
    padding: 0;
  }
}

.imgPar {
  position: relative;
  width: 648px;
  height: 648px;
  img {
    @include absolute;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
  @media (max-width: 1199px) {
    width: 376px;
    height: 376px;
  }
  @media (max-width: 991px) {
    width: 296px;
    height: 296px;
  }
  @media (max-width: 767px) {
    margin: 3rem auto 0;
  }
}

.aboutSection {
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc((100vw - 1300px) / 2);
    border-top: 2px solid $gray-300;
    border-bottom: 2px solid $gray-300;
    z-index: -1;
    margin: auto;
    height: calc(100% - 12rem);
    min-width: 3rem;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: calc(100% - 12rem);
      z-index: -1;
      margin: auto;
      border: 2px solid $gray-300;
      border-left: 0;
    }
  }
  @media (max-width: 1199px) {
    &:before {
      width: calc((100vw - 930px) / 2);
      height: 100%;
    }
    .flex {
      padding: 2.5rem 0;
      &:before {
        height: 100%;
      }
    }
  }
  @media (max-width: 991px) {
    &:before {
      width: calc((100vw - 656px) / 2);
    }
  }
  @media (max-width: 767px) {
    &:before {
      display: none;
    }
    .imgPar {
      display: none;
    }
    .flex {
      padding: 0;
      &:before {
        display: none;
      }
    }
  }
}

.uspSection {
  border-top: 2px solid $gray-300;
  border-bottom: 2px solid $gray-300;
  padding: 6rem 0;
  .flex {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: calc(33.3% - 1rem);
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
      margin-right: 1.5rem;
      @media screen and (min-width: 1200px) {
        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      img {
        width: 4.5rem;
        height: 4.5rem;
        object-fit: contain;
        font-family: 'object-fit: contain;';
        margin-bottom: 1.5rem;
      }
      .text {
        margin: 0;

      }
    }
  }
  @media screen and (max-width: 1199px) {
    padding: 1.5rem 0;
    .flex {
      .item {
        width: calc(50% - 12px);
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 3rem 0;
    .container {
      max-width: 100%;
      padding: 0 !important;
    }
    .flex {
      display: block;
      .item {
        width: 296px;
        margin: 0;
      }
    }
    .owl-dots {
      margin-top: 4rem;
    }
  }
  @media screen and (max-width: 575px) {
    border: 0;
    position: relative;
    &:before {
      content: '';
      width: calc(100% - 4rem);
      height: 2px;
      background-color: $gray-300;
      left: 2rem;
      top: 0;
      position: absolute;
    }
    &:after {
      content: '';
      width: calc(100% - 4rem);
      height: 2px;
      background-color: $gray-300;
      left: 2rem;
      bottom: 0;
      position: absolute;
    }
  }
  @media screen and (max-width: 350px) {
    .owl-dots .owl-dot {
      margin: 0 1rem;
    }
  }
}

.contactsSection {
  .flex {
    display: flex;
    justify-content: space-between;
    .item {
      width: calc(50% - 12px);
      padding: 4rem 3rem;
      &.black {
        background-color: $blue;
        color: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        h2, p {
          color: #fff;
        }
        a {
          color: $gray-500;
          font-family: $ui-bold;
          display: table;
        }
        h2 {
          margin-bottom: 2rem !important;
        }
        .text {
          margin-bottom: 0;
        }
        .signature {
          margin-bottom: 3rem;
        }
      }
      &.white {
        border: 1px solid $gray-300;
        a {
          color: $gray-700;
          font-family: $ui-bold;
          display: table;
          line-height: 1.5;
        }
        h2 {
          margin-bottom: 1.5rem !important;
          line-height: 1.5;
        }
        .text {
          margin-bottom: 0;
          line-height: 1.5;
        }
        .ul {
          margin: 1.5rem 0 0 0;
          padding: 0;
          li {
            list-style-type: none;
            color: #292A3D;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .flex .item {
      padding: 4rem 2rem;
    }
  }
  @media screen and (max-width: 991px) {
    .flex .item {
      padding: 3rem 1.5rem;
    }
  }
  @media screen and (max-width: 767px) {
    .flex {
      flex-wrap: wrap;
      .item {
        width: 100%;
        &.black {
          margin-bottom: 1.5rem;
          h2 {
            margin-bottom: 3rem !important;
          }
        }
      }
    }
  }
}

/* partnership */

/* + textSection */
.textSection {
  ul {
    li {
      list-style-type: none;
      margin-bottom: 1rem;
    }
  }

  ol {
    counter-reset: item;
    padding: 0;
    margin: 0 0 1rem;
    li {
      display: block
    }
    li:before {
      content: counters(item, ".") " ";
      counter-increment: item
    }
  }

}

/* - textSection */

/* + section404 */
.section404 {
  padding: 7.5rem 0 0;
  text-align: center;
  .img-wrap {
    display: inline-block;
    margin-bottom: 3rem;
    img {
      max-width: 100%;
    }
    hr {
      border-top: 1px solid $blue !important;
      margin: 0;
    }
  }
  h1 {
    width: 100%;
    margin-bottom: 1.5rem !important;
  }
  p {
    width: 100%;
    margin-bottom: 4rem;
    color: $gray-700;
  }
  @media screen and (max-width: 1199px) {
    .img-wrap {
      margin-bottom: 1.5rem
    }
    p {
      margin-bottom: 3rem;
    }
  }

}

/* - section404 */

/* + section500 */
.section500 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  min-height: 100vh;
  padding: 3rem 0;
  .logo {
    width: 372px;
    height: 80px;
    @include icon($url: '../img/logo-full.svg');
    display: inline-block;
  }
  .logo-wrap {
    width: 100%;
    text-align: center;
  }
  .grayBox {
    margin: 3rem 0;
    background-image: url('../img/ripples.png');
    background-color: $gray-300;
    width: 100%;
    max-width: 648px;
    height: 336px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    h2 {
      width: 100%;
      text-align: center;
      margin-bottom: 1rem !important;
    }
    p {
      margin: 0;
    }
  }
  .textBox {
    width: 100%;
    text-align: center;
    a {
      display: table;
      font-family: $ui-bold;
      color: $gray-700;
      margin: 1.5rem auto 0;
    }
  }
  @media screen and (max-width: 1199px) {
    .logo {
      width: 228px;
      height: 48px;
    }
  }
  @media screen and (max-width: 767px) {
    .grayBox {
      height: 192px;
    }
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    justify-content: space-around;
    align-items: normal;
    flex-wrap: nowrap;
    .grayBox {
      width: calc(100% + 4rem);
      margin-left: -2rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .logo {
      width: 190px;
      height: 40px;
    }
  }

}

/* - section500 */

/* + plugSection */
.plugSection {
  height: 100vh;
  background-color: $blue;
  width: 100%;
  margin: 0;
  .logo-wrap {
    text-align: center;
    height: calc(100% - 96px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    width: 372px;
    height: 80px;
    @include icon($url: '../img/logo.svg');
    display: inline-block;
  }
  .plug-f {
    background-color: #fff;
    padding: 24px 0;
    text-align: center;
    .icBtn {
      margin: 0 12px;
    }
  }
  @media screen and (max-width: 1199px) {
    .logo {
      width: 228px;
      height: 48px;
    }
  }
  @media screen and (max-width: 767px) {
    .logo {
      width: 190px;
      height: 40px;
    }
  }
}

/* - plugSection */

/* + ----------- static*/
.static {
  p {
    word-break: break-word;
  }
  .iframe-wrap {
    position: relative;
    width: 100%;
    padding-top: 56%;
    margin-bottom: 1rem;
    iframe {
      @include absolute
    }
  }
  #button {
    text-align: center;
    min-width: 160px;
    background-color: $blue;
    font-size: 1rem;
    padding: 1.1875rem 1.5rem;
    color: #fff;
    display: table;
    margin: 2rem auto 3rem;
    font-family: $ui-bold;
    white-space: nowrap;
  }
  table {
    margin-bottom: 12px;
  }
  .table-img.table-text, .table-text {
    display: flex;
    width: calc(100% + 24px);
    margin-left: -12px;
    tbody {
      width: 100%;
    }
    td {
      border: 12px;
      position: relative;
    }
    img {
      position: relative;
      width: calc(100% - 24px);
      margin: auto;
      display: block;
    }
    p {
      width: calc(100% - 24px);
      margin: 0 auto 1rem;
      display: block;
      &:last-child {
        margin-bottom: 0;
      }
    }
    tr {
      display: flex !important;
      align-items: flex-start;
      margin-bottom: 1.5rem;
      @include lastChild-margin0;
      td {
        width: 50%;
        padding-top: 0;
      }
    }

  }
  .table-img {
    display: flex;
    width: calc(100% + 24px);
    margin-left: -12px;
    tbody {
      width: 100%;
    }
    td {
      border: 12px;
      position: relative;
    }
    img {
      @include object-fit-cover;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: calc(100% - 24px);
      height: calc(100% - 24px);
      margin-left: 0;
      margin-right: 0;
    }
    tr {
      display: flex !important;
    }
    .one-img {
      td {
        width: 100%;
        padding-top: 100%;
      }
    }
    .two-img {
      td {
        width: 50%;
        padding-top: 50%;
      }
    }
    .three-img {
      td {
        width: 33.3%;
        padding-top: 33.3%;
      }
    }
    .four-img {
      td {
        width: 25%;
        padding-top: 25%;
      }
    }
  }
  @media (max-width: 991px) {
    #button {
      margin: 0.5rem auto 1.5rem;
    }
  }
  @media (max-width: 767px) {
    .table-img.table-text, .table-text {
      margin-left: 0;
      width: 100%;
      tr {
        overflow: initial;
        flex-wrap: wrap;
        &.one-img,
        &.two-img,
        &.three-img,
        &.four-img {
          flex-wrap: nowrap;
          overflow: auto;
        }
        td {
          width: 100%;
          img {
            margin-bottom: 1rem;
          }
          p {
            width: 100%;
          }
        }
      }
    }
    .table-img {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      tr {
        display: flex;
        max-width: calc(100vw - 4rem);
        margin-right: auto;
        margin-left: auto;
        overflow: auto;
        td:first-child {
          margin-left: -12px;
        }

      }
      .one-img {
        td {
          min-width: 100%;
          padding-top: 100%;
          margin-left: 0 !important;
          img {
            left: 0;
            right: 0;
            width: 100%;
          }
        }
      }
      .two-img, .three-img {
        td {
          min-width: 280px;
          padding-top: 280px;
          &:last-child {
            min-width: calc(280px - 12px);
            img {
              left: auto;
              width: calc(100% - 12px);
            }
          }
        }
      }
      .four-img {
        td {
          min-width: 200px;
          padding-top: 200px;
          &:last-child {
            min-width: calc(200px - 12px);
            img {
              left: auto;
              width: calc(100% - 12px);
            }
          }
        }
      }
    }
  }
}

/* - ----------- static*/

.table-img {
  a {
    display: block;
  }
}

#instafeed {
  img {
    width: 200px;
    height: 200px;
  }
}

/* + basket */
.emptyBasket {
  padding-top: 1.5rem;
  min-height: calc(100vh - 726px);
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    p {
      width: 100%;
      text-align: center;
      line-height: 96px;
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 1199px) {

  }
  @media screen and (max-width: 767px) {
    min-height: auto;
  }
}

.close {
   width: 1.5rem;
   height: 1.5rem;
   min-width: 16px;
   @include icon($url: '../img/cross-ic.svg');
   background-size: 16px;
   display: block;
   opacity: 1;

 }

.goodsBox {
  margin-bottom: 4.5rem;
  .goods-h {
    display: flex;
    align-items: center;
    padding: 0 2.5rem 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #F7F7F8;
    .text {
      color: $gray-700;
      margin: 0;
      &:nth-child(1) {
        width: 31%;
        margin-left: 20%;
      }
      &:nth-child(2) {
        width: 18%;
      }
      &:nth-child(3) {
        width: 18%;
      }
      &:nth-child(4) {
        width: 13%;
      }
    }
  }
  .goods-item {
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(62, 63, 78, 0.1);
    background-color: #fff;
    padding: 1.5rem 2.5rem;
    margin-bottom: 1.5rem;
    @include lastChild-margin0;
    p {
      margin-bottom: 0;
      color: $black;
    }
    .img-wrap {
      width: 94px;
      height: 144px;
      margin: 0 calc(15% - 92px) 0 calc(5% - 24px);
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .wrap {
      width: 31%;

      .name {
        width: 100%;
        margin-bottom: 1.5rem;
        @include dotted;
        padding-right: 2rem;
      }
    }
    .select-wrap {
      width: 100%;
      max-width: 260px;
      .select2-container--bootstrap4 .select2-selection--single {
        height: 3rem !important;
        padding: 14px 2.25rem 14px 1.5rem;
      }
    }
    .price {
      width: 18%;
      padding-right: 1rem;
    }
    .quantity-wrap {
      width: 18%;
    }
    .total-price {
      font-family: $ui-bold;
      width: 13%;
    }
  }
  .recover-wrap {
    margin-bottom: 1.5rem;
    padding: 1.5rem 0;
    p {
      margin-bottom: 0;
    }
    .link {
      display: inline-block;
      cursor: pointer !important;
    }
  }
  @media screen and (max-width: 1199px) {
    .goods-h {
      padding: 0 1.5rem 1.5rem;
      .text:nth-child(1) {
        width: 33%;
        margin-left: 18%;
      }
    }
    .goods-item {
      padding: 1.5rem;
      .select-wrap {
        max-width: 250px;
      }
      .img-wrap {
        margin: 0 calc(13% - 92px) 0 calc(5% - 24px);
      }
      .wrap {
        width: 33%;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .goods-h {
      display: none;
    }
    .recover-wrap {
      padding: 1rem 0;
    }
    .goods-item {
      flex-wrap: wrap;
      .close {
        margin-bottom: -3rem;
      }
      .img-wrap {
        margin: 0 1.5rem -3rem;
      }
      .wrap {
        width: calc(100% - 166px);
        .name {
          padding-right: 0;
          margin-bottom: 1rem;
        }
      }
      .price {
        display: none;
      }
      .quantity-wrap {
        width: 152px;
        margin-left: 166px;
        margin-top: 0.5rem;
      }
      .total-price {
        width: calc(100% - 320px);
        margin-top: 0.5rem;
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 1.5rem;
    .goods-item {
      padding: 1rem;
      position: relative;
      justify-content: space-between;
      .close {
        margin-bottom: 0;
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
      .img-wrap {
        margin: 0;
        width: 2rem;
        height: 3rem;
        position: absolute;
        top: 1rem;
        left: 1rem;
      }
      .wrap {
        width: 100%;
        .name {
          padding: 0 2.5rem 0 4rem;
          line-height: 1.5;
          margin: 0 0 1rem;
          min-height: 3rem;
          white-space: normal;
        }
      }
      .select-wrap {
        max-width: 100%;
      }
      .quantity-wrap {
        width: 120px;
        margin-left: 0;
      }
      .total-price {
        width: calc(100% - 144px);
        margin-top: 0.5rem;
        text-align: right;
      }
    }
    .recover-wrap {
      padding: 0;
      p {
        line-height: 1.5;
      }
    }

  }
}

.navOrder {
  max-width: 648px;
  margin-left: auto;
  margin-right: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 3rem;
  background-image: url(../img/ripples.png);
  background-color: #EDEEF2;
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    p {
      margin-bottom: 0;

    }
    .text-right {
      max-width: 300px;
      line-height: 1.5;
    }
    .h2 {
      margin: 0 !important;
      line-height: 1.5;
    }
  }
  .btn {
    width: 100%;
  }
  .signature.center {
    text-align: center;
    margin-bottom: 0;
    line-height: 3rem;
  }
  .signature {
    text-align: right;
    line-height: 1.5rem;
    width: 100%;
    margin-bottom: 0;
  }
  .btn-outline-primary {
    color: #85869D;
    border-color: #CBCCD7;
  }
  @media screen and (max-width: 1199px) {
    max-width: 100%;
    padding: 1.5rem 4.5rem;
  }
  @media screen and (max-width: 991px) {

  }
  @media screen and (max-width: 767px) {
    max-width: 100%;
    padding: 1.5rem 1.5rem 3rem;
    p {
      font-size: 12px;
      line-height: 15px;
    }
    .text1 {
      max-width: 100px;
    }
    .item {
      .h2 {
        max-width: calc(100% - 100px);
      }
      .text-right {
        max-width: 56%;
      }
    }

  }

}

/* - basket */

/* + place your order */
.formalizeSection {
  margin-top: 11rem;
  h2 {
    margin-bottom: 3rem !important;
  }
  .select-wrap {
    max-width: 536px;
  }
  .flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
  }
  .sContent {
    width: 59%;
    padding-right: 1.5rem;
  }
  .sBar {
    width: 41%;
    position: sticky;
    top: 1.5rem;
  }
  .select-wrap {
    margin-bottom: 1rem;
  }
  .signature.text-secondary {
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }
  .form-group {
    margin-bottom: 1.5rem;
    &.textarea {
      height: 120px;
    }
    textarea {
      resize: none;
      height: calc(100% - 38px);
      margin: 0.5rem 0 0;
    }
    .datePicker {
      border: none;
      width: 100%;
    }
    &.fill {
      .datePicker {
        &::-webkit-input-placeholder { /* Chrome */
          font-size: 0;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          font-size: 0;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          font-size: 0;
        }
        &:-moz-placeholder { /* Firefox 4 - 18 */
          font-size: 0;
        }
      }
    }
  }
  .form-group.dp {
    max-width: 182px;
    background-image: url("../img/calendar.svg");
    background-position: 1rem center;
    background-size: 1.5rem;
    background-repeat: no-repeat;
    padding-left: 3.5rem;
    position: relative;
    &.fill{
      .datePicker{
        padding-top: 1.5rem;
      }
    }
    .placeholder{
      margin-bottom: 1.5rem;
    }
    .datePicker{
      padding-left: 8px;
      position: absolute;
      top: 0;
      left: 3px;
      width: calc(100% - 6px);
      height: 100%;
      right: 0;
      text-align: center;
      background: transparent;
    }
  }
  hr {
    margin: 3rem 0;
  }
  #map {
    background: #ccc;
    margin: 0;
    height: 312px;
    width: 100%;
  }
}

.tabOrder {
  margin-bottom: 3rem;
  .nav-item {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  .btn {
    padding: 0.6875rem 18px;
    min-width: auto;
    color: $gray-700;
    border-color: $gray-300;
    &.active {
      background-color: transparent;
      color: $blue;
      border-color: $blue;
    }

  }

}

.resultOrder {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 3rem 3.5rem;
  background-image: url(../img/ripples.png?3e17e78â¦);
  background-color: #EDEEF2;
  color: $black;
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    p {
      margin-bottom: 0;
      line-height: 1.5rem;
    }
    h2 {
      margin: 0 !important;
    }
  }
  .btn {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .number-order {
    width: 100%;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $gray-700;
    text-align: center;
  }
  .signature {
    display: block;
    margin-bottom: 0;
    position: relative;
    padding-left: 2rem;
    .ic {
      margin-right: 0.5rem;
      @include absolute;
      right: auto;
      bottom: auto;
      width: 1.5rem;
      height: 1.5rem;
    }
    .bold {
      margin-left: 5px;
      font-family: $ui-bold;
      &:hover {
        color: $blue;
      }
    }
  }
}
input[disabled] ~ label{
  color: #ccc !important;
}
.selectPayment {
  .item {
    margin-bottom: 1.5rem;
    @include lastChild-margin0;
    input {
      display: none;
      &:checked ~ label {
        border: 1px solid $green;
        .round {
          border: none;
          @include icon($url: '../img/check-ic.svg')
        }
      }
      &:checked ~ .text {
        display: block;
      }
    }
    label {
      padding: 1.5rem 1rem 1.5rem 3rem;
      position: relative;
      border: 2px solid $gray-300;
      width: 100%;
      margin: 0;
      display: block;
      line-height: 1.3;
      &.opacity{
        color: #ccc;
      }
      .round {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 1px solid $gray-300;
        position: absolute;
        left: 18px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .text {
      display: none;
      margin: 0;
      padding: 0.5rem 0;
      line-height: 1.5;
    }
  }
  .accent {
    margin-bottom: 1.5rem;
    color: $blue-light;
  }
}

.yourData {
  .signature {
    margin: 0 0 1.5rem;
    line-height: 1.5;
  }
  .accent {
    color: $blue-light;
  }
}

.delivery-other-city {
  .text {
    @include lastChild-margin0;
  }
}

.formPhone {
  max-width: 424px;
  margin: auto;
  h2 {
    text-align: center;
  }
  .btn {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .signature {
    display: block;
    margin-bottom: 0;
    position: relative;
    padding-left: 2rem;
    .ic {
      margin-right: 0.5rem;
      @include absolute;
      right: auto;
      bottom: auto;
      width: 1.5rem;
      height: 1.5rem;
    }
    .bold {
      margin-left: 5px;
      font-family: $ui-bold;
      &:hover {
        color: $blue;
      }
    }
  }
}

.orderSuccess {
  text-align: center;
  min-height: calc(100vh - 582px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    margin-bottom: 1.5rem !important;
    padding: 0.5rem 0;
  }
  .text {
    padding: 0.5rem 0;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 1199px) {
  .formalizeSection {
    .sBar {
      width: 32%;
    }
    .sContent {
      width: 68%;
      padding-right: 1.5rem;
    }
  }
  .resultOrder {
    padding: 3rem 1.5rem 3.5rem;
    .item {
      h2 {
        font-size: 20px !important;
        padding: 12px 0;
      }

    }
  }
}

@media screen and (max-width: 991px) {
  .formalizeSection {
    .sContent {
      width: 65%;
      padding-right: 1rem;
    }
    .sBar {
      width: 35%;
    }
  }
  .yourData .signature {
    margin-top: -1.5rem;
    padding: 3px 0;
  }
}

@media screen and (max-width: 767px) {
  .col-lg-4 .form-group {
    max-width: 160px;
  }
  .formalizeSection {
    margin-top: 7.75rem;
    h2 {
      margin-bottom: 1.5rem !important;
    }
    .flex {
      flex-wrap: wrap;
    }
    .sBar {
      width: 100%;
    }
    .sContent {
      width: 100%;
      padding-right: 0;
      margin-bottom: 1.5rem;
    }
  }
  .tabOrder {
    margin-bottom: 1.5rem;
  }
  .resultOrder {
    padding: 3rem 1.5rem 3.5rem;
  }
  .selectPayment {
    .item {
      .text {
        padding: 1rem 0;
        font-size: 12px;
      }
    }
  }
  .formPhone {
    h2 {
      text-align: left;
    }
  }
  .orderSuccess {
    min-height: auto;
  }
}

/* - place your order */