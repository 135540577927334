@charset "UTF-8";

@import "~air-datepicker/dist/css/datepicker.css";

@import "~hamburgers/dist/hamburgers.css";

@import "~animate.css/animate.css";

@import "~owl.carousel/dist/assets/owl.carousel.css";

@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";

@font-face {
  font-family: "Cormorant Garamond light";
  src: url("./../fonts/CormorantGaramond-Light.eot");
  src: url("./../fonts/CormorantGaramond-Light.eot") format("embedded-opentype"), url("./../fonts/CormorantGaramond-Light.woff2") format("woff2"), url("./../fonts/CormorantGaramond-Light.woff") format("woff"), url("./../fonts/CormorantGaramond-Light.ttf") format("truetype"), url("./../fonts/CormorantGaramond-Light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Cormorant Garamond bold";
  src: url("./../fonts/CormorantGaramond-Bold.eot");
  src: url("./../fonts/CormorantGaramond-Bold.eot") format("embedded-opentype"), url("./../fonts/CormorantGaramond-Bold.woff2") format("woff2"), url("./../fonts/CormorantGaramond-Bold.woff") format("woff"), url("./../fonts/CormorantGaramond-Bold.ttf") format("truetype"), url("./../fonts/CormorantGaramond-Bold.svg") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./../fonts/Roboto-Regular.eot");
  src: url("./../fonts/Roboto-Regular.eot") format("embedded-opentype"), url("./../fonts/Roboto-Regular.woff2") format("woff2"), url("./../fonts/Roboto-Regular.woff") format("woff"), url("./../fonts/Roboto-Regular.ttf") format("truetype"), url("./../fonts/Roboto-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PT Root UI bold";
  src: url("./../fonts/PTRootUI-Bold.eot");
  src: url("./../fonts/PTRootUI-Bold.eot") format("embedded-opentype"), url("./../fonts/PTRootUI-Bold.woff2") format("woff2"), url("./../fonts/PTRootUI-Bold.woff") format("woff"), url("./../fonts/PTRootUI-Bold.ttf") format("truetype"), url("./../fonts/PTRootUI-Bold.svg") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "PT Root UI lightn";
  src: url("./../fonts/PTRootUI-Light.eot");
  src: url("./../fonts/PTRootUI-Light.eot") format("embedded-opentype"), url("./../fonts/PTRootUI-Light.woff2") format("woff2"), url("./../fonts/PTRootUI-Light.woff") format("woff"), url("./../fonts/PTRootUI-Light.ttf") format("truetype"), url("./../fonts/PTRootUI-Light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}

/* + color */

/* - color */

/* + link */

/* - link */

/* button */

/* - button */

/* + select */

/* - select */

/* + input */

/* - input */

/* + moadl */

/* - modal */

/* ----------site */

* {
  outline: none !important;
}

a {
  transition: 0.3s;
}

a:hover {
  opacity: 0.8;
}

p {
  line-height: 1.7;
}

button:focus,
a:focus {
  box-shadow: none !important;
}

button.focus,
a.focus {
  box-shadow: none !important;
}

hr {
  border-top: 2px solid #EDEEF2 !important;
}

.container {
  padding: 0 2rem !important;
}

section {
  margin-bottom: 6rem;
}

@media screen and (max-width: 767px) {
  section {
    margin-bottom: 3rem;
  }
}

.quote {
  position: relative;
  padding-left: 88px;
}

.quote:before {
  content: "";
  width: 64px;
  height: 46px;
  background: url("./../img/quote.svg") no-repeat center;
  background-size: contain;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

@media screen and (max-width: 767px) {
  .quote:before {
    position: relative;
    width: 31px;
    height: 22px;
    margin-bottom: 1rem;
  }
}

/* + fancybox */

.fancybox-bg {
  background: rgba(41, 42, 61, 0.8);
}

.fancybox-toolbar .fancybox-button--zoom,
.fancybox-toolbar .fancybox-button--play,
.fancybox-toolbar .fancybox-button--thumbs {
  display: none !important;
}

.fancybox-toolbar .fancybox-button.fancybox-button--close {
  top: 1.5rem;
  right: 1.5rem;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
  position: relative;
  transition: 0.3s;
  background: url("./../img/close-fancy.svg") no-repeat center;
  background-size: contain;
}

.fancybox-toolbar .fancybox-button.fancybox-button--close svg {
  display: none;
}

.fancybox-navigation .fancybox-button {
  padding: 0;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
  background-image: url("./../img/arr-fancy.svg");
  background-size: contain !important;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5 !important;
}

.fancybox-navigation .fancybox-button > div {
  display: none;
}

.fancybox-navigation .fancybox-button.fancybox-button--arrow_left {
  left: 2rem;
}

.fancybox-navigation .fancybox-button.fancybox-button--arrow_right {
  right: 2rem;
  transform: rotate(-180deg);
}

/* + scrol */

::-webkit-scrollbar-button {
  background-repeat: no-repeat;
  width: 5px;
  height: 0px;
  position: absolute;
}

::-webkit-scrollbar-track {
  background-color: #CBCCD7;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  position: absolute;
  border-radius: 2px;
  background-color: #85869D;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  width: 5px;
  border: 1px solid #85869D;
}

::-webkit-resizer {
  background-repeat: no-repeat;
  width: 5px;
  height: 0px;
  position: absolute;
}

::-webkit-scrollbar {
  width: 5px;
  position: absolute;
}

/* - scrol */

@media screen and (max-width: 991px) {
  .fancybox-navigation .fancybox-button {
    top: auto;
    bottom: 3rem;
    margin: auto;
  }

  .fancybox-navigation .fancybox-button.fancybox-button--arrow_left {
    left: 0;
    right: 95px;
  }

  .fancybox-navigation .fancybox-button.fancybox-button--arrow_right {
    right: 0;
    left: 95px;
  }
}

/* - fancybox */

.ic {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  margin: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.vk-ic {
  background-image: url("./../img/vk-gray.svg");
}

.tw-ic {
  background-image: url("./../img/tv-gray.svg");
  background-size: 84%;
}

.fb-ic {
  background-image: url("./../img/fb-gray.svg");
  background-size: auto 90%;
}

.in-ic {
  background-image: url("./../img/in-gray.svg");
  background-size: 84%;
}

.in-black-ic {
  background-image: url("./../img/instagram-black.svg");
}

.point-ic {
  background-image: url("./../img/point-gray.svg");
  background-size: auto 90%;
}

.list-ic {
  background-image: url("./../img/list-gray.svg");
}

.arr-b-ic {
  background-image: url("./../img/play-blue.svg");
  background-size: 10px;
  transform: rotate(90deg);
}

.arr-r-ic {
  background-image: url("./../img/play-blue.svg");
  background-size: 10px;
}

.arr-t-ic {
  background-image: url("./../img/play-blue.svg");
  background-size: 10px;
  transform: rotate(-90deg);
}

.check-ic {
  background-image: url("./../img/ok.svg");
}

.plus-ic {
  background-image: url("./../img/plus.svg");
}

.close-ic {
  background-image: url("./../img/close.svg");
}

.time-ic {
  background-image: url("./../img/time.svg");
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 0.5rem;
}

.h1,
h1,
h2.h1 {
  font-size: 3.25rem !important;
  font-family: "Cormorant Garamond light";
  margin-bottom: 3rem !important;
}

.h2,
h2 {
  font-size: 2rem !important;
  font-family: "Cormorant Garamond bold";
  margin-bottom: 1.5rem !important;
}

.h2 .ic,
h2 .ic {
  margin: 0px 1rem -3px 0;
}

.roboto32 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-family: "PT Root UI bold";
}

.title {
  text-align: center;
  padding: 0 2rem 10px;
  margin-top: 11rem;
  margin-bottom: 4.5rem !important;
  position: relative;
}

.title.text-white:before {
  content: "";
  background-color: #fff;
}

.title:before {
  content: "";
  position: absolute;
  top: calc(100%);
  width: 160px;
  height: 2px;
  background-color: #EDEEF2;
  left: 0;
  right: 0;
  margin: auto;
}

.accent {
  font-family: "PT Root UI bold";
}

.signature {
  font-size: 0.75rem;
}

/* + table */

.table {
  margin-bottom: 3rem !important;
}

.table th,
.table td {
  border-top: none !important;
  border-bottom: 2px solid #EDEEF2 !important;
  vertical-align: middle !important;
}

.table th {
  font-weight: normal;
  font-size: 0.75rem;
  color: #85869D;
  padding: 0.625rem 1.5rem 0.625rem 0 !important;
  width: 224px;
}

.table td {
  font-size: 1rem;
  padding: 0.625rem 0 0.625rem 0 !important;
}

.link {
  color: #85869D;
  display: table;
  font-family: "PT Root UI bold";
  margin: 0;
}

.grey {
  color: #85869D;
}

/* - table */

/* + .alert-success */

.alert.alert-success {
  padding: 13px 1rem 13px 3rem;
  position: relative;
  font-family: "PT Root UI bold";
  border-radius: 0;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 20px;
}

.alert.alert-success.alert-success {
  background-color: #61D1A2;
  color: #fff;
  background-image: url("./../img/error.svg");
  background-repeat: no-repeat;
  background-position: 1rem center;
  background-size: 1.5rem;
}

.consentLink {
  display: block;
  margin-bottom: 0;
  position: relative;
  padding-left: 2rem;
}

.consentLink .ic {
  margin-right: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: auto;
  bottom: auto;
  width: 1.5rem;
  height: 1.5rem;
}

.consentLink .bold {
  margin-left: 5px;
  font-family: "PT Root UI bold";
}

.consentLink .bold:hover {
  color: #00023A;
}

/* - .alert-success */

@media screen and (max-width: 767px) {
  .h1,
  h1,
  h2.h1 {
    font-size: 2rem !important;
    margin-bottom: 1.5rem !important;
  }

  .h2,
  h2 {
    font-size: 1.25rem !important;
  }

  .roboto32 {
    font-size: 1.25rem;
  }

  .alert.alert-success {
    padding: 10px 1rem 10px 3rem;
    line-height: 1.5;
    font-size: 12px;
  }
}

@media screen and (max-width: 575px) {
  .table {
    margin-bottom: 1.5rem !important;
  }

  .table th {
    width: 104px;
  }
}

.btn.btn-outline-secondary {
  border-color: #CBCCD7 !important;
}

.btn.btn-outline-secondary:hover {
  background-color: transparent !important;
  border-color: #282A66 !important;
  color: #282A66 !important;
}

.btn.btn-primary {
  min-width: 160px;
}

.btn.btn-primary:hover {
  background-color: #282A66 !important;
}

.btn.btn-outline-light {
  min-width: 160px;
  border-color: #85869D !important;
}

.btn.btn-outline-light:hover {
  border-color: #fff !important;
  background-color: transparent !important;
  color: #fff !important;
}

.btn.btn-outline-primary {
  min-width: 160px;
  transition: 0.3s;
  position: relative;
}

.btn.btn-outline-primary:before {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #00023A;
  transition: 0.3s;
}

.btn.btn-outline-primary:hover {
  background-color: transparent !important;
  color: #00023A !important;
}

.btn.btn-outline-primary:hover:before {
  opacity: 1;
}

.boxBtn {
  width: 4.5rem;
  height: 4.5rem;
  border: 2px solid #EDEEF2;
  box-shadow: none;
  background-color: transparent;
  position: relative;
  transition: 0.3s;
  display: block;
  background-image: none !important;
}

.boxBtn:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  opacity: 1;
  transition: 0.3s;
}

.boxBtn:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  opacity: 0;
  transition: 0.3s;
}

.boxBtn:hover {
  box-shadow: 0px 4px 8px rgba(62, 63, 78, 0.1);
}

.boxBtn:hover:before {
  opacity: 0;
}

.boxBtn:hover:after {
  opacity: 1;
}

.boxBtn.active {
  border-color: #00023A;
}

.boxBtn.active:before {
  opacity: 0 !important;
}

.boxBtn.active:after {
  opacity: 1 !important;
}

.boxBtn.list-ic:before {
  background-image: url(./../img/list-gray.svg);
}

.boxBtn.list-ic:after {
  background-image: url(./../img/list-blue.svg);
}

.boxBtn.point-ic:before {
  background-image: url(./../img/point-gray.svg);
}

.boxBtn.point-ic:after {
  background-image: url(./../img/point-blue.svg);
}

.pointBtn {
  width: 4.5rem;
  height: 4.5rem;
  border: 2px solid #EDEEF2;
  box-shadow: none;
  background-color: transparent;
  position: relative;
  transition: 0.3s;
  display: block;
}

.pointBtn:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./../img/point-gray.svg") no-repeat center;
  background-size: 1.375rem;
  opacity: 1;
  transition: inherit;
}

.pointBtn:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./../img/point-blue.svg") no-repeat center;
  background-size: 1.375rem;
  opacity: 0;
  transition: inherit;
}

.pointBtn:hover {
  box-shadow: 0px 4px 8px rgba(62, 63, 78, 0.1);
}

.pointBtn:hover:before {
  opacity: 0;
}

.pointBtn:hover:after {
  opacity: 1;
}

.link {
  color: #CBCCD7;
  font-family: "PT Root UI bold";
  transition: 0.3s;
}

.link:hover {
  color: #292A3D;
}

.link:hover.white {
  color: #fff;
}

.link:active,
.link.active {
  color: #292A3D;
}

.link:active.white,
.link.active.white {
  color: #fff;
}

.close {
  width: 1.5rem;
  height: 1.5rem;
  min-width: 16px;
  background: url("./../images/cross-ic.svg") no-repeat center;
  background-size: contain;
  background-size: 16px;
  display: block;
  opacity: 1;
}

.close:hover {
  opacity: 1 !important;
  background: url("./../images/cross-ic2.svg") no-repeat center;
  background-size: contain;
  background-size: 16px;
}

.closeBtn {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
  border: 1px solid #EDEEF2;
  position: relative;
  transition: 0.3s;
  background-color: #fff;
}

.closeBtn:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./../img/cross-gray.svg") no-repeat center;
  background-size: 1.625rem;
  opacity: 1;
  transition: inherit;
}

.closeBtn:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./../img/cross-blue.svg") no-repeat center;
  background-size: 1.625rem;
  opacity: 0;
  transition: inherit;
}

.closeBtn:hover:before {
  opacity: 0;
}

.closeBtn:hover:after {
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .closeBtn {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.icBtn {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  border: 1px solid #EDEEF2;
  position: relative;
  transition: 0.3s;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
}

.icBtn.fb:before {
  background-image: url("./../img/fb-gray.svg");
  background-size: 0.75rem;
}

.icBtn.fb:after {
  background-image: url("./../img/fb-blue.svg");
  background-size: 0.75rem;
}

.icBtn.in:before {
  background-image: url("./../img/in-gray.svg");
  background-size: 1.25rem;
}

.icBtn.in:after {
  background-image: url("./../img/in-blue.svg");
  background-size: 1.25rem;
}

.icBtn.vk:before {
  background-image: url("./../img/vk-gray.svg");
  background-size: 1.5rem;
}

.icBtn.vk:after {
  background-image: url("./../img/vk-blue.svg");
  background-size: 1.5rem;
}

.icBtn.tw:before {
  background-image: url("./../img/tv-gray.svg");
  background-size: 1.25rem;
}

.icBtn.tw:after {
  background-image: url("./../img/tv-blue.svg");
  background-size: 1.25rem;
}

.icBtn.play {
  background: url("./../img/play-blue.svg") no-repeat center;
  background-size: 13px;
}

.icBtn.play.white {
  background: url("./../img/play-white.svg") no-repeat center;
  background-size: 13px;
}

.icBtn:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  transition: inherit;
}

.icBtn:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: center;
  transition: inherit;
}

.icBtn:hover:before {
  opacity: 0;
}

.icBtn:hover:after {
  opacity: 1;
}

.hamburger {
  padding: 0;
  opacity: 1 !important;
}

.hamburger-box {
  width: 36px;
  height: 30px;
  display: block;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 36px;
  height: 2px;
  background-color: #00023A;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #00023A;
}

.hamburger-inner {
  margin-top: -1px;
}

.hamburger-inner::before {
  top: -13px;
}

.hamburger--emphatic .hamburger-inner::after {
  top: 13px;
}

.playBtn {
  color: #fff;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: 0.875rem;
  font-family: "Roboto";
}

.playBtn .play-ic {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin-right: 0.75rem;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #fff;
  position: relative;
}

.playBtn .play-ic:before {
  content: "";
  background-image: url("./../img/play-border.svg");
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  left: 2px;
  opacity: 1;
  transition: 0.3s;
}

.playBtn .play-ic:after {
  content: "";
  background: url("./../img/play-white.svg");
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 1.125rem;
  background-repeat: no-repeat;
  background-position: center;
  left: 2px;
  opacity: 0;
  transition: 0.3s;
}

.playBtn:hover .play-ic:before {
  opacity: 0;
}

.playBtn:hover .play-ic:after {
  opacity: 1;
}

/* + tipEl */

.tipEl {
  display: flex;
  background-color: #080A11;
  padding: 8px 1.5rem 8px 1rem;
  max-width: 392px;
  width: calc(100% - 1rem);
  box-shadow: 0px 12px 24px rgba(0, 2, 58, 0.14);
  position: fixed;
  bottom: 1.5rem;
  z-index: 10;
  left: 0;
  right: 0;
  margin: auto;
  align-items: center;
  justify-content: space-between;
}

.tipEl .text {
  width: calc(100% - 32px);
  font-size: 12px;
  color: #fff;
  margin: 0;
  max-width: 288px;
  line-height: 1.4;
}

.tipEl .closeBtn {
  background-color: transparent;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  cursor: pointer;
}

.tipEl .closeBtn:hover {
  background-color: transparent;
}

.tipEl .closeBtn:hover:before {
  opacity: 1;
}

.tipEl .closeBtn:hover:after {
  opacity: 0;
}

@media screen and (max-width: 767px) {
  .tipEl {
    bottom: 0.5rem;
  }
}

/* - tipEl */

/* + successEl */

.successEl {
  font-family: "PT Root UI bold";
  text-align: center;
}

.successEl span {
  width: 3rem;
  height: 3rem;
  background-color: #00023A;
  border-radius: 100%;
  margin-left: 2.25rem;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-image: url("./../img/ok-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}

.successEl span:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: 4.5rem;
  height: 4.5rem;
  border: 1px solid #00023A;
  border-radius: 100%;
  margin: auto;
  left: -0.75rem;
}

@media screen and (max-width: 1199px) {
  .successEl {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .successEl {
    font-size: 12px;
  }

  .successEl span {
    width: 2rem;
    height: 2rem;
    margin-left: 1.5rem;
  }

  .successEl span:before {
    width: 3.5rem;
    height: 3.5rem;
  }
}

/* - successEl */

/* + quantity */

.quantity .input-group-prepend .btn {
  background: url("./../images/minus-ic.svg") no-repeat center;
  background-size: contain;
  background-size: 12px;
}

.quantity .input-group-prepend .btn:hover {
  background: url("./../images/minus-ic2.svg") no-repeat center;
  background-size: contain;
  background-size: 12px;
}

.quantity .input-group-append .btn {
  background: url("./../images/plus-ic.svg") no-repeat center;
  background-size: contain;
  background-size: 12px;
}

.quantity .input-group-append .btn:hover {
  background: url("./../images/plus-ic2.svg") no-repeat center;
  background-size: contain;
  background-size: 12px;
}

.quantity .input-group {
  width: 120px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #EDEEF2;
}

.quantity .input-group-append .btn,
.quantity .input-group-prepend .btn {
  border: none;
  width: 20px;
  min-width: 20px !important;
  height: 20px;
  padding: 0;
}

.quantity .input-group-append .btn strong,
.quantity .input-group-prepend .btn strong {
  display: none;
}

.quantity .form-control {
  width: 52px !important;
  height: 24px;
  font-size: 16px;
  line-height: 20px;
  color: #292A3D;
  padding: 0 6px;
}

.quantity.text-pack .input-group-append:before {
  content: "уп";
}

.quantity.text-piece .input-group-append:before {
  content: "шт";
}

.quantity.text-pack .form-control,
.quantity.text-piece .form-control {
  width: 20px !important;
  max-width: 20px !important;
  min-width: 20px !important;
  padding: 0;
  margin-left: 0.5rem;
  margin-right: 2px;
}

.quantity.text-pack .input-group-append:before,
.quantity.text-piece .input-group-append:before {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  margin-right: 0.5rem;
  line-height: 20px;
}

/* - quantity */

.form-group {
  position: relative;
  border: 2px solid #EDEEF2;
  height: 4.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
  transition: 0.3s;
  background-color: #fff;
}

.form-group.fill .placeholder {
  display: block;
  font-size: 12px;
}

.form-group.fill .form-control::-webkit-input-placeholder {
  color: transparent;
}

.form-group.fill .form-control::-moz-placeholder {
  color: transparent;
}

.form-group.fill .form-control:-moz-placeholder {
  color: transparent;
}

.form-group.fill .form-control:-ms-input-placeholder {
  color: transparent;
}

.form-group:hover {
  box-shadow: 0px 4px 8px rgba(62, 63, 78, 0.1);
  border: 2px solid #CBCCD7;
}

.form-group .placeholder {
  margin: 0;
  line-height: 1;
  display: none;
  width: 100%;
  color: #85869D;
}

.form-group .form-control {
  width: 100%;
  box-shadow: none !important;
}

.form-group .form-control::-webkit-input-placeholder {
  color: #85869D;
}

.form-group .form-control::-moz-placeholder {
  color: #85869D;
}

.form-group .form-control:-moz-placeholder {
  color: #85869D;
}

.form-group .form-control:-ms-input-placeholder {
  color: #85869D;
}

.form-group.is-invalid {
  border: 2px solid transparent;
  outline: 1px solid #EE5D49 !important;
  outline-offset: -1px;
}

.form-group.is-invalid .invalid-feedback {
  display: block;
}

.form-group.error {
  border: 2px solid transparent;
  outline: 1px solid #EE5D49 !important;
  outline-offset: -1px;
}

.form-group.error .invalid-feedback {
  display: none;
}

.form-group .invalid-feedback {
  position: absolute;
  margin: 0;
  top: calc(100%);
  right: 0;
  text-align: right;
  font-size: 0.75rem;
  color: #EE5D49;
}

.form-group.was-validated .form-control:invalid,
.form-group .form-control.is-invalid {
  background-image: none;
}

/* + select */

.select2-container--bootstrap4 {
  padding: 0 0 0.5rem;
}

.select2-container--bootstrap4 .select2-selection--single {
  transition: 0.3s;
  border-color: #F7F7F8;
  height: 4.5rem !important;
}

.select2-container--bootstrap4 .select2-selection--single:hover {
  border-color: #CBCCD7 !important;
  box-shadow: 0px 4px 8px rgba(62, 63, 78, 0.1);
}

.select2-container--bootstrap4 .select2-selection--single:hover .select2-selection__arrow b {
  background: url("./../img/arrBottom-blue.svg") no-repeat center;
  background-size: contain;
}

.select2-container--bootstrap4.select2-container--open .select2-selection__arrow b {
  transform: rotate(180deg);
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow {
  bottom: 0.5rem !important;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b {
  border: 0 !important;
  background: url("./../img/arrBottom-gray.svg") no-repeat center;
  background-size: contain;
  width: 10px !important;
  height: 10px !important;
}

.select2-container--bootstrap4 .select2-dropdown {
  margin-top: -4px !important;
  border: 2px solid #EDEEF2 !important;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(62, 63, 78, 0.1);
}

.select2-container--bootstrap4.select2-container--focus .select2-selection,
.select2-container--bootstrap4.select2-container--open .select2-selection {
  border: 1px solid #CBCCD7 !important;
}

.select2-container--bootstrap4 .select2-results__option {
  padding: 0.5rem 1.5rem !important;
  border-bottom: 2px solid #EDEEF2 !important;
}

.select2-container--bootstrap4 .select2-results__option:last-child {
  border: 0 !important;
}

.select2-container--bootstrap4 .select2-results > .select2-results__options {
  max-height: 268px !important;
}

.select2-container--bootstrap4 .select2-results__option--highlighted[aria-selected] {
  background-color: #EDEEF2 !important;
  color: #292A3D !important;
}

.select2-container--bootstrap4 .select2-results__option[aria-disabled=true] {
  height: 0;
  padding: 0 !important;
  opacity: 0;
  border: 0 !important;
}

.select2-container--bootstrap4.dropdown-card {
  z-index: 999;
}

.select2-container--bootstrap4.dropdown-card .select2-selection__rendered {
  color: #080A11 !important;
}

.select2-container--bootstrap4.dropdown-card .nicescroll-rails.nicescroll-rails-vr {
  display: none !important;
}

.select2-container--bootstrap4.dropdown-card .select2-results__option--highlighted[aria-selected] {
  background-color: #fff !important;
}

.select2-container--bootstrap4.dropdown-card .select2-results__option[aria-selected=true] {
  background-color: #fff;
}

.select2-container--bootstrap4.dropdown-card .select2-dropdown {
  border: none !important;
  border-radius: 0 !important;
}

.select2-container--bootstrap4.dropdown-card .select2-results__options:after {
  content: "";
  position: absolute;
  width: 100%;
  background: #fff;
  height: 1px;
  bottom: 0;
  z-index: 1;
  left: 0;
  right: 0;
  margin: auto;
}

.select2-container--bootstrap4.dropdown-card .select2-results__option {
  padding: 10px 1rem !important;
  border-bottom: none !important;
  position: relative;
  color: #080A11 !important;
  transition: 0.3s;
  margin-top: -1px;
}

.select2-container--bootstrap4.dropdown-card .select2-results__option:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(100% - 2rem);
  left: 0;
  right: 0;
  margin: auto;
  background: #EDEEF2;
  height: 1px;
  z-index: 1;
}

.select2-container--bootstrap4.dropdown-card .select2-results__option:hover {
  background-color: transparent !important;
}

.select2-container--bootstrap4.dropdown-card .select2-results__option:last-child {
  border-bottom: 0 !important;
}

/* - select */

/* + datepicker */

.datepicker {
  margin-left: -3.5rem;
  border-radius: 0;
  width: 366px;
  font-size: 1rem;
}

.datepicker .datepicker--pointer {
  display: none;
}

.datepicker .datepicker--nav-title {
  font-family: "PT Root UI bold";
  color: #00023A;
  font-size: 1rem;
  line-height: 1.5rem;
}

.datepicker .datepicker--nav-title i {
  font-size: inherit;
  font-style: normal;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  font-size: inherit;
}

.datepicker .datepicker--nav-title:hover {
  background-color: transparent !important;
}

.datepicker .datepicker--nav-action {
  background-image: url(./../img/play-blue.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
}

.datepicker .datepicker--nav-action[data-action=prev] {
  transform: rotate(180deg);
}

.datepicker .datepicker--nav-action svg {
  display: none;
}

.datepicker .datepicker--nav {
  padding: 1.5rem 1.5rem 1.5rem;
  border: none;
}

.datepicker .datepicker--days-names {
  margin: 0 auto 6px;
}

.datepicker .datepicker--days-names .datepicker--day-name {
  color: #85869D;
  text-transform: none;
  font-size: 1rem;
  font-family: "PT Root UI lightn";
  line-height: 1.5rem;
}

.datepicker .datepicker--content {
  padding: 0 1.5rem calc(2rem - 10px);
}

.datepicker .datepicker--cell {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  color: #292A3D;
  font-family: "PT Root UI lightn";
  font-size: 1rem;
  z-index: 1;
}

.datepicker .datepicker--cell.-other-month- {
  color: #CBCCD7;
}

.datepicker .datepicker--cell.-current- {
  color: #EE5D49;
  background-color: transparent;
}

.datepicker .datepicker--cell.-focus- {
  background-color: transparent;
}

.datepicker .datepicker--cell.-focus-:before {
  content: "";
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
  border-radius: 100%;
  background-color: #EDEEF2;
}

.datepicker .datepicker--cell.-selected- {
  background-color: transparent;
  color: #fff;
  font-family: "PT Root UI bold";
}

.datepicker .datepicker--cell.-selected-:before {
  content: "";
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  right: 0;
  margin: auto;
  z-index: -1;
  border-radius: 100%;
  background-color: #282A66 !important;
}

/* - datepicker */

.iframe-wrap {
  position: relative;
  padding-top: 56%;
  width: 100%;
}

.iframe-wrap iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal .closeBtn {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  z-index: 2;
}

.addProduct {
  position: fixed;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  width: 90%;
  max-width: 360px;
  z-index: 9999;
  background: #FFFFFF;
  box-shadow: 0px 20px 28px rgba(26, 27, 32, 0.1);
  height: 144px;
  display: none;
}

.addProduct::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #292A3D;
  z-index: -1;
  opacity: 0.3;
}

.addProduct .flex {
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  padding: 1.5rem;
  background: #FFFFFF;
}

.addProduct.active {
  display: block;
}

.addProduct p {
  line-height: 1.5;
  margin-bottom: 0;
}

.addProduct .close-ic {
  opacity: 0.4;
  background-color: transparent;
  border: none;
}

.addProduct .btn {
  width: 100%;
  margin-top: 1.5rem;
}

@media screen and (max-width: 767px) {
  .addProduct .close-ic {
    display: none;
  }

  .addProduct .flex {
    position: relative;
    padding-top: 3rem;
  }

  .addProduct .flex:before {
    content: "";
    width: 4.5rem;
    height: 2px;
    margin: auto;
    position: absolute;
    top: 1rem;
    left: 0;
    right: 0;
    background-color: #F7F7F8;
  }
}

.tipBox {
  display: none;
  width: 536px;
  padding: 1rem 1.5rem;
  box-shadow: 0px 12px 24px rgba(0, 2, 58, 0.14);
  position: relative;
}

.tipBox.mobile,
.tipBox.mobile.active {
  display: none;
}

@media (max-width: 767px) {
  .tipBox.mobile.active {
    display: block;
    max-width: 476px;
    position: sticky;
    bottom: 0;
    z-index: 2;
  }

  .tipBox.mobile.active ~ .layerMobile {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #292A3D;
    display: block;
    z-index: 1;
    opacity: 0.3;
  }
}

@media (max-width: 575px) {
  .tipBox.mobile.active {
    margin-left: -2rem;
    width: 100vw;
    max-width: 100vw;
  }

  .tipBox.mobile.active ~ .layerMobile {
    width: calc(100% + 4rem);
    left: -2rem;
  }
}

.tipBox * {
  overflow-wrap: break-word;
}

.tipBox.active {
  display: block;
}

.tipBox h2 {
  padding: 1rem 4.5rem 0 0;
}

.tipBox .closeBtn {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
}

.loader {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #00023A;
  width: 100%;
  height: 100%;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.loader .h1 {
  transition: 0s;
  display: block;
  font-family: "PT Root UI lightn";
}

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root {
  --blue: #00023A;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #EE5D49;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #61D1A2;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #CBCCD7;
  --gray-dark: #343a40;
  --primary: #00023A;
  --secondary: #85869D;
  --success: #61D1A2;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #EE5D49;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(41, 42, 61, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "PT Root UI lightn";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #00023A;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #292A3D;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #CBCCD7;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #CBCCD7;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(41, 42, 61, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #CBCCD7;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #EDEEF2;
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #CBCCD7;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1000px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1384px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #00023A;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #EDEEF2;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #EDEEF2;
}

.table tbody + tbody {
  border-top: 2px solid #EDEEF2;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #EDEEF2;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #EDEEF2;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(41, 42, 61, 0.05);
}

.table-hover tbody tr:hover {
  color: #00023A;
  background-color: rgba(41, 42, 61, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8b8c8;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7a7b99;
}

.table-hover .table-primary:hover {
  background-color: #aaaabd;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #aaaabd;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddde4;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #c0c0cc;
}

.table-hover .table-secondary:hover {
  background-color: #cfcfd9;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfcfd9;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d3f2e5;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #ade7cf;
}

.table-hover .table-success:hover {
  background-color: #bfecd9;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #bfecd9;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fad2cc;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f6aba0;
}

.table-hover .table-danger:hover {
  background-color: #f8beb5;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f8beb5;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(41, 42, 61, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(31, 32, 46, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(31, 32, 46, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #85869D;
  background-color: #e9ecef;
  border-color: #EDEEF2;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 2rem;
  padding: 0rem 0rem;
  font-family: "PT Root UI lightn";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #292A3D;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #292A3D;
  background-color: #fff;
  border-color: #0006ba;
  outline: 0;
  box-shadow: 0;
}

.form-control::placeholder {
  color: #85869D;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #292A3D;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0rem + 0);
  padding-bottom: calc(0rem + 0);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 0);
  padding-bottom: calc(0.5rem + 0);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 0);
  padding-bottom: calc(0.25rem + 0);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-bottom: 0;
  line-height: 1;
  color: #00023A;
  background-color: transparent;
  border: solid transparent;
  border-width: 0 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 0);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 0);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #CBCCD7;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #61D1A2;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.7;
  color: #212529;
  background-color: rgba(97, 209, 162, 0.9);
  border-radius: 0.375rem;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #61D1A2;
  padding-right: calc(1em + 0rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2361D1A2' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.25em + 0rem);
  background-size: calc(0.5em + 0rem) calc(0.5em + 0rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #61D1A2;
  box-shadow: 0 0 0 0.2rem rgba(97, 209, 162, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1em + 0rem);
  background-position: top calc(0.25em + 0rem) right calc(0.25em + 0rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #61D1A2;
  padding-right: calc((1em + 3rem) * 3 / 4 + 2.625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.625rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2361D1A2' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2.625rem/calc(0.5em + 0rem) calc(0.5em + 0rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #61D1A2;
  box-shadow: 0 0 0 0.2rem rgba(97, 209, 162, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #61D1A2;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #61D1A2;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #61D1A2;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #89ddb9;
  background-color: #89ddb9;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(97, 209, 162, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #61D1A2;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #61D1A2;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #61D1A2;
  box-shadow: 0 0 0 0.2rem rgba(97, 209, 162, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #EE5D49;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.7;
  color: #fff;
  background-color: rgba(238, 93, 73, 0.9);
  border-radius: 0.375rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #EE5D49;
  padding-right: calc(1em + 0rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23EE5D49' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23EE5D49' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.25em + 0rem);
  background-size: calc(0.5em + 0rem) calc(0.5em + 0rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #EE5D49;
  box-shadow: 0 0 0 0.2rem rgba(238, 93, 73, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1em + 0rem);
  background-position: top calc(0.25em + 0rem) right calc(0.25em + 0rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #EE5D49;
  padding-right: calc((1em + 3rem) * 3 / 4 + 2.625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.625rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23EE5D49' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23EE5D49' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 2.625rem/calc(0.5em + 0rem) calc(0.5em + 0rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #EE5D49;
  box-shadow: 0 0 0 0.2rem rgba(238, 93, 73, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #EE5D49;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #EE5D49;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #EE5D49;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f28778;
  background-color: #f28778;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(238, 93, 73, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #EE5D49;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #EE5D49;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #EE5D49;
  box-shadow: 0 0 0 0.2rem rgba(238, 93, 73, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-family: "PT Root UI bold";
  font-weight: 400;
  color: #00023A;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1.1875rem 1.5rem;
  font-size: 1rem;
  line-height: 1.7;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #00023A;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 2, 58, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #00023A;
  border-color: #00023A;
}

.btn-primary:hover {
  color: #fff;
  background-color: #000114;
  border-color: #000007;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 40, 88, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #00023A;
  border-color: #00023A;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #000007;
  border-color: black;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 40, 88, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #85869D;
  border-color: #85869D;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #70718c;
  border-color: #6a6c85;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(151, 152, 172, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #85869D;
  border-color: #85869D;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6a6c85;
  border-color: #65667d;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(151, 152, 172, 0.5);
}

.btn-success {
  color: #212529;
  background-color: #61D1A2;
  border-color: #61D1A2;
}

.btn-success:hover {
  color: #212529;
  background-color: #43c891;
  border-color: #3ac68b;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(87, 183, 144, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #212529;
  background-color: #61D1A2;
  border-color: #61D1A2;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3ac68b;
  border-color: #37bc84;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(87, 183, 144, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #EE5D49;
  border-color: #EE5D49;
}

.btn-danger:hover {
  color: #fff;
  background-color: #eb3e26;
  border-color: #ea331a;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 117, 100, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #EE5D49;
  border-color: #EE5D49;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea331a;
  border-color: #e22e15;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 117, 100, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #00023A;
  border-color: #00023A;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #00023A;
  border-color: #00023A;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 2, 58, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #00023A;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #00023A;
  border-color: #00023A;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 2, 58, 0.5);
}

.btn-outline-secondary {
  color: #85869D;
  border-color: #85869D;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #85869D;
  border-color: #85869D;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 134, 157, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #85869D;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #85869D;
  border-color: #85869D;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 134, 157, 0.5);
}

.btn-outline-success {
  color: #61D1A2;
  border-color: #61D1A2;
}

.btn-outline-success:hover {
  color: #212529;
  background-color: #61D1A2;
  border-color: #61D1A2;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 209, 162, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #61D1A2;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #61D1A2;
  border-color: #61D1A2;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 209, 162, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #EE5D49;
  border-color: #EE5D49;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #EE5D49;
  border-color: #EE5D49;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 93, 73, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #EE5D49;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #EE5D49;
  border-color: #EE5D49;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 93, 73, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #292A3D;
  text-decoration: none;
}

.btn-link:hover {
  color: #CBCCD7;
  text-decoration: none;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #CBCCD7;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.6875rem 0.6875rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #00023A;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(41, 42, 61, 0.15);
  border-radius: 0.375rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #00023A;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #CBCCD7;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #CBCCD7;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.515625rem;
  padding-left: 0.515625rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: 0;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: 0;
}

.input-group-prepend {
  margin-right: 0;
}

.input-group-append {
  margin-left: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0rem 0rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #292A3D;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 0 solid #ced4da;
  border-radius: 0;
}

.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 0);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 0);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.625rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.7rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00023A;
  background-color: #00023A;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #0006ba;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #0008ed;
  border-color: #0008ed;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #CBCCD7;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.35rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #CBCCD7 solid 0;
}

.custom-control-label::after {
  position: absolute;
  top: 0.35rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.375rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #00023A;
  background-color: #00023A;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 2, 58, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 2, 58, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 2, 58, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.35rem + 0);
  left: calc(-2.25rem + 0);
  width: calc(1rem - 0);
  height: calc(1rem - 0);
  background-color: #CBCCD7;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 2, 58, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 2rem;
  padding: 1.5rem 2.625rem 1.5rem 1.625rem;
  font-family: "PT Root UI lightn";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #292A3D;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.625rem center/8px 10px;
  background-color: #fff;
  border: 0 solid #ced4da;
  border-radius: 0.375rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #0006ba;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 2, 58, 0.25);
}

.custom-select:focus::-ms-value {
  color: #292A3D;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1.625rem;
  background-image: none;
}

.custom-select:disabled {
  color: #CBCCD7;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 0);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 0);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2rem;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 2rem;
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #0006ba;
  box-shadow: 0;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 2rem;
  padding: 0rem 0rem;
  font-family: "PT Root UI lightn";
  font-weight: 400;
  line-height: 1;
  color: #292A3D;
  background-color: #fff;
  border: 0 solid #ced4da;
  border-radius: 0;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1em + 0rem);
  padding: 0rem 0rem;
  line-height: 1;
  color: #292A3D;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #00023A;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #0008ed;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #EDEEF2;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #00023A;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #0008ed;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #EDEEF2;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #00023A;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #0008ed;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #EDEEF2;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #EDEEF2;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #CBCCD7;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #CBCCD7;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #CBCCD7;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #CBCCD7;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #EDEEF2;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #EDEEF2;
}

.nav-tabs .nav-link.disabled {
  color: #CBCCD7;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #85869D;
  background-color: #fff;
  border-color: #EDEEF2 #EDEEF2 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.375rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #00023A;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.2875rem;
  padding-bottom: 0.2875rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(41, 42, 61, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(41, 42, 61, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(41, 42, 61, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(41, 42, 61, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(41, 42, 61, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(41, 42, 61, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(41, 42, 61, 0.5);
  border-color: rgba(41, 42, 61, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(41, 42, 61, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(41, 42, 61, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(41, 42, 61, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(41, 42, 61, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(41, 42, 61, 0.125);
  border-radius: 0.375rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(41, 42, 61, 0.03);
  border-bottom: 1px solid rgba(41, 42, 61, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(41, 42, 61, 0.03);
  border-top: 1px solid rgba(41, 42, 61, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.375rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.375rem - 1px);
  border-bottom-left-radius: calc(0.375rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.375rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #CBCCD7;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #CBCCD7;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.375rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #292A3D;
  background-color: #fff;
  border: 1px solid #EDEEF2;
}

.page-link:hover {
  z-index: 2;
  color: #CBCCD7;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #EDEEF2;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 2, 58, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #00023A;
  border-color: #00023A;
}

.page-item.disabled .page-link {
  color: #CBCCD7;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #EDEEF2;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #00023A;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #000007;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 2, 58, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #85869D;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #6a6c85;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(133, 134, 157, 0.5);
}

.badge-success {
  color: #212529;
  background-color: #61D1A2;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #212529;
  background-color: #3ac68b;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(97, 209, 162, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #EE5D49;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #ea331a;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(238, 93, 73, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.375rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #14153b;
  background-color: #ccccd8;
  border-color: #b8b8c8;
}

.alert-primary hr {
  border-top-color: #aaaabd;
}

.alert-primary .alert-link {
  color: #070715;
}

.alert-secondary {
  color: #595a6f;
  background-color: #e7e7eb;
  border-color: #dddde4;
}

.alert-secondary hr {
  border-top-color: #cfcfd9;
}

.alert-secondary .alert-link {
  color: #424353;
}

.alert-success {
  color: #468172;
  background-color: #dff6ec;
  border-color: #d3f2e5;
}

.alert-success hr {
  border-top-color: #bfecd9;
}

.alert-success .alert-link {
  color: #346055;
}

.alert-info {
  color: #20687d;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #164654;
}

.alert-warning {
  color: #987921;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #6e5818;
}

.alert-danger {
  color: #8f4543;
  background-color: #fcdfdb;
  border-color: #fad2cc;
}

.alert-danger hr {
  border-top-color: #f8beb5;
}

.alert-danger .alert-link {
  color: #6c3433;
}

.alert-light {
  color: #95969f;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #7a7b87;
}

.alert-dark {
  color: #2f323f;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #191b22;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.375rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #00023A;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #85869D;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #85869D;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #00023A;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(41, 42, 61, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #CBCCD7;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #00023A;
  border-color: #00023A;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #14153b;
  background-color: #b8b8c8;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #14153b;
  background-color: #aaaabd;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #14153b;
  border-color: #14153b;
}

.list-group-item-secondary {
  color: #595a6f;
  background-color: #dddde4;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #595a6f;
  background-color: #cfcfd9;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #595a6f;
  border-color: #595a6f;
}

.list-group-item-success {
  color: #468172;
  background-color: #d3f2e5;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #468172;
  background-color: #bfecd9;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #468172;
  border-color: #468172;
}

.list-group-item-info {
  color: #20687d;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #20687d;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #20687d;
  border-color: #20687d;
}

.list-group-item-warning {
  color: #987921;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #987921;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #987921;
  border-color: #987921;
}

.list-group-item-danger {
  color: #8f4543;
  background-color: #fad2cc;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #8f4543;
  background-color: #f8beb5;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #8f4543;
  border-color: #8f4543;
}

.list-group-item-light {
  color: #95969f;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #95969f;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #95969f;
  border-color: #95969f;
}

.list-group-item-dark {
  color: #2f323f;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #2f323f;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2f323f;
  border-color: #2f323f;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #292A3D;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #292A3D;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(41, 42, 61, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #CBCCD7;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(41, 42, 61, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #292A3D;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #EDEEF2;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.7;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #EDEEF2;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "PT Root UI lightn";
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #292A3D;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #292A3D;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #292A3D;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #292A3D;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #292A3D;
  border-radius: 0.375rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "PT Root UI lightn";
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(41, 42, 61, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(41, 42, 61, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(41, 42, 61, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(41, 42, 61, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(41, 42, 61, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #00023A;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #00023A !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #000007 !important;
}

.bg-secondary {
  background-color: #85869D !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #6a6c85 !important;
}

.bg-success {
  background-color: #61D1A2 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3ac68b !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #EE5D49 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea331a !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #EDEEF2 !important;
}

.border-top {
  border-top: 1px solid #EDEEF2 !important;
}

.border-right {
  border-right: 1px solid #EDEEF2 !important;
}

.border-bottom {
  border-bottom: 1px solid #EDEEF2 !important;
}

.border-left {
  border-left: 1px solid #EDEEF2 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #00023A !important;
}

.border-secondary {
  border-color: #85869D !important;
}

.border-success {
  border-color: #61D1A2 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #EE5D49 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.375rem !important;
}

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-right {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-left {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(41, 42, 61, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(41, 42, 61, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(41, 42, 61, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #00023A !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: black !important;
}

.text-secondary {
  color: #85869D !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #5f6076 !important;
}

.text-success {
  color: #61D1A2 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #34b27d !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #EE5D49 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #d62c14 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #00023A !important;
}

.text-muted {
  color: #CBCCD7 !important;
}

.text-black-50 {
  color: rgba(41, 42, 61, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #CBCCD7;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

@page {
    size: a3;
}

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #292A3D;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #EDEEF2 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #EDEEF2;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #EDEEF2;
  }
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #EDEEF2;
  border-radius: 0.375rem;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #EDEEF2;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #EDEEF2;
  border-radius: 0;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #EDEEF2;
  border-radius: 0.375rem;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #EDEEF2;
  border-radius: 0.375rem;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #EDEEF2;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.select2-container--bootstrap4 {
  display: block;
}

.select2-container--bootstrap4 .select2-selection {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0;
  color: #292A3D;
  font-size: 1rem;
  outline: 0;
}

.select2-container--bootstrap4 .select2-selection.form-control {
  border-radius: 0.375rem;
}

.select2-container--bootstrap4 .select2-search--dropdown .select2-search__field {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0;
  color: #292A3D;
  font-size: 1rem;
}

.select2-container--bootstrap4 .select2-search__field {
  outline: 0;
}

.select2-container--bootstrap4 .select2-search__field::-webkit-input-placeholder {
  color: #85869D;
}

.select2-container--bootstrap4 .select2-search__field:-moz-placeholder {
  color: #85869D;
}

.select2-container--bootstrap4 .select2-search__field::-moz-placeholder {
  color: #85869D;
  opacity: 1;
}

.select2-container--bootstrap4 .select2-search__field:-ms-input-placeholder {
  color: #85869D;
}

.select2-container--bootstrap4 .select2-results__option {
  padding: 1.625rem 1.5rem;
  font-size: 1rem;
}

.select2-container--bootstrap4 .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--bootstrap4 .select2-results__option[aria-disabled=true] {
  color: #CBCCD7;
  cursor: not-allowed;
}

.select2-container--bootstrap4 .select2-results__option[aria-selected=true] {
  background-color: #f8f9fa;
  color: #16181b;
}

.select2-container--bootstrap4 .select2-results__option--highlighted[aria-selected] {
  background-color: #00023A;
  color: #fff;
}

.select2-container--bootstrap4 .select2-results__option .select2-results__option {
  padding: 1.625rem 1.5rem;
}

.select2-container--bootstrap4 .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--bootstrap4 .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1.5rem;
  padding-left: 3rem;
}

.select2-container--bootstrap4 .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3rem;
  padding-left: 4.5rem;
}

.select2-container--bootstrap4 .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4.5rem;
  padding-left: 6rem;
}

.select2-container--bootstrap4 .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -6rem;
  padding-left: 7.5rem;
}

.select2-container--bootstrap4 .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -7.5rem;
  padding-left: 9rem;
}

.select2-container--bootstrap4 .select2-results__group {
  color: #CBCCD7;
  display: block;
  padding: 1.625rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1;
  white-space: nowrap;
}

.select2-container--bootstrap4.select2-container--focus .select2-selection,
.select2-container--bootstrap4.select2-container--open .select2-selection {
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-color: #0006ba;
}

@media (prefers-reduced-motion: reduce) {
  .select2-container--bootstrap4.select2-container--focus .select2-selection,
  .select2-container--bootstrap4.select2-container--open .select2-selection {
    transition: none;
  }
}

.select2-container--bootstrap4.select2-container--open .select2-selection .select2-selection__arrow b {
  border-color: transparent transparent #85869D transparent;
  border-width: 0 0.25rem 0.25rem 0.25rem;
}

.select2-container--bootstrap4.select2-container--open.select2-container--below .select2-selection {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-color: transparent;
}

.select2-container--bootstrap4.select2-container--open.select2-container--above .select2-selection {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-color: transparent;
}

.select2-container--bootstrap4 .select2-selection__clear {
  color: #85869D;
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--bootstrap4 .select2-selection__clear:hover {
  color: #343a40;
}

.select2-container--bootstrap4.select2-container--disabled .select2-selection {
  border-color: #ced4da;
}

.select2-container--bootstrap4.select2-container--disabled .select2-selection,
.select2-container--bootstrap4.select2-container--disabled .select2-search__field {
  cursor: not-allowed;
}

.select2-container--bootstrap4.select2-container--disabled .select2-selection,
.select2-container--bootstrap4.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  background-color: #e9ecef;
}

.select2-container--bootstrap4.select2-container--disabled .select2-selection__clear,
.select2-container--bootstrap4.select2-container--disabled .select2-selection--multiple .select2-selection__choice__remove {
  display: none;
}

.select2-container--bootstrap4 .select2-dropdown {
  border-color: #0006ba;
  border-width: 1px;
  overflow-x: hidden;
  margin-top: -1px;
}

.select2-container--bootstrap4 .select2-dropdown--above {
  margin-top: 1px;
}

.select2-container--bootstrap4 .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--bootstrap4 .select2-selection--single {
  height: 2rem;
  line-height: 1;
  padding: 1.625rem 2.25rem 1.625rem 1.5rem;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow {
  position: absolute;
  bottom: 0;
  right: 1.5rem;
  top: 0;
  width: 0.25rem;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b {
  border-color: #85869D transparent transparent transparent;
  border-style: solid;
  border-width: 0.25rem 0.25rem 0 0.25rem;
  height: 0;
  left: 0;
  margin-left: -0.25rem;
  margin-top: -0.125rem;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered {
  color: #292A3D;
  padding: 0;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__placeholder {
  color: #85869D;
}

.select2-container--bootstrap4 .select2-selection--multiple {
  min-height: 2rem;
  padding: 0;
  height: auto;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  display: block;
  line-height: 1;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 1.625rem 1.5rem 0 1.625rem;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__placeholder {
  color: #85869D;
  float: left;
  margin-top: 5px;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
  color: #292A3D;
  background: transparent;
  border: 1px solid #343a40;
  border-radius: 0.375rem;
  cursor: default;
  float: left;
  margin: 0.625rem 0 0 0.75rem;
  padding: 0 1.625rem;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-search--inline .select2-search__field {
  background: transparent;
  padding: 0 1.5rem;
  height: 0rem;
  line-height: 1;
  margin-top: 0;
  min-width: 5em;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove {
  color: #85869D;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 0.8125rem;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #343a40;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__clear {
  margin-top: 1.625rem;
}

.select2-container--bootstrap4 .select2-selection--single.input-sm,
.input-group-sm .select2-container--bootstrap4 .select2-selection--single,
.form-group-sm .select2-container--bootstrap4 .select2-selection--single {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  height: calc(1.5em + 0.5rem + 0);
  line-height: 1;
  padding: 0.5rem 1rem 0.5rem 0.25rem;
}

.select2-container--bootstrap4 .select2-selection--single.input-sm .select2-selection__arrow b,
.input-group-sm .select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b,
.form-group-sm .select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b {
  margin-left: -0.5rem;
}

.select2-container--bootstrap4 .select2-selection--multiple.input-sm,
.input-group-sm .select2-container--bootstrap4 .select2-selection--multiple,
.form-group-sm .select2-container--bootstrap4 .select2-selection--multiple {
  min-height: calc(1.5em + 0.5rem + 0);
  border-radius: 0.2rem;
}

.select2-container--bootstrap4 .select2-selection--multiple.input-sm .select2-selection__rendered,
.input-group-sm .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered,
.form-group-sm .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
  padding: 0.5rem 0.25rem 0 0.5rem;
}

.select2-container--bootstrap4 .select2-selection--multiple.input-sm .select2-selection__choice,
.input-group-sm .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice,
.form-group-sm .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
  font-size: 0.875rem;
  line-height: 1;
  margin: 0 0 0 0.125rem;
  padding: 0 0.5rem;
}

.select2-container--bootstrap4 .select2-selection--multiple.input-sm .select2-search--inline .select2-search__field,
.input-group-sm .select2-container--bootstrap4 .select2-selection--multiple .select2-search--inline .select2-search__field,
.form-group-sm .select2-container--bootstrap4 .select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 0 0.25rem;
  font-size: 0.875rem;
  height: calc(1.5em + 0.5rem + 0)-2;
  line-height: 1;
}

.select2-container--bootstrap4 .select2-selection--multiple.input-sm .select2-selection__clear,
.input-group-sm .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__clear,
.form-group-sm .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__clear {
  margin-top: 0.5rem;
}

.select2-container--bootstrap4 .select2-selection--single.input-lg,
.input-group-lg .select2-container--bootstrap4 .select2-selection--single,
.form-group-lg .select2-container--bootstrap4 .select2-selection--single {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  height: calc(1.5em + 1rem + 0);
  line-height: 1;
  padding: 1rem 1.4375rem 1rem 0.5rem;
}

.select2-container--bootstrap4 .select2-selection--single.input-lg .select2-selection__arrow,
.input-group-lg .select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow,
.form-group-lg .select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow {
  width: 0.3125rem;
}

.select2-container--bootstrap4 .select2-selection--single.input-lg .select2-selection__arrow b,
.input-group-lg .select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b,
.form-group-lg .select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b {
  border-width: 0.3125rem 0.3125rem 0 0.3125rem;
  margin-left: -0.3125rem;
  margin-left: -1rem;
  margin-top: -0.15625rem;
}

.select2-container--bootstrap4 .select2-selection--multiple.input-lg,
.input-group-lg .select2-container--bootstrap4 .select2-selection--multiple,
.form-group-lg .select2-container--bootstrap4 .select2-selection--multiple {
  min-height: calc(1.5em + 1rem + 0);
  border-radius: 0.3rem;
}

.select2-container--bootstrap4 .select2-selection--multiple.input-lg .select2-selection__choice,
.input-group-lg .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice,
.form-group-lg .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 0.375rem;
  margin: 0rem 0 0 0.25rem;
  padding: 0 1rem;
}

.select2-container--bootstrap4 .select2-selection--multiple.input-lg .select2-search--inline .select2-search__field,
.input-group-lg .select2-container--bootstrap4 .select2-selection--multiple .select2-search--inline .select2-search__field,
.form-group-lg .select2-container--bootstrap4 .select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 0 0.5rem;
  font-size: 1.25rem;
  height: calc(1.5em + 1rem + 0)-2;
  line-height: 1;
}

.select2-container--bootstrap4 .select2-selection--multiple.input-lg .select2-selection__clear,
.input-group-lg .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__clear,
.form-group-lg .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__clear {
  margin-top: 1rem;
}

.select2-container--bootstrap4 .select2-selection.input-lg.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #85869D transparent;
  border-width: 0 0.3125rem 0.3125rem 0.3125rem;
}

.input-group-lg .select2-container--bootstrap4 .select2-selection.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #85869D transparent;
  border-width: 0 0.3125rem 0.3125rem 0.3125rem;
}

.select2-container--bootstrap4[dir=rtl] .select2-selection--single {
  padding-left: 2.25rem;
  padding-right: 1.5rem;
}

.select2-container--bootstrap4[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 0;
  padding-left: 0;
  text-align: right;
}

.select2-container--bootstrap4[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--bootstrap4[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1.5rem;
  right: auto;
}

.select2-container--bootstrap4[dir=rtl] .select2-selection--single .select2-selection__arrow b {
  margin-left: 0;
}

.select2-container--bootstrap4[dir=rtl] .select2-selection--multiple .select2-selection__choice,
.select2-container--bootstrap4[dir=rtl] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--bootstrap4[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--bootstrap4[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 0;
  margin-right: 0.75rem;
}

.select2-container--bootstrap4[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.has-warning .select2-dropdown,
.has-warning .select2-selection {
  border-color: #ffc107;
}

.has-warning .select2-container--focus .select2-selection,
.has-warning .select2-container--open .select2-selection {
  border-color: #d39e00;
}

.has-warning.select2-drop-active {
  border-color: #d39e00;
}

.has-warning.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #d39e00;
}

.has-error .select2-dropdown,
.has-error .select2-selection {
  border-color: #EE5D49;
}

.has-error .select2-container--focus .select2-selection,
.has-error .select2-container--open .select2-selection {
  border-color: #ea331a;
}

.has-error.select2-drop-active {
  border-color: #ea331a;
}

.has-error.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #ea331a;
}

.has-success .select2-dropdown,
.has-success .select2-selection {
  border-color: #61D1A2;
}

.has-success .select2-container--focus .select2-selection,
.has-success .select2-container--open .select2-selection {
  border-color: #3ac68b;
}

.has-success.select2-drop-active {
  border-color: #3ac68b;
}

.has-success.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #3ac68b;
}

.input-group > .select2-hidden-accessible:first-child + .select2-container--bootstrap4 > .selection > .select2-selection,
.input-group > .select2-hidden-accessible:first-child + .select2-container--bootstrap4 > .selection > .select2-selection.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .select2-hidden-accessible:not(:first-child) + .select2-container--bootstrap4:not(:last-child) > .selection > .select2-selection,
.input-group > .select2-hidden-accessible:not(:first-child) + .select2-container--bootstrap4:not(:last-child) > .selection > .select2-selection.form-control {
  border-radius: 0;
}

.input-group > .select2-hidden-accessible:not(:first-child):not(:last-child) + .select2-container--bootstrap4:last-child > .selection > .select2-selection,
.input-group > .select2-hidden-accessible:not(:first-child):not(:last-child) + .select2-container--bootstrap4:last-child > .selection > .select2-selection.form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .select2-container--bootstrap4 {
  display: table;
  table-layout: fixed;
  position: relative;
  z-index: 2;
  width: 100%;
  margin-bottom: 0;
}

.input-group > .select2-container--bootstrap4 > .selection > .select2-selection.form-control {
  float: none;
}

.input-group > .select2-container--bootstrap4.select2-container--open,
.input-group > .select2-container--bootstrap4.select2-container--focus {
  z-index: 3;
}

.input-group > .select2-container--bootstrap4,
.input-group > .select2-container--bootstrap4 .input-group-btn,
.input-group > .select2-container--bootstrap4 .input-group-btn .btn {
  vertical-align: top;
}

.form-control.select2-hidden-accessible {
  position: absolute !important;
  width: 1px !important;
}

@media (min-width: 576px) {
  .form-inline .select2-container--bootstrap4 {
    display: inline-block;
  }
}

/* + styleGuide */

.styleGuide .itemSg {
  border: 2px solid;
  border-bottom: 0;
  border-color: #002B46;
}

.styleGuide .itemSg:last-child {
  border-bottom: 2px solid;
}

.styleGuide .itemSg .sg_input {
  display: none;
}

.styleGuide .itemSg .sg_input:checked ~ .sg_label {
  background-color: #002b46;
  color: #fff;
}

.styleGuide .itemSg .sg_input:checked ~ .sg_label ~ .box {
  display: block;
  background: #ccc;
}

.styleGuide .itemSg .box {
  display: none;
  background-color: #fff;
  padding: 20px 20px;
}

.styleGuide .itemSg .box .box-item {
  margin-bottom: 25px;
  max-width: 1200px;
  background-color: #fff;
  padding: 5px;
}

.styleGuide .itemSg .box .box-item textarea {
  margin-top: 10px;
}

.styleGuide .itemSg .sg_label {
  width: 100%;
  font-size: 1.4rem;
  padding: 10px 30px;
  margin: 0;
  cursor: pointer;
}

.styleGuide .itemSg textarea {
  width: 100%;
  min-height: 70px;
}

@media screen and (max-width: 768px) {
  .styleGuide .itemSg .box {
    padding: 0;
  }

  .styleGuide .itemSg .box .box-item {
    padding: 10px 10px;
  }

  .styleGuide .itemSg .box .box-item > .box-item {
    padding: 0;
  }

  .styleGuide .itemSg .box .navbar {
    width: 100vw;
    margin-left: -10px;
  }

  .styleGuide .itemSg {
    border: 0;
  }
}

/* - styleGuide */

/* + loader */

.loader {
  display: flex;
  padding-bottom: 50px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 9999;
  position: fixed;
}

.loader .logo {
  background: url("./../img/logo-full.svg") no-repeat center;
  background-size: contain;
  width: 250px;
  height: 100px;
  display: block;
}

/* - loader */

/* + header */

header {
  width: 100%;
  position: absolute;
  top: 2rem;
  z-index: 5;
  transition: 0.3s;
}

header.main {
  top: 4.5rem;
}

@media screen and (min-width: 768px) {
  header.main {
    position: absolute;
  }

  header.main.hidden {
    transform: translate3d(0, calc(-100% - 4.5rem), 0);
  }
}

header.white .nav .nav-item .nav-link.active .text,
header.main .nav .nav-item .nav-link.active .text {
  color: #fff;
}

header.white .logo,
header.main .logo {
  background: url("./../img/logo.svg") no-repeat center;
  background-size: contain;
  width: 370px;
  height: 80px;
}

header.white .hamburger-inner,
header.white .hamburger-inner::before,
header.white .hamburger-inner::after,
header.main .hamburger-inner,
header.main .hamburger-inner::before,
header.main .hamburger-inner::after {
  background-color: #fff;
}

header.open .logo {
  background: url("./../img/logo.svg") no-repeat center;
  background-size: contain;
}

header.open .nav .nav-item .nav-link.active .text {
  color: #fff;
}

header .d-flex {
  align-items: center;
  justify-content: space-between;
}

header .hamburger {
  display: none;
}

header .hamburger-inner,
header .hamburger-inner::before,
header .hamburger-inner::after {
  width: 36px;
  height: 2px;
  background-color: #292A3D;
}

header .hamburger.is-active .hamburger-inner,
header .hamburger.is-active .hamburger-inner::before,
header .hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}

header .nav {
  align-items: center;
  margin-left: auto;
}

header .nav .nav-item {
  padding: 0;
  margin-right: 1.25rem;
}

header .nav .nav-item:last-child {
  margin-right: 0;
}

header .nav .nav-item .nav-link {
  padding: 0.5rem 0.375rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

header .nav .nav-item .nav-link .text-wrap {
  overflow: hidden;
  line-height: 1.1875rem;
  display: block;
  position: relative;
}

header .nav .nav-item .nav-link .text {
  color: #CBCCD7;
  font-family: "PT Root UI bold";
  padding: 0;
  transition: 0.3s;
  font-size: 1rem;
  display: block;
}

header .nav .nav-item .nav-link .text:before {
  content: attr(data-hover);
  line-height: 1.1875rem;
  position: absolute;
  left: 0;
  top: 100%;
  transition: 0.3s;
  transform: translate3d(0, 0, 0);
  font-family: "PT Root UI bold";
}

header .nav .nav-item .nav-link:hover.active .text-wrap .text {
  transform: translateY(0);
}

header .nav .nav-item .nav-link:hover .text-wrap .text {
  transform: translateY(-100%);
}

header .nav .nav-item .nav-link.active .text {
  color: #292A3D;
}

header .phone-h {
  color: #CBCCD7;
  padding-left: 1.5rem;
  margin-left: 1.5rem;
  border-left: 1px solid #CBCCD7;
  padding-right: 1.5rem;
  border-right: 1px solid #CBCCD7;
  line-height: 1.5rem;
  font-size: 1rem;
  font-family: "PT Root UI lightn";
}

header .basket {
  z-index: 1;
  position: relative;
  margin-left: 1.5rem;
}

header .basket .basketLink {
  cursor: pointer;
  background: url("./../img/basket-ic.svg") no-repeat center;
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  display: block;
}

header .basket .basketLink:hover ~ .tooltip-basket {
  display: block;
}

header .basket .basketLink .count {
  line-height: 1rem;
  min-width: 1rem;
  text-align: center;
  font-size: 12px;
  color: #85869D;
  position: absolute;
  right: -3px;
  bottom: -3px;
  border-radius: 100%;
  font-family: "PT Root UI bold";
}

header .basket .tooltip-basket {
  position: absolute;
  top: calc(100% + 0.5rem);
  background-color: #fff;
  box-shadow: 0px 12px 24px rgba(0, 2, 58, 0.14);
  right: 0;
  padding: 1.5rem 2rem;
  width: 360px;
  z-index: -10;
  display: none;
}

header .basket .tooltip-basket:hover {
  display: block;
}

header .basket .tooltip-basket:before {
  content: "";
  position: absolute;
  bottom: 100%;
  width: 100%;
  left: 0;
  height: 0.5rem;
  display: block;
}

header .basket .tooltip-basket .tooltipGoods {
  max-height: 336px;
  overflow: auto;
  width: calc(100% + 1rem);
  padding-right: 1rem;
  margin-bottom: 1.5rem;
}

header .basket .tooltip-basket .tooltipGoods .item {
  display: flex;
  margin-bottom: 1.5rem;
}

header .basket .tooltip-basket .tooltipGoods .item:last-child {
  margin: 0;
}

header .basket .tooltip-basket .tooltipGoods .item .img-wrap {
  width: 64px;
  min-width: 64px;
  max-height: 96px;
}

header .basket .tooltip-basket .tooltipGoods .item .img-wrap img {
  width: 100%;
  object-fit: contain;
}

header .basket .tooltip-basket .tooltipGoods .item .wrap {
  padding-left: 1.5rem;
  width: calc(100% - 64px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

header .basket .tooltip-basket .tooltipGoods .item .wrap .name {
  margin: 0;
  line-height: 1.5;
  padding: 12px 0;
  width: 100%;
}

header .basket .tooltip-basket .tooltipGoods .item .wrap .signature {
  margin: 0;
  line-height: 1.5;
  padding: 3px 0;
  width: 100%;
}

header .basket .tooltip-basket .tooltipGoods .item .wrap .flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

header .basket .tooltip-basket .tooltipGoods .item .wrap .flex p {
  margin: 0;
  line-height: 1.5;
}

header .basket .tooltip-basket .tooltipGoods .item .wrap .flex .price {
  font-family: "PT Root UI bold";
}

header .basket .tooltip-basket .tooltipGoods .item .wrap .flex .pack {
  color: #85869D;
}

header .basket .tooltip-basket .resultPrice {
  line-height: 2rem;
  text-align: right;
  margin-bottom: 1.5rem;
  font-family: "PT Root UI bold";
}

header .basket .tooltip-basket .btn {
  width: 100%;
}

header .logo {
  width: 223px;
  height: 48px;
  background: url("./../img/logo-full.svg") no-repeat center;
  background-size: contain;
  display: block;
  transition: 0s;
}

header .social {
  display: none;
}

@media screen and (max-width: 1199px) {
  header.main {
    top: 4rem;
  }

  header.white .logo,
  header.main .logo {
    width: 224px;
    height: 48px;
  }

  header .logo {
    width: 224px;
    height: 48px;
  }
}

@media screen and (max-width: 991px) {
  header .phone-h {
    padding-left: 0.5rem;
    margin-left: 0.5rem;
    padding-right: 0.5rem;
    border: none;
  }

  header .basket {
    margin-left: 0.5rem;
  }

  header .nav .nav-item {
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: 767px) {
  header.main {
    top: 3rem;
  }

  header.main .logo {
    width: 195px;
    height: 42px;
  }

  header.main .nav {
    top: -3rem;
  }

  header .logo {
    width: 144px !important;
    height: 31px !important;
    z-index: 2;
    order: 1;
  }

  header .nav {
    z-index: 1;
    display: none;
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: -2rem;
  }

  header .nav:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00023A;
    opacity: 1;
  }

  header .nav.active {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 5rem;
  }

  header .nav .nav-item {
    width: 100%;
    margin: 0.5rem 0;
    text-align: center;
  }

  header .nav .nav-item .nav-link {
    display: inline-flex;
  }

  header .nav.active ~ .social {
    display: block;
    position: absolute;
    z-index: 3;
    top: calc(100vh - 144px);
    left: 0;
    width: 100%;
    text-align: center;
  }

  header .nav.active ~ .social .icBtn {
    margin: 0 0.75rem;
  }

  header .hamburger {
    display: block;
    z-index: 2;
    order: 3;
  }

  header .phone-h {
    display: none;
  }

  header .nav.active ~ .phone-h {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    display: block;
    margin: auto;
    width: calc(100% - 4rem);
    text-align: center;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    border-bottom: 1px solid #85869D;
    border-top: 1px solid #85869D;
    max-width: 476px;
    top: calc(100vh - calc(3rem + 37vh));
  }

  header .basket {
    order: 2;
    margin-left: auto;
    margin-right: 1.5rem;
  }

  header .basket .tooltip-basket {
    display: none !important;
  }
}

/* - header */

/* + footer */

footer {
  border-top: 1px solid #EDEEF2;
  padding-top: 1.5rem;
  background-color: #fff;
}

footer .d-flex {
  justify-content: space-between;
}

footer .d-flex .col {
  width: 25%;
  padding: 0 0 0 1.5rem;
  border-left: 1px solid #EDEEF2;
}

footer .d-flex .col:first-child {
  padding-left: 0;
  border: none;
  display: flex;
  align-items: center;
}

footer .btn.btn-outline-secondary {
  width: 112px;
  margin: auto 0;
  display: block;
}

footer .nav {
  width: 100%;
  padding: 0.25rem 0;
}

footer .nav .nav-item {
  width: 100%;
  margin: 0.5rem 0;
}

footer .nav .nav-item:first-child {
  margin-top: 0;
}

footer .nav .nav-item:last-child {
  margin-bottom: 0;
}

footer .nav .nav-item .nav-link {
  padding: 0;
  line-height: 1.5rem;
  font-family: "PT Root UI bold";
  color: #CBCCD7;
  transition: 0.3s;
}

footer .nav .nav-item .nav-link:hover {
  color: #292A3D;
}

footer .contact-f {
  padding: 0.25rem 0;
}

footer .contact-f .text {
  line-height: 1.5rem;
  font-size: 1rem;
  display: table;
  margin-bottom: 1rem;
}

footer .contact-f .text:last-child {
  margin-bottom: 0;
}

footer .text-f {
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

footer .social-f .social {
  display: block;
  width: auto;
  height: auto;
}

footer .social-f .social .icBtn {
  display: table;
  border: none;
  padding-left: 26px;
  margin: 0 0 0.75rem;
  width: auto;
  height: auto;
}

footer .social-f .social .icBtn.fb:before {
  background-image: url("./../img/fb-ic2.svg");
  background-size: 20px;
}

footer .social-f .social .icBtn.fb:after {
  background-image: url("./../img/fb-ic2-2.svg");
  background-size: 20px;
}

footer .social-f .social .icBtn:before {
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin: auto;
  right: auto;
}

footer .social-f .social .icBtn:after {
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin: auto;
  right: auto;
}

footer .social-f .social .icBtn:hover span {
  color: #292A3D;
}

footer .social-f .social .icBtn span {
  color: #CBCCD7;
  font-size: 16px;
  font-family: "PT Root UI bold";
  transition: 0.3s;
}

footer .line-f {
  padding: 1.5rem 0;
  margin-top: 1.5rem;
  background-image: url("./../img/pipples2.png");
  background-color: #00023A;
}

footer .line-f .signature {
  margin: 0;
  color: #fff;
  line-height: 1.5rem;
}

footer .line-f .signature a {
  color: inherit;
  transition: 0.3s;
}

footer .line-f .signature a:hover {
  opacity: 0.8;
}

footer .line-f .link {
  margin-left: auto;
  line-height: 1.5rem;
}

@media screen and (max-width: 1199px) {
  footer .d-flex .col:nth-child(3) {
    width: 35%;
    max-width: 35%;
    min-width: 35%;
  }

  footer .d-flex .col:nth-child(4) {
    width: 15%;
    min-width: 15%;
    max-width: 15%;
  }
}

@media screen and (max-width: 991px) {
  footer .d-flex .col {
    padding: 0 0 0 1rem;
  }

  footer .d-flex .col:nth-child(1) {
    width: 20%;
    max-width: 20%;
    min-width: 20%;
  }

  footer .d-flex .col:nth-child(2) {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
  }

  footer .d-flex .col:nth-child(3) {
    width: 35%;
    max-width: 35%;
    min-width: 35%;
  }

  footer .d-flex .col:nth-child(4) {
    width: 20%;
    min-width: 20%;
    max-width: 20%;
  }
}

@media screen and (max-width: 767px) {
  footer {
    padding-top: 1.5rem;
  }

  footer .d-flex {
    flex-wrap: wrap;
  }

  footer .d-flex .col {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    padding: 1.5rem 0;
    border: none;
  }

  footer .d-flex .col:nth-child(1) {
    padding: 0;
  }

  footer .d-flex .col:nth-child(3) {
    position: relative;
  }

  footer .d-flex .col:nth-child(3):before {
    content: "";
    position: absolute;
    top: 0;
    left: -2rem;
    right: 0;
    margin: auto;
    background-color: #EDEEF2;
    display: block;
    height: 1px;
    width: calc(100% + 4rem);
  }

  footer .d-flex .col:nth-child(3):after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -2rem;
    right: 0;
    margin: auto;
    background-color: #EDEEF2;
    display: block;
    height: 1px;
    width: calc(100% + 4rem);
  }

  footer .btn.btn-outline-secondary {
    width: 100%;
  }

  footer .nav {
    width: 100%;
    padding: 0;
  }

  footer .contact-f {
    padding: 0;
  }

  footer .text-right {
    width: 100%;
    margin-top: 2.5rem;
    position: relative;
    padding: 3rem 0;
    overflow: hidden;
  }

  footer .text-right:before {
    content: "";
    width: 100vw;
    left: calc((100vw - 540px) / (-2) - 32px);
    top: 0;
    position: absolute;
    height: 2px;
    background-color: #EDEEF2;
    display: block;
  }

  footer .social .icBtn:last-child {
    margin-bottom: 0;
  }

  footer .line-f {
    padding: 1.5rem 0;
    margin-top: 0;
  }

  footer .line-f .link {
    margin: auto;
  }

  footer .line-f .d-flex {
    flex-direction: column-reverse;
    justify-content: center;
  }

  footer .line-f .signature {
    width: 100%;
    text-align: center;
  }

  footer .line-f .signature:first-child {
    margin-top: 1.25rem;
  }
}

@media screen and (max-width: 575px) {
  footer .text-right:before {
    content: "";
    width: calc(100% + 4rem);
    left: -2rem;
  }
}

/* - footer */

/* + main-img */

.main-img {
  width: 100%;
  height: 100vh;
  padding: 1rem;
  position: relative;
  z-index: 1;
  margin: 0 0 5rem;
}

.main-img .img {
  position: absolute;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  top: 1rem;
  left: 1rem;
  object-fit: cover;
  font-family: "object-fit: cover;";
  z-index: -1;
}

.main-img .container {
  height: 100%;
  position: relative;
}

.main-img .playBtn {
  bottom: 4.5rem;
  position: absolute;
  left: 2rem;
}

.main-img .downBtn {
  height: 116px;
  width: 16px;
  background: url("./../img/down-text.svg") no-repeat center;
  background-size: contain;
  display: block;
  position: absolute;
  border: 0;
  padding: 0;
  right: 2rem;
  bottom: 32vh;
}

.main-img .downBtn span {
  width: 1px;
  height: calc(32vh - 1rem);
  display: block;
  background-color: #fff;
  margin: auto;
  top: calc(100% + 1rem);
  left: 4px;
  right: 0;
  position: absolute;
}

@media screen and (max-width: 991px) {
  .main-img {
    margin: 0 0 4rem;
  }
}

@media screen and (max-width: 767px) {
  .main-img {
    margin: 0 0 3rem;
  }

  .main-img .img {
    border-radius: 20px;
  }
}

.socialMain {
  position: absolute;
  left: 2rem;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.socialMain li {
  list-style-type: none;
  display: block;
  margin: 1rem 0;
}

.socialMain li a {
  color: #EDEEF2;
  margin: 0;
  display: block;
  font-family: "PT Root UI bold";
  font-size: 0;
}

.socialMain li a.fb-text {
  background: url("./../img/Facebook-text.svg") no-repeat center;
  background-size: contain;
  height: 69px;
  width: 16px;
}

.socialMain li a.in-text {
  background: url("./../img/Instagram.svg") no-repeat center;
  background-size: contain;
  height: 80px;
  width: 16px;
  margin-left: 1px;
}

/* - main-img */

/* + sectionText */

.sectionText hr {
  margin: 0;
}

.sectionText .row.indent {
  padding: 6rem 0;
}

@media screen and (max-width: 1199px) {
  .sectionText h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 991px) {
  .sectionText .row.indent {
    padding: 4rem 0;
  }

  .sectionText .btn {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sectionText .btn {
    max-width: 280px;
    width: 100%;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .sectionText .row.indent {
    padding: 1.5rem 0;
  }
}

/* - sectionText */

/* + articleSlider */

.articleSlider .owl-stage-outer {
  padding: 1rem 0;
}

.articleSlider .owl-stage-outer .owl-stage {
  display: flex;
}

.articleSlider .img-wrap {
  position: relative;
  width: 40%;
  padding-top: 384px;
}

.articleSlider .img-wrap img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.articleSlider .article-text {
  width: 60%;
  padding-left: 1.5rem;
  padding-right: 3rem;
}

.articleSlider .article-text .text {
  height: 81px;
  min-height: 80px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 1.5rem;
  padding-right: 12px;
}

.articleSlider .article-text .text.hidden {
  height: 81px;
  overflow: hidden;
  position: relative;
  word-break: break-all;
}

.articleSlider .article-text .text.hidden:before {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
}

.articleSlider .article-text .h2 {
  max-height: 114px;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.articleSlider .signature {
  margin-bottom: 0.5rem;
  width: 100%;
}

.articleSlider .item {
  width: calc(100vw - 6rem);
  max-width: 1318px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(62, 63, 78, 0.1);
  transition: 0.3s;
}

.articleSlider .item:hover {
  box-shadow: 0px 6px 13px rgba(0, 2, 58, 0.14);
}

.articleSlider .item:hover .btn.btn-outline-primary {
  opacity: 1;
}

@media screen and (min-width: 991px) {
  .articleSlider .btn.btn-outline-primary {
    opacity: 0;
  }
}

.articleSlider .owl-nav button {
  position: absolute;
  height: calc(100% - 4rem);
  width: calc((100vw - 1370px) / 2);
  top: 1rem;
  z-index: 5;
  opacity: 0;
  background-color: red !important;
}

.articleSlider .owl-nav button.owl-prev {
  left: 0;
}

.articleSlider .owl-nav button.owl-next {
  right: 0;
}

@media screen and (max-width: 1400px) {
  .articleSlider .owl-nav button {
    width: calc((100vw - 1340px) / 2);
  }
}

@media screen and (max-width: 1384px) {
  .articleSlider .owl-nav button {
    width: 1.5rem;
  }
}

@media screen and (max-width: 767px) {
  .articleSlider .item {
    flex-wrap: wrap;
    width: calc(100vw - 4rem);
  }

  .articleSlider .img-wrap {
    width: 100%;
    padding-top: 49%;
  }

  .articleSlider .article-text {
    width: 100%;
    padding: 1.5rem;
    display: flex;
    flex-wrap: wrap;
  }

  .articleSlider .article-text .h2 {
    margin-bottom: 1rem !important;
    height: 48px;
  }

  .articleSlider .text {
    display: none;
  }
}

.owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
}

.owl-dots .owl-dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  margin: 0 1.5rem;
  background-color: #CBCCD7 !important;
  display: block;
}

.owl-dots .owl-dot.active {
  background-color: #00023A !important;
}

@media screen and (max-width: 767px) {
  .owl-dots {
    margin-top: 0.5rem;
  }
}

/* - articleSlider */

/* + subscribeSection */

.subscribeSection {
  padding: 7.5rem 0;
  position: relative;
  z-index: 1;
}

.subscribeSection:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: calc(100% - ((100vw - 1350px) / 2 + 4.5rem));
  z-index: -1;
  background-image: url("./../img/ripples.png");
  background-color: #EDEEF2;
}

.subscribeSection .signature {
  margin-bottom: 0;
}

.subscribeSection .h1.m-0 {
  margin-bottom: 0.25rem !important;
  max-width: 650px;
}

.subscribeSection .form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.subscribeSection .form .form-group {
  margin-bottom: 0;
  width: calc(100% - 184px);
}

.subscribeSection .form .signature {
  width: 100%;
  margin-top: 1.5rem;
  color: #00023A;
}

.subscribeSection .form .signature a {
  font-family: "PT Root UI bold";
  color: inherit;
}

@media screen and (max-width: 1384px) {
  .subscribeSection:before {
    width: calc(100% - 5.75rem);
  }
}

@media screen and (max-width: 1199px) {
  .subscribeSection {
    padding: 6rem 0;
  }

  .subscribeSection:before {
    width: calc(100% - ((100vw - 960px) / 2 + 4.5rem));
  }

  .subscribeSection .form {
    margin-top: 3rem;
    max-width: 696px;
    margin-left: auto;
    margin-right: 0;
  }
}

@media screen and (max-width: 991px) {
  .subscribeSection:before {
    width: calc(100% - ((100vw - 720px) / 2 + 4.5rem));
  }

  .subscribeSection .form {
    max-width: 536px;
  }
}

@media screen and (max-width: 767px) {
  .subscribeSection {
    padding: 3rem 0;
  }

  .subscribeSection:before {
    width: 100%;
  }

  .subscribeSection .h1.m-0 {
    margin-bottom: 1.5rem !important;
  }

  .subscribeSection .form .form-group {
    width: 100%;
    order: 1;
  }

  .subscribeSection .form .signature {
    order: 2;
    margin: 1.5rem 0;
  }

  .subscribeSection .form .btn {
    order: 3;
    width: 100%;
    max-width: 350px;
  }
}

/* - subscribeSection */

/* + quoteSection */

.quoteSection .h1 {
  max-width: 848px;
  margin: 0 auto !important;
}

.quoteSection .indent {
  padding: 6rem 0;
}

.quoteSection hr {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .quoteSection .indent {
    padding: 2rem 0;
  }

  .quoteSection .h1 {
    padding: 0;
    font-size: 20px !important;
  }
}

/* - quoteSection */

/* + commentsSlider */

.commentsSlider .owl-stage-outer {
  padding: 1rem 0;
}

.commentsSlider .img-wrap {
  width: 240px;
  height: 240px;
  position: relative;
}

.commentsSlider .img-wrap img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.commentsSlider .sliderText {
  padding: 0 0 0 1.5rem;
  width: calc(100% - 240px);
}

.commentsSlider .sliderText .accent {
  max-height: 54px;
  overflow: hidden;
  width: 100%;
}

.commentsSlider .sliderText .signature {
  display: inline-block;
  margin-bottom: 2rem;
  color: #85869D;
  font-family: "PT Root UI bold";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.commentsSlider .sliderText .text {
  height: 81px;
  overflow: hidden;
}

.commentsSlider .item {
  padding: 3rem;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(62, 63, 78, 0.1);
  width: calc(100vw - 6rem);
  max-width: 1318px;
  transition: 0.3s;
}

.commentsSlider .item:hover {
  box-shadow: 0px 6px 13px rgba(0, 2, 58, 0.14);
}

.commentsSlider .owl-nav button {
  position: absolute;
  height: calc(100% - 4rem);
  width: calc((100vw - 1370px) / 2);
  top: 1rem;
  z-index: 5;
  opacity: 0;
  background-color: red !important;
}

.commentsSlider .owl-nav button.owl-prev {
  left: 0;
}

.commentsSlider .owl-nav button.owl-next {
  right: 0;
}

@media screen and (max-width: 1400px) {
  .commentsSlider .owl-nav button {
    width: calc((100vw - 1340px) / 2);
  }
}

@media screen and (max-width: 1384px) {
  .commentsSlider .owl-nav button {
    width: 1.5rem;
  }
}

@media screen and (max-width: 767px) {
  .commentsSlider .item {
    flex-wrap: wrap;
    width: calc(100vw - 4rem);
    padding: 1.5rem;
    position: relative;
  }

  .commentsSlider .img-wrap {
    width: 72px;
    height: 72px;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
  }

  .commentsSlider .sliderText {
    width: 100%;
    padding-left: 88px;
  }

  .commentsSlider .sliderText .text {
    width: calc(100% + 88px);
    margin-left: -88px;
    height: auto;
  }

  .commentsSlider .sliderText .signature {
    margin-bottom: 2rem;
  }

  .commentsSlider .sliderText .accent {
    font-size: 12px;
    max-height: 40px;
  }
}

/* - commentsSlider */

/* + section-static-map */

.staticImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 456px;
  background-size: cover;
}

.staticImg .btn.btn-primary {
  margin-top: 8rem;
  max-width: calc(100% - 3rem);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 20px;
}

@media screen and (max-width: 767px) {
  .staticImg {
    background-position: 0;
  }
}

/* - section-static-map */

/* + concept */

.conFlex {
  display: flex;
  align-items: flex-start;
}

.sidebar {
  position: sticky;
  width: 336px;
  top: 0;
  padding-top: 3rem;
}

.menuConcept {
  padding: 0 0 6rem;
  margin: 0;
}

.menuConcept li {
  list-style-type: none;
  padding: 13px 13px 13px 88px;
  position: relative;
}

.menuConcept li .link {
  color: #CBCCD7;
  font-size: 1rem;
  font-family: "PT Root UI bold";
  line-height: 20px;
  display: inline-block;
}

.menuConcept li .link:before {
  content: "";
  width: 62px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 1px;
  left: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
}

.menuConcept li .link.active {
  color: #fff;
}

.menuConcept li .link.active:before {
  opacity: 1;
}

.sideContent {
  position: relative;
  padding: 0 0 5rem 3rem;
  background-color: #fff;
  width: calc(100% - 336px);
  margin-bottom: 0;
}

.sideContent:before {
  content: "";
  display: block;
  width: calc((100vw - 1384px + 47px) / 2);
  left: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: #fff;
}

.sideContent .h2 {
  padding-top: 3rem;
}

.sideContent .img-wrap {
  width: 100%;
  height: 408px;
  margin-top: 3rem;
  position: relative;
}

.sideContent .img-wrap img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  object-fit: cover;
  font-family: "object-fit: cover;";
  width: 600px;
  height: 100%;
}

.sideContent .img-wrap:before {
  content: "";
  position: absolute;
  height: 264px;
  width: calc(((100vw - 1384px + 47px) / 2) + (100% - 64px));
  top: 0;
  bottom: 0;
  margin: auto;
  right: calc(((100vw - 1384px + 47px) / (-2)));
  background-image: url("./../img/ripples.png");
  background-color: #EDEEF2;
}

.sideContent .conceptBox {
  position: relative;
}

.sideContent .conceptBox.hide-layer .img-wrap {
  display: none !important;
}

.sideContent .conceptBox:nth-child(odd) .img-wrap {
  margin-left: auto;
  margin-right: 0;
  display: block;
}

.sideContent .conceptBox:nth-child(odd) .img-wrap img {
  left: auto;
  right: 0;
}

.imgConcept {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

@media screen and (max-width: 1384px) {
  .sideContent:before {
    width: 2rem;
  }

  .sideContent .img-wrap:before {
    width: calc(2rem + (100% - 64px));
    right: -2rem;
  }
}

@media screen and (max-width: 1199px) {
  .title:before {
    width: 113px;
  }

  .conFlex {
    width: calc(100vw - 4rem);
    margin-left: calc((100vw - 1000px) / (-2));
  }

  .sidebar {
    display: none;
  }

  .sideContent {
    width: 100%;
  }

  .imgConcept {
    position: absolute;
  }

  .sideContent {
    padding: 0 3rem 5rem 3rem;
    width: 100%;
  }

  .sideContent:before {
    display: none;
  }

  .sideContent .img-wrap:before {
    right: -3rem;
  }
}

@media screen and (max-width: 991px) {
  .conFlex {
    width: 100%;
    margin-left: 0;
  }

  .sideContent .img-wrap {
    height: 286px;
  }

  .sideContent .img-wrap:before {
    height: 185px;
  }

  .sideContent .img-wrap img {
    width: 420px;
  }
}

@media screen and (max-width: 767px) {
  .sideContent .img-wrap {
    height: 202px;
  }

  .sideContent .img-wrap:before {
    height: 105px;
  }

  .sideContent .img-wrap img {
    width: 296px;
  }
}

@media screen and (max-width: 575px) {
  .title {
    text-align: center;
    padding: 0 0 10px;
    margin-top: 7.75rem;
    margin-bottom: 1.5rem !important;
  }

  .title:before {
    width: 36px;
    height: 1px;
  }

  .imgConcept {
    height: 196px;
  }

  .sideContent {
    padding-left: 0;
    padding-right: 0;
  }

  .sideContent .img-wrap {
    height: 202px;
  }

  .sideContent .img-wrap:before {
    right: auto;
    left: -2rem;
  }

  .sideContent .img-wrap img {
    width: 296px;
    left: auto;
    right: 0;
    max-width: 100%;
    object-fit: contain;
    font-family: "object-fit: contain;";
  }
}

.instagram {
  background-color: #EDEEF2;
  padding: 6rem 0;
  margin: 0;
}

.instagram h2 {
  margin-bottom: 3rem !important;
}

/* - concept */

.inSlider .item {
  position: relative;
  padding-top: 100%;
  width: 200px;
}

.inSlider .item img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.inSlider .item a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1384px) {
  .inSlider {
    width: calc(100% + 2rem);
  }
}

@media (max-width: 1199px) {
  .inSlider {
    width: calc(100% + ((100vw - 937px) / 2));
  }
}

@media (max-width: 991px) {
  .inSlider {
    width: calc(100% + ((100vw - 660px) / 2));
  }
}

@media (max-width: 768px) {
  .inSlider {
    width: calc(100% + ((100vw - 479px) / 2));
  }
}

@media (max-width: 575px) {
  .inSlider {
    width: calc(100% + 2rem);
  }
}

/* + catalog */

.catalog {
  margin-bottom: 1rem;
}

.catalog .img-wrap {
  max-width: 392px;
  width: 100%;
  padding-top: 93%;
  display: block;
  margin: 0 auto;
}

.catalog .img-wrap:before {
  content: "";
  width: 77%;
  padding-top: 76.4%;
  height: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50%;
  right: -50%;
  margin: auto;
  border-radius: 100%;
  border: 2px solid #EDEEF2;
  transition: 0.3s;
}

.catalog .img-wrap img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: "object-fit: contain;";
  transition: 0.3s;
}

.catalog .img-wrap img:last-child {
  opacity: 0;
}

.catalog .img-wrap img:first-child {
  opacity: 1;
}

.catalog .item {
  position: relative;
  margin-bottom: 6rem;
}

.catalog .item .abs:hover ~ .img-wrap:before {
  background-color: #EDEEF2;
}

.catalog .item .abs:hover ~ img:first-child {
  opacity: 0;
}

.catalog .item .abs:hover ~ img:last-child {
  opacity: 1;
}

.catalog .item .abs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.catalog .item .text {
  position: absolute;
  bottom: 120px;
  left: 0;
  max-width: 280px;
}

@media (max-width: 575px) {
  .catalog {
    margin-bottom: 0rem;
  }

  .catalog .item {
    margin-bottom: 3rem;
  }

  .catalog .item .text {
    bottom: 56px;
    font-size: 0.75rem;
  }

  .catalog .item .h2 {
    margin-bottom: 0.5rem !important;
  }

  .catalog .img-wrap {
    padding-top: 378px;
  }

  .catalog .img-wrap:before {
    width: 296px;
    padding-top: 296px;
  }
}

/* - catalog */

/* + breadcrumb */

.breadcrumb {
  margin: 7.5rem 0 3rem;
  background-color: transparent;
  padding: 0 0 0 18px;
  position: relative;
}

.breadcrumb:before {
  content: "";
  background: url("./../img/arrBottom-gray.svg") no-repeat center;
  background-size: contain;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(90deg);
}

.breadcrumb .breadcrumb-item a {
  display: block;
  font-family: "PT Root UI bold";
  font-size: 0.75rem;
  color: #CBCCD7;
}

@media (max-width: 767px) {
  .breadcrumb {
    margin: 6rem 0 1.5rem;
  }
}

/* - breadcrumb */

/* + card */

.goodsSection {
  margin-bottom: 4.5rem;
}

.goodsSection .img-wrap {
  position: relative;
  width: 424px;
  height: 684px;
  display: block;
  margin: 0 auto;
  z-index: 1;
}

.goodsSection .img-wrap:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: -50%;
  right: -50%;
  z-index: -1;
  width: 536px;
  height: 536px;
  margin: auto;
  border-radius: 100%;
  background-color: #00023A;
}

.goodsSection .img-wrap img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: "object-fit: contain;";
}

.goodsSection .addBasket .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.goodsSection .addBasket .flex:last-child {
  margin: 0;
}

.goodsSection .addBasket .flex .select2-container--bootstrap4 .select2-selection {
  border: 2px solid #EDEEF2;
}

.goodsSection .addBasket .flex .select-wrap {
  width: calc(100% - 152px);
  margin-bottom: 1rem;
  order: 1;
}

.goodsSection .addBasket .flex .select-wrap.hidden-select ~ .quantity .input-group {
  margin-bottom: 0;
}

.goodsSection .addBasket .flex .select-wrap ~ .quantity {
  order: 2;
  margin: 0;
}

.goodsSection .addBasket .flex .select-wrap ~ .quantity .input-group {
  margin-bottom: 1.5rem;
}

.goodsSection .addBasket .flex .select-wrap ~ .roboto32 {
  order: 3;
  margin: 0;
  max-width: 216px;
}

.goodsSection .addBasket .flex .select-wrap ~ .btn {
  order: 4;
  width: 296px;
}

.goodsSection .addBasket .flex .roboto32 {
  order: 1;
  margin-bottom: 0;
}

.goodsSection .addBasket .flex .quantity {
  order: 2;
  margin: 0 0.5rem;
}

.goodsSection .addBasket .flex .quantity .input-group {
  width: 128px;
  padding: 22px 12px;
}

.goodsSection .addBasket .flex .quantity .btn.btn-outline-secondary {
  padding: 0 !important;
}

.goodsSection .addBasket .flex .btn {
  order: 3;
  padding: 21.5px 1.5rem;
}

.goodsSection .officialDealer {
  margin-top: 3rem;
  border-top: 2px solid #EDEEF2;
  border-bottom: 2px solid #EDEEF2;
  padding: 1.5rem 0 20px;
}

.goodsSection .officialDealer .link {
  display: inline-flex;
  align-items: center;
  line-height: 1.5;
}

.goodsSection .officialDealer .link .ic {
  margin-right: 0.5rem;
}

@media (max-width: 1199px) {
  .goodsSection .img-wrap {
    width: 291px;
    height: 446px;
  }

  .goodsSection .img-wrap:before {
    width: 369px;
    height: 369px;
  }
}

@media (max-width: 991px) {
  .goodsSection .img-wrap {
    width: 248px;
    height: 381px;
    margin-bottom: 1.5rem;
  }

  .goodsSection .img-wrap:before {
    width: 292px;
    height: 292px;
  }
}

@media (max-width: 767px) {
  .goodsSection {
    margin-bottom: 3rem;
  }

  .goodsSection .addBasket {
    margin-top: 3rem;
  }

  .goodsSection .addBasket .flex .select-wrap {
    width: 100%;
  }

  .goodsSection .addBasket .flex .select-wrap ~ .roboto32 {
    margin: 0 0 1.5rem;
  }

  .goodsSection .addBasket .flex .select-wrap ~ .btn {
    width: 100%;
  }

  .goodsSection .addBasket .flex .quantity {
    margin: 0 0 1.5rem;
    order: 1;
  }

  .goodsSection .addBasket .flex .roboto32 {
    order: 2;
    margin: 0 0 1.5rem;
  }

  .goodsSection .addBasket .flex .btn {
    width: 100%;
  }
}

.infoBox {
  margin-bottom: 4.5rem;
}

.infoBox .col-lg-6 {
  margin-bottom: 1.5rem;
}

.infoBox .img-wrap {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.infoBox .img-wrap img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.infoBox .infoBox__text {
  padding-left: 40px;
  height: 432px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding-right: 1.5rem;
  word-break: break-word;
}

.infoBox .infoBox__text:before {
  content: "";
  border-bottom: 1px solid #CBCCD7;
  border-top: 1px solid #CBCCD7;
  border-right: 1px solid #CBCCD7;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: auto;
  right: 0;
  width: calc(((100vw - 1384px - 80px) / 2) + 1384px);
  z-index: -1;
}

.infoBox .infoBox__text p {
  max-width: 535px;
}

.infoBox .item-wrap:before {
  content: "";
  position: absolute;
  background: url("./../img/logo-gray.svg") no-repeat center;
  background-size: contain;
  width: 567px;
  height: 536px;
  left: calc(100% - 283px);
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
}

.infoBox .item-wrap .item {
  margin: 3rem 0;
  max-width: 536px;
}

@media (max-width: 1199px) {
  .infoBox .infoBox__text {
    padding-left: 0px;
    height: 385px;
  }

  .infoBox .item-wrap:before {
    width: 424px;
    height: 400px;
    left: calc(100% - 200px);
  }

  .infoBox .item-wrap .item {
    margin: 1.5rem 0;
  }

  .infoBox .item-wrap .item .h2 {
    margin-bottom: 0.5rem !important;
  }
}

@media (max-width: 991px) {
  .infoBox .infoBox__text {
    height: 289px;
  }
}

@media (max-width: 767px) {
  .infoBox {
    margin-bottom: 0;
  }

  .infoBox .infoBox__text {
    height: auto;
  }

  .infoBox .infoBox__text:before {
    display: none;
  }

  .infoBox .col-lg-6 {
    margin-bottom: 3rem;
  }

  .infoBox .col-lg-6.order1 {
    order: 1;
  }

  .infoBox .col-lg-6.order2 {
    order: 2;
  }

  .infoBox .col-lg-6.order3 {
    order: 3;
  }

  .infoBox .col-lg-6.order4 {
    order: 4;
  }

  .infoBox .col-lg-6.indentMb {
    margin-bottom: 1.5rem;
  }

  .infoBox .item-wrap:before {
    bottom: 30px;
    width: 212px;
    left: auto;
    right: calc((100vw - 507px) / (-2));
    background-size: cover;
    background-position: left;
  }

  .infoBox .item-wrap .item {
    margin: 0 0 1.5rem;
  }

  .infoBox .item-wrap .item:last-child {
    margin-bottom: 0;
  }

  .infoBox .item-wrap .item .h2 {
    margin-bottom: 1rem !important;
  }
}

@media (max-width: 575px) {
  .infoBox .item-wrap:before {
    right: -1rem;
  }
}

.nextCard {
  position: relative;
  background-image: url(./../img/pipples2.png);
  background-color: #00023A;
  padding: 4.5rem 7rem;
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.nextCard:hover {
  background-color: #282A66;
}

.nextCard .h2 {
  color: #fff;
  margin: 0 0 0 1.5rem !important;
  width: calc(100% - 4.5rem);
}

.nextCard .h2 span {
  font-size: 1rem;
  width: 100%;
  display: block;
  font-family: "PT Root UI bold";
  margin-bottom: 0.5rem;
}

.nextCard .abs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

@media (max-width: 991px) {
  .nextCard {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (max-width: 575px) {
  .nextCard {
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }
}

/* - card */

/* + preOrder */

.preOrderSection {
  margin-top: 11rem;
}

.preOrderSection h2 {
  line-height: 1.5;
  margin-bottom: 3rem !important;
}

@media screen and (max-width: 767px) {
  .preOrderSection {
    margin-top: 7.75rem;
  }

  .preOrderSection h2 {
    margin-bottom: 1.5rem !important;
  }
}

.preOrder {
  max-width: 424px;
  margin: 0 auto;
}

.preOrder .quantity.text {
  margin-bottom: 0;
  padding: 0;
}

.preOrder .item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.preOrder .item .select2-container--bootstrap4 .select2-selection--single {
  line-height: 1;
  padding: 14px 2.25rem 14px 1.5rem;
  height: 3rem !important;
  border: 2px solid #EDEEF2;
}

.preOrder .item .select-wrap {
  width: calc(100% - 152px);
}

.preOrder .item .quantity .input-group {
  width: 128px;
}

.preOrder .item p {
  margin-bottom: 0;
}

.preOrder .item .form-group {
  width: 100%;
  margin-bottom: 1.5rem;
}

.preOrder .item .form-group:last-child {
  margin: 0;
}

.preOrder .text {
  padding: 1rem 0;
  margin-bottom: 1.5rem;
}

.preOrder .btn.btn-primary {
  width: 100%;
  margin-bottom: 1.5rem;
}

.preOrder hr {
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 767px) {
  .preOrder .item {
    margin-bottom: 1rem;
  }

  .preOrder .item .select-wrap {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .preOrder hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .preOrder .text {
    padding: 0;
  }
}

/* - preOrder */

/* + buy */

.subSection .d-flex {
  justify-content: space-between;
}

.subSection .successEl {
  text-align: left;
  margin-top: 1.5rem;
}

.subSection .successEl:first-child {
  margin-top: 0;
}

.subSection .successEl span {
  margin-left: auto;
  margin-right: 2.25rem;
}

@media (max-width: 991px) {
  .subSection .d-flex {
    flex-wrap: wrap;
  }
}

.addressSection {
  position: relative;
}

.addressSection .tipBox {
  max-width: calc(100vw - 70px);
  min-width: 290px;
}

@media (max-width: 991px) {
  .addressSection .tipBox {
    max-width: 460px;
  }
}

.partnersBox {
  width: 424px;
  background-color: #00023A;
  padding: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 456px;
}

.partnersBox h2 {
  color: #fff;
}

.partnersBox p {
  color: #fff;
  text-align: center;
}

.partnersBox .btn {
  margin-top: 3rem;
}

@media (max-width: 1199px) {
  .partnersBox {
    width: 376px;
    padding: 1.5rem;
  }
}

@media (max-width: 991px) {
  .partnersBox {
    width: 100%;
    margin-bottom: 3rem;
    padding: 3rem 1rem;
    height: auto;
  }

  .partnersBox .btn {
    margin-top: 2rem;
  }
}

.subForm {
  padding: 3rem;
  height: 456px;
  width: calc(100% - 424px - 1.5rem);
  background-color: #EDEEF2;
  background-image: url("./../img/ripples.png");
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}

.subForm h2 {
  margin-bottom: 0.5rem !important;
}

.subForm .form-wrap {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.subForm .form-wrap .form-group {
  margin: 0;
  width: calc(100% - 160px - 1.5rem);
}

.subForm .form-wrap .signature {
  margin-top: 1.5rem;
  margin-bottom: 0;
  color: #292A3D;
  width: 100%;
}

.subForm .form-wrap .signature a {
  font-family: "PT Root UI bold";
  color: inherit;
}

@media (max-width: 1199px) {
  .subForm {
    width: calc(100% - 376px - 1.5rem);
    padding: 1.5rem;
  }
}

@media (max-width: 991px) {
  .subForm {
    width: 100%;
    padding: 3rem 2rem;
  }

  .subForm .form-wrap .form-group {
    width: calc(100% - 160px - 0.5rem);
  }
}

@media (max-width: 575px) {
  .subForm {
    min-width: calc(100% + 4rem);
    margin-left: -2rem;
    display: block;
    height: auto;
  }

  .subForm h2 {
    margin-bottom: 1.5rem !important;
  }

  .subForm .form-wrap {
    margin-top: 1.5rem;
  }

  .subForm .form-wrap .form-group {
    width: 100%;
    margin-bottom: 1rem;
  }

  .subForm .form-wrap .btn.btn-primary {
    width: 100%;
    max-width: 350px;
  }
}

.tabEl {
  z-index: 3;
  position: relative;
  background-color: #fff;
  padding: 3rem 3rem 2.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 3rem auto 0;
  box-shadow: 0px 12px 24px rgba(0, 2, 58, 0.14);
}

.tabEl .nav {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.tabEl .nav li {
  margin-left: 1.5rem;
}

@media (max-width: 767px) {
  .tabEl {
    margin: 1.5rem auto 0;
    padding: 1.5rem;
  }
}

@media (max-width: 575px) {
  .tabEl {
    flex-wrap: wrap;
  }

  .tabEl .select2-container--bootstrap4 {
    width: 100%;
    margin-bottom: 1rem;
  }

  .tabEl .nav {
    width: 100%;
    justify-content: space-between;
  }

  .tabEl .nav li {
    margin: 0;
    width: calc(50% - 12px);
  }

  .tabEl .nav li .boxBtn {
    width: 100%;
  }
}

.addressList .item {
  position: relative;
  padding: 3rem 0;
}

.addressList .item:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(100% + (100vw - 1337px));
  left: calc((100vw - 1337px) / (-2));
  height: 2px;
  background-color: #EDEEF2;
}

.addressList .item p {
  margin: 0;
}

.addressList .item a {
  display: table;
  color: #85869D;
  font-family: "PT Root UI bold";
}

@media (max-width: 1400px) {
  .addressList .item:before {
    width: calc(100% + 4rem);
    left: -2rem;
  }
}

@media (max-width: 1199px) {
  .addressList .item:before {
    width: calc(100% + (100vw - 953px));
    left: calc((100vw - 953px) / (-2));
  }
}

@media (max-width: 991px) {
  .addressList .item:before {
    width: calc(100% + (100vw - 674px));
    left: calc((100vw - 674px) / (-2));
  }
}

@media (max-width: 767px) {
  .addressList .item:before {
    width: calc(100% + (100vw - 495px));
    left: calc((100vw - 495px) / (-2));
  }
}

@media (max-width: 576px) {
  .addressList {
    margin-top: 1.5rem;
  }

  .addressList .item {
    padding: 1.5rem 0;
  }

  .addressList .item:before {
    width: calc(100% + 4rem);
    left: -2rem;
  }
}

.mapBox,
#map {
  width: 100%;
  height: 648px;
  margin-top: -3rem;
  margin-bottom: -4.5rem;
}

@media (max-width: 767px) {
  .mapBox,
  #map {
    margin-top: -1.5rem;
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .mapBox,
  #map {
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }
}

/* - buy */

/* + blog */

.blogSection {
  padding: 3rem 0;
  margin: 0;
  position: relative;
  border-bottom: 2px solid #EDEEF2;
}

.blogSection .absLink {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.blogSection:last-child {
  margin-bottom: 3rem;
  border-bottom: 0;
}

.blogSection:nth-child(even) .img-wrap {
  order: 2;
  margin: 0 0 0 1.5rem;
}

.blogSection:nth-child(even) .textBlog {
  order: 1;
}

.blogSection .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-wrap: nowrap;
}

.blogSection .img-wrap {
  position: relative;
  width: 648px;
  height: 432px;
  margin-right: 1.5rem;
}

.blogSection .img-wrap img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.blogSection .textBlog {
  width: calc(100% - 648px - 1.5rem);
}

.blogSection .textBlog .h2 {
  display: inline-block;
  margin-bottom: 3rem !important;
}

.blogSection .textBlog .h2 span {
  font-size: 0.75rem;
  font-family: "PT Root UI lightn";
  display: block;
  margin-bottom: 0.5rem;
}

.blogSection .textBlog .text {
  margin-bottom: 3rem;
  height: 81px;
  overflow: hidden;
}

.blogSection .textBlog .text p {
  margin: 0;
}

@media (max-width: 1199px) {
  .blogSection .img-wrap {
    width: 376px;
  }

  .blogSection .textBlog {
    width: calc(100% - 376px - 1.5rem);
  }
}

@media (max-width: 767px) {
  .blogSection {
    padding: 0;
    margin-bottom: 1.5rem;
    border: none;
  }

  .blogSection:nth-child(even) .img-wrap {
    order: 1;
    margin: 0;
  }

  .blogSection:nth-child(even) .textBlog {
    order: 2;
  }

  .blogSection .flex {
    flex-wrap: wrap;
    box-shadow: 0px 4px 8px rgba(62, 63, 78, 0.1);
  }

  .blogSection .img-wrap {
    width: 100%;
    margin: 0;
    height: auto;
    padding-top: 49%;
  }

  .blogSection .textBlog {
    width: 100%;
    padding: 1.5rem;
  }

  .blogSection .textBlog .text {
    display: none;
  }

  .blogSection .textBlog .h2 {
    margin-bottom: 1.5rem !important;
    width: 100%;
  }
}

/* - blog */

/* + article */

.article .img-wrap {
  width: 100%;
  height: 432px;
  position: relative;
  margin-bottom: 1.5rem;
}

.article .img-wrap img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.article .text-secondary {
  margin-bottom: 1.5rem;
}

@media (max-width: 767px) {
  .article .img-wrap {
    width: 100%;
    height: auto;
    padding-top: 49%;
  }
}

.static {
  max-width: 872px;
  margin: 0 auto 0;
}

.static img {
  margin: 1.5rem 0;
  max-width: 100%;
}

.static strong {
  font-family: "PT Root UI bold";
}

.static .btn {
  margin: 3rem auto;
  display: table;
}

.social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.social .text-secondary {
  margin: 0;
}

.social .icBtn {
  margin-left: 1.5rem;
}

@media (max-width: 767px) {
  .social .icBtn {
    margin-left: 1rem;
    min-width: 3rem;
  }
}

@media (max-width: 350px) {
  .social {
    flex-wrap: wrap;
  }

  .social .text-secondary {
    width: 100%;
    margin-bottom: 1rem;
    text-align: right;
  }
}

/* - article */

/* + partnership */

.coopSection {
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
}

.coopSection:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: calc((100vw - 1300px) / 2);
  min-width: 3rem;
  background-color: #EDEEF2;
  height: calc(100% - 12rem);
  z-index: -1;
  background-image: url("./../img/ripples.png");
  margin: auto;
}

.coopSection .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.coopSection .flex:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: #EDEEF2;
  height: calc(100% - 12rem);
  z-index: -1;
  background-image: url("./../img/ripples.png");
  margin: auto;
}

@media (max-width: 1199px) {
  .coopSection:before {
    display: none;
  }

  .coopSection .flex {
    padding: 2.5rem 0;
  }

  .coopSection .flex:before {
    height: 100%;
    width: 75%;
    left: 0;
    right: auto;
  }
}

@media (max-width: 767px) {
  .coopSection {
    margin-bottom: 3rem;
  }

  .coopSection .flex {
    padding: 3rem 0 0;
    flex-wrap: wrap;
  }

  .coopSection .flex:before {
    width: 100%;
    height: 60%;
    bottom: auto;
  }
}

@media (max-width: 575px) {
  .coopSection .flex:before {
    width: calc(100% + 4rem);
    left: -2rem;
  }
}

.textPar {
  width: calc(100% - 648px);
  padding: 0 1.5rem 0 3rem;
}

.textPar h2 {
  margin-bottom: 4rem !important;
}

.textPar ul {
  padding: 0;
  margin: 0;
}

.textPar ul li {
  list-style-type: none;
  position: relative;
  margin-bottom: 1rem;
}

.textPar ul li:last-child {
  margin-bottom: 0;
}

.textPar ul li:before {
  content: "-";
  display: inline;
  font-size: inherit;
  margin-right: 6px;
}

@media (max-width: 1199px) {
  .textPar {
    width: calc(100% - 376px);
    padding: 0 1.5rem 0 1.5rem;
  }

  .textPar h2 {
    margin-bottom: 2rem !important;
  }
}

@media (max-width: 991px) {
  .textPar {
    width: calc(100% - 296px);
  }
}

@media (max-width: 767px) {
  .textPar {
    width: 100%;
  }

  .textPar h2 {
    margin-bottom: 1.5rem !important;
  }
}

@media (max-width: 575px) {
  .textPar {
    padding: 0;
  }
}

.imgPar {
  position: relative;
  width: 648px;
  height: 648px;
}

.imgPar img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

@media (max-width: 1199px) {
  .imgPar {
    width: 376px;
    height: 376px;
  }
}

@media (max-width: 991px) {
  .imgPar {
    width: 296px;
    height: 296px;
  }
}

@media (max-width: 767px) {
  .imgPar {
    margin: 3rem auto 0;
  }
}

.aboutSection {
  position: relative;
  z-index: 1;
}

.aboutSection:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: calc((100vw - 1300px) / 2);
  border-top: 2px solid #EDEEF2;
  border-bottom: 2px solid #EDEEF2;
  z-index: -1;
  margin: auto;
  height: calc(100% - 12rem);
  min-width: 3rem;
}

.aboutSection .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.aboutSection .flex:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: calc(100% - 12rem);
  z-index: -1;
  margin: auto;
  border: 2px solid #EDEEF2;
  border-left: 0;
}

@media (max-width: 1199px) {
  .aboutSection:before {
    width: calc((100vw - 930px) / 2);
    height: 100%;
  }

  .aboutSection .flex {
    padding: 2.5rem 0;
  }

  .aboutSection .flex:before {
    height: 100%;
  }
}

@media (max-width: 991px) {
  .aboutSection:before {
    width: calc((100vw - 656px) / 2);
  }
}

@media (max-width: 767px) {
  .aboutSection:before {
    display: none;
  }

  .aboutSection .imgPar {
    display: none;
  }

  .aboutSection .flex {
    padding: 0;
  }

  .aboutSection .flex:before {
    display: none;
  }
}

.uspSection {
  border-top: 2px solid #EDEEF2;
  border-bottom: 2px solid #EDEEF2;
  padding: 6rem 0;
}

.uspSection .flex {
  display: flex;
  flex-wrap: wrap;
}

.uspSection .flex .item {
  width: calc(33.3% - 1rem);
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  margin-right: 1.5rem;
}

@media screen and (min-width: 1200px) {
  .uspSection .flex .item:nth-child(3n) {
    margin-right: 0;
  }
}

.uspSection .flex .item img {
  width: 4.5rem;
  height: 4.5rem;
  object-fit: contain;
  font-family: "object-fit: contain;";
  margin-bottom: 1.5rem;
}

.uspSection .flex .item .text {
  margin: 0;
}

@media screen and (max-width: 1199px) {
  .uspSection {
    padding: 1.5rem 0;
  }

  .uspSection .flex .item {
    width: calc(50% - 12px);
  }

  .uspSection .flex .item:nth-child(2n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .uspSection {
    padding: 3rem 0;
  }

  .uspSection .container {
    max-width: 100%;
    padding: 0 !important;
  }

  .uspSection .flex {
    display: block;
  }

  .uspSection .flex .item {
    width: 296px;
    margin: 0;
  }

  .uspSection .owl-dots {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 575px) {
  .uspSection {
    border: 0;
    position: relative;
  }

  .uspSection:before {
    content: "";
    width: calc(100% - 4rem);
    height: 2px;
    background-color: #EDEEF2;
    left: 2rem;
    top: 0;
    position: absolute;
  }

  .uspSection:after {
    content: "";
    width: calc(100% - 4rem);
    height: 2px;
    background-color: #EDEEF2;
    left: 2rem;
    bottom: 0;
    position: absolute;
  }
}

@media screen and (max-width: 350px) {
  .uspSection .owl-dots .owl-dot {
    margin: 0 1rem;
  }
}

.contactsSection .flex {
  display: flex;
  justify-content: space-between;
}

.contactsSection .flex .item {
  width: calc(50% - 12px);
  padding: 4rem 3rem;
}

.contactsSection .flex .item.black {
  background-color: #00023A;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}

.contactsSection .flex .item.black h2,
.contactsSection .flex .item.black p {
  color: #fff;
}

.contactsSection .flex .item.black a {
  color: #CBCCD7;
  font-family: "PT Root UI bold";
  display: table;
}

.contactsSection .flex .item.black h2 {
  margin-bottom: 2rem !important;
}

.contactsSection .flex .item.black .text {
  margin-bottom: 0;
}

.contactsSection .flex .item.black .signature {
  margin-bottom: 3rem;
}

.contactsSection .flex .item.white {
  border: 1px solid #EDEEF2;
}

.contactsSection .flex .item.white a {
  color: #85869D;
  font-family: "PT Root UI bold";
  display: table;
  line-height: 1.5;
}

.contactsSection .flex .item.white h2 {
  margin-bottom: 1.5rem !important;
  line-height: 1.5;
}

.contactsSection .flex .item.white .text {
  margin-bottom: 0;
  line-height: 1.5;
}

.contactsSection .flex .item.white .ul {
  margin: 1.5rem 0 0 0;
  padding: 0;
}

.contactsSection .flex .item.white .ul li {
  list-style-type: none;
  color: #292A3D;
}

@media screen and (max-width: 1199px) {
  .contactsSection .flex .item {
    padding: 4rem 2rem;
  }
}

@media screen and (max-width: 991px) {
  .contactsSection .flex .item {
    padding: 3rem 1.5rem;
  }
}

@media screen and (max-width: 767px) {
  .contactsSection .flex {
    flex-wrap: wrap;
  }

  .contactsSection .flex .item {
    width: 100%;
  }

  .contactsSection .flex .item.black {
    margin-bottom: 1.5rem;
  }

  .contactsSection .flex .item.black h2 {
    margin-bottom: 3rem !important;
  }
}

/* partnership */

/* + textSection */

.textSection ul li {
  list-style-type: none;
  margin-bottom: 1rem;
}

.textSection ol {
  counter-reset: item;
  padding: 0;
  margin: 0 0 1rem;
}

.textSection ol li {
  display: block;
}

.textSection ol li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

/* - textSection */

/* + section404 */

.section404 {
  padding: 7.5rem 0 0;
  text-align: center;
}

.section404 .img-wrap {
  display: inline-block;
  margin-bottom: 3rem;
}

.section404 .img-wrap img {
  max-width: 100%;
}

.section404 .img-wrap hr {
  border-top: 1px solid #00023A !important;
  margin: 0;
}

.section404 h1 {
  width: 100%;
  margin-bottom: 1.5rem !important;
}

.section404 p {
  width: 100%;
  margin-bottom: 4rem;
  color: #85869D;
}

@media screen and (max-width: 1199px) {
  .section404 .img-wrap {
    margin-bottom: 1.5rem;
  }

  .section404 p {
    margin-bottom: 3rem;
  }
}

/* - section404 */

/* + section500 */

.section500 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  min-height: 100vh;
  padding: 3rem 0;
}

.section500 .logo {
  width: 372px;
  height: 80px;
  background: url("./../img/logo-full.svg") no-repeat center;
  background-size: contain;
  display: inline-block;
}

.section500 .logo-wrap {
  width: 100%;
  text-align: center;
}

.section500 .grayBox {
  margin: 3rem 0;
  background-image: url("./../img/ripples.png");
  background-color: #EDEEF2;
  width: 100%;
  max-width: 648px;
  height: 336px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.section500 .grayBox h2 {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem !important;
}

.section500 .grayBox p {
  margin: 0;
}

.section500 .textBox {
  width: 100%;
  text-align: center;
}

.section500 .textBox a {
  display: table;
  font-family: "PT Root UI bold";
  color: #85869D;
  margin: 1.5rem auto 0;
}

@media screen and (max-width: 1199px) {
  .section500 .logo {
    width: 228px;
    height: 48px;
  }
}

@media screen and (max-width: 767px) {
  .section500 .grayBox {
    height: 192px;
  }
}

@media screen and (max-width: 575px) {
  .section500 {
    flex-direction: column;
    justify-content: space-around;
    align-items: normal;
    flex-wrap: nowrap;
  }

  .section500 .grayBox {
    width: calc(100% + 4rem);
    margin-left: -2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .section500 .logo {
    width: 190px;
    height: 40px;
  }
}

/* - section500 */

/* + plugSection */

.plugSection {
  height: 100vh;
  background-color: #00023A;
  width: 100%;
  margin: 0;
}

.plugSection .logo-wrap {
  text-align: center;
  height: calc(100% - 96px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.plugSection .logo {
  width: 372px;
  height: 80px;
  background: url("./../img/logo.svg") no-repeat center;
  background-size: contain;
  display: inline-block;
}

.plugSection .plug-f {
  background-color: #fff;
  padding: 24px 0;
  text-align: center;
}

.plugSection .plug-f .icBtn {
  margin: 0 12px;
}

@media screen and (max-width: 1199px) {
  .plugSection .logo {
    width: 228px;
    height: 48px;
  }
}

@media screen and (max-width: 767px) {
  .plugSection .logo {
    width: 190px;
    height: 40px;
  }
}

/* - plugSection */

/* + ----------- static*/

.static p {
  word-break: break-word;
}

.static .iframe-wrap {
  position: relative;
  width: 100%;
  padding-top: 56%;
  margin-bottom: 1rem;
}

.static .iframe-wrap iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.static #button {
  text-align: center;
  min-width: 160px;
  background-color: #00023A;
  font-size: 1rem;
  padding: 1.1875rem 1.5rem;
  color: #fff;
  display: table;
  margin: 2rem auto 3rem;
  font-family: "PT Root UI bold";
  white-space: nowrap;
}

.static table {
  margin-bottom: 12px;
}

.static .table-img.table-text,
.static .table-text {
  display: flex;
  width: calc(100% + 24px);
  margin-left: -12px;
}

.static .table-img.table-text tbody,
.static .table-text tbody {
  width: 100%;
}

.static .table-img.table-text td,
.static .table-text td {
  border: 12px;
  position: relative;
}

.static .table-img.table-text img,
.static .table-text img {
  position: relative;
  width: calc(100% - 24px);
  margin: auto;
  display: block;
}

.static .table-img.table-text p,
.static .table-text p {
  width: calc(100% - 24px);
  margin: 0 auto 1rem;
  display: block;
}

.static .table-img.table-text p:last-child,
.static .table-text p:last-child {
  margin-bottom: 0;
}

.static .table-img.table-text tr,
.static .table-text tr {
  display: flex !important;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.static .table-img.table-text tr:last-child,
.static .table-text tr:last-child {
  margin: 0;
}

.static .table-img.table-text tr td,
.static .table-text tr td {
  width: 50%;
  padding-top: 0;
}

.static .table-img {
  display: flex;
  width: calc(100% + 24px);
  margin-left: -12px;
}

.static .table-img tbody {
  width: 100%;
}

.static .table-img td {
  border: 12px;
  position: relative;
}

.static .table-img img {
  object-fit: cover;
  font-family: "object-fit: cover;";
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  margin-left: 0;
  margin-right: 0;
}

.static .table-img tr {
  display: flex !important;
}

.static .table-img .one-img td {
  width: 100%;
  padding-top: 100%;
}

.static .table-img .two-img td {
  width: 50%;
  padding-top: 50%;
}

.static .table-img .three-img td {
  width: 33.3%;
  padding-top: 33.3%;
}

.static .table-img .four-img td {
  width: 25%;
  padding-top: 25%;
}

@media (max-width: 991px) {
  .static #button {
    margin: 0.5rem auto 1.5rem;
  }
}

@media (max-width: 767px) {
  .static .table-img.table-text,
  .static .table-text {
    margin-left: 0;
    width: 100%;
  }

  .static .table-img.table-text tr,
  .static .table-text tr {
    overflow: initial;
    flex-wrap: wrap;
  }

  .static .table-img.table-text tr.one-img,
  .static .table-img.table-text tr.two-img,
  .static .table-img.table-text tr.three-img,
  .static .table-img.table-text tr.four-img,
  .static .table-text tr.one-img,
  .static .table-text tr.two-img,
  .static .table-text tr.three-img,
  .static .table-text tr.four-img {
    flex-wrap: nowrap;
    overflow: auto;
  }

  .static .table-img.table-text tr td,
  .static .table-text tr td {
    width: 100%;
  }

  .static .table-img.table-text tr td img,
  .static .table-text tr td img {
    margin-bottom: 1rem;
  }

  .static .table-img.table-text tr td p,
  .static .table-text tr td p {
    width: 100%;
  }

  .static .table-img {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .static .table-img tr {
    display: flex;
    max-width: calc(100vw - 4rem);
    margin-right: auto;
    margin-left: auto;
    overflow: auto;
  }

  .static .table-img tr td:first-child {
    margin-left: -12px;
  }

  .static .table-img .one-img td {
    min-width: 100%;
    padding-top: 100%;
    margin-left: 0 !important;
  }

  .static .table-img .one-img td img {
    left: 0;
    right: 0;
    width: 100%;
  }

  .static .table-img .two-img td,
  .static .table-img .three-img td {
    min-width: 280px;
    padding-top: 280px;
  }

  .static .table-img .two-img td:last-child,
  .static .table-img .three-img td:last-child {
    min-width: calc(280px - 12px);
  }

  .static .table-img .two-img td:last-child img,
  .static .table-img .three-img td:last-child img {
    left: auto;
    width: calc(100% - 12px);
  }

  .static .table-img .four-img td {
    min-width: 200px;
    padding-top: 200px;
  }

  .static .table-img .four-img td:last-child {
    min-width: calc(200px - 12px);
  }

  .static .table-img .four-img td:last-child img {
    left: auto;
    width: calc(100% - 12px);
  }
}

/* - ----------- static*/

.table-img a {
  display: block;
}

#instafeed img {
  width: 200px;
  height: 200px;
}

/* + basket */

.emptyBasket {
  padding-top: 1.5rem;
  min-height: calc(100vh - 726px);
}

.emptyBasket .flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.emptyBasket .flex p {
  width: 100%;
  text-align: center;
  line-height: 96px;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .emptyBasket {
    min-height: auto;
  }
}

.close {
  width: 1.5rem;
  height: 1.5rem;
  min-width: 16px;
  background: url("./../img/cross-ic.svg") no-repeat center;
  background-size: contain;
  background-size: 16px;
  display: block;
  opacity: 1;
}

.goodsBox {
  margin-bottom: 4.5rem;
}

.goodsBox .goods-h {
  display: flex;
  align-items: center;
  padding: 0 2.5rem 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #F7F7F8;
}

.goodsBox .goods-h .text {
  color: #85869D;
  margin: 0;
}

.goodsBox .goods-h .text:nth-child(1) {
  width: 31%;
  margin-left: 20%;
}

.goodsBox .goods-h .text:nth-child(2) {
  width: 18%;
}

.goodsBox .goods-h .text:nth-child(3) {
  width: 18%;
}

.goodsBox .goods-h .text:nth-child(4) {
  width: 13%;
}

.goodsBox .goods-item {
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(62, 63, 78, 0.1);
  background-color: #fff;
  padding: 1.5rem 2.5rem;
  margin-bottom: 1.5rem;
}

.goodsBox .goods-item:last-child {
  margin: 0;
}

.goodsBox .goods-item p {
  margin-bottom: 0;
  color: #292A3D;
}

.goodsBox .goods-item .img-wrap {
  width: 94px;
  height: 144px;
  margin: 0 calc(15% - 92px) 0 calc(5% - 24px);
}

.goodsBox .goods-item .img-wrap img {
  width: 100%;
  object-fit: contain;
}

.goodsBox .goods-item .wrap {
  width: 31%;
}

.goodsBox .goods-item .wrap .name {
  width: 100%;
  margin-bottom: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2rem;
}

.goodsBox .goods-item .select-wrap {
  width: 100%;
  max-width: 260px;
}

.goodsBox .goods-item .select-wrap .select2-container--bootstrap4 .select2-selection--single {
  height: 3rem !important;
  padding: 14px 2.25rem 14px 1.5rem;
}

.goodsBox .goods-item .price {
  width: 18%;
  padding-right: 1rem;
}

.goodsBox .goods-item .quantity-wrap {
  width: 18%;
}

.goodsBox .goods-item .total-price {
  font-family: "PT Root UI bold";
  width: 13%;
}

.goodsBox .recover-wrap {
  margin-bottom: 1.5rem;
  padding: 1.5rem 0;
}

.goodsBox .recover-wrap p {
  margin-bottom: 0;
}

.goodsBox .recover-wrap .link {
  display: inline-block;
  cursor: pointer !important;
}

@media screen and (max-width: 1199px) {
  .goodsBox .goods-h {
    padding: 0 1.5rem 1.5rem;
  }

  .goodsBox .goods-h .text:nth-child(1) {
    width: 33%;
    margin-left: 18%;
  }

  .goodsBox .goods-item {
    padding: 1.5rem;
  }

  .goodsBox .goods-item .select-wrap {
    max-width: 250px;
  }

  .goodsBox .goods-item .img-wrap {
    margin: 0 calc(13% - 92px) 0 calc(5% - 24px);
  }

  .goodsBox .goods-item .wrap {
    width: 33%;
  }
}

@media screen and (max-width: 991px) {
  .goodsBox .goods-h {
    display: none;
  }

  .goodsBox .recover-wrap {
    padding: 1rem 0;
  }

  .goodsBox .goods-item {
    flex-wrap: wrap;
  }

  .goodsBox .goods-item .close {
    margin-bottom: -3rem;
  }

  .goodsBox .goods-item .img-wrap {
    margin: 0 1.5rem -3rem;
  }

  .goodsBox .goods-item .wrap {
    width: calc(100% - 166px);
  }

  .goodsBox .goods-item .wrap .name {
    padding-right: 0;
    margin-bottom: 1rem;
  }

  .goodsBox .goods-item .price {
    display: none;
  }

  .goodsBox .goods-item .quantity-wrap {
    width: 152px;
    margin-left: 166px;
    margin-top: 0.5rem;
  }

  .goodsBox .goods-item .total-price {
    width: calc(100% - 320px);
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 767px) {
  .goodsBox {
    margin-bottom: 1.5rem;
  }

  .goodsBox .goods-item {
    padding: 1rem;
    position: relative;
    justify-content: space-between;
  }

  .goodsBox .goods-item .close {
    margin-bottom: 0;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .goodsBox .goods-item .img-wrap {
    margin: 0;
    width: 2rem;
    height: 3rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  .goodsBox .goods-item .wrap {
    width: 100%;
  }

  .goodsBox .goods-item .wrap .name {
    padding: 0 2.5rem 0 4rem;
    line-height: 1.5;
    margin: 0 0 1rem;
    min-height: 3rem;
    white-space: normal;
  }

  .goodsBox .goods-item .select-wrap {
    max-width: 100%;
  }

  .goodsBox .goods-item .quantity-wrap {
    width: 120px;
    margin-left: 0;
  }

  .goodsBox .goods-item .total-price {
    width: calc(100% - 144px);
    margin-top: 0.5rem;
    text-align: right;
  }

  .goodsBox .recover-wrap {
    padding: 0;
  }

  .goodsBox .recover-wrap p {
    line-height: 1.5;
  }
}

.navOrder {
  max-width: 648px;
  margin-left: auto;
  margin-right: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 3rem;
  background-image: url(./../img/ripples.png);
  background-color: #EDEEF2;
}

.navOrder .item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.navOrder .item p {
  margin-bottom: 0;
}

.navOrder .item .text-right {
  max-width: 300px;
  line-height: 1.5;
}

.navOrder .item .h2 {
  margin: 0 !important;
  line-height: 1.5;
}

.navOrder .btn {
  width: 100%;
}

.navOrder .signature.center {
  text-align: center;
  margin-bottom: 0;
  line-height: 3rem;
}

.navOrder .signature {
  text-align: right;
  line-height: 1.5rem;
  width: 100%;
  margin-bottom: 0;
}

.navOrder .btn-outline-primary {
  color: #85869D;
  border-color: #CBCCD7;
}

@media screen and (max-width: 1199px) {
  .navOrder {
    max-width: 100%;
    padding: 1.5rem 4.5rem;
  }
}

@media screen and (max-width: 767px) {
  .navOrder {
    max-width: 100%;
    padding: 1.5rem 1.5rem 3rem;
  }

  .navOrder p {
    font-size: 12px;
    line-height: 15px;
  }

  .navOrder .text1 {
    max-width: 100px;
  }

  .navOrder .item .h2 {
    max-width: calc(100% - 100px);
  }

  .navOrder .item .text-right {
    max-width: 56%;
  }
}

/* - basket */

/* + place your order */

.formalizeSection {
  margin-top: 11rem;
}

.formalizeSection h2 {
  margin-bottom: 3rem !important;
}

.formalizeSection .select-wrap {
  max-width: 536px;
}

.formalizeSection .flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.formalizeSection .sContent {
  width: 59%;
  padding-right: 1.5rem;
}

.formalizeSection .sBar {
  width: 41%;
  position: sticky;
  top: 1.5rem;
}

.formalizeSection .select-wrap {
  margin-bottom: 1rem;
}

.formalizeSection .signature.text-secondary {
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.formalizeSection .form-group {
  margin-bottom: 1.5rem;
}

.formalizeSection .form-group.textarea {
  height: 120px;
}

.formalizeSection .form-group textarea {
  resize: none;
  height: calc(100% - 38px);
  margin: 0.5rem 0 0;
}

.formalizeSection .form-group .datePicker {
  border: none;
  width: 100%;
}

.formalizeSection .form-group.fill .datePicker::-webkit-input-placeholder {
  /* Chrome */
  font-size: 0;
}

.formalizeSection .form-group.fill .datePicker:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 0;
}

.formalizeSection .form-group.fill .datePicker::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 0;
}

.formalizeSection .form-group.fill .datePicker:-moz-placeholder {
  /* Firefox 4 - 18 */
  font-size: 0;
}

.formalizeSection .form-group.dp {
  max-width: 182px;
  background-image: url("./../img/calendar.svg");
  background-position: 1rem center;
  background-size: 1.5rem;
  background-repeat: no-repeat;
  padding-left: 3.5rem;
  position: relative;
}

.formalizeSection .form-group.dp.fill .datePicker {
  padding-top: 1.5rem;
}

.formalizeSection .form-group.dp .placeholder {
  margin-bottom: 1.5rem;
}

.formalizeSection .form-group.dp .datePicker {
  padding-left: 8px;
  position: absolute;
  top: 0;
  left: 3px;
  width: calc(100% - 6px);
  height: 100%;
  right: 0;
  text-align: center;
  background: transparent;
}

.formalizeSection hr {
  margin: 3rem 0;
}

.formalizeSection #map {
  background: #ccc;
  margin: 0;
  height: 312px;
  width: 100%;
}

.tabOrder {
  margin-bottom: 3rem;
}

.tabOrder .nav-item {
  margin-right: 1rem;
}

.tabOrder .nav-item:last-child {
  margin-right: 0;
}

.tabOrder .btn {
  padding: 0.6875rem 18px;
  min-width: auto;
  color: #85869D;
  border-color: #EDEEF2;
}

.tabOrder .btn.active {
  background-color: transparent;
  color: #00023A;
  border-color: #00023A;
}

.resultOrder {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 3rem 3.5rem;
  background-image: url(./../img/ripples.png);
  background-color: #EDEEF2;
  color: #292A3D;
}

.resultOrder .item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.resultOrder .item p {
  margin-bottom: 0;
  line-height: 1.5rem;
}

.resultOrder .item h2 {
  margin: 0 !important;
}

.resultOrder .btn {
  width: 100%;
  margin-bottom: 1.5rem;
}

.resultOrder .number-order {
  width: 100%;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #85869D;
  text-align: center;
}

.resultOrder .signature {
  display: block;
  margin-bottom: 0;
  position: relative;
  padding-left: 2rem;
}

.resultOrder .signature .ic {
  margin-right: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: auto;
  bottom: auto;
  width: 1.5rem;
  height: 1.5rem;
}

.resultOrder .signature .bold {
  margin-left: 5px;
  font-family: "PT Root UI bold";
}

.resultOrder .signature .bold:hover {
  color: #00023A;
}

input[disabled] ~ label {
  color: #ccc !important;
}

.selectPayment .item {
  margin-bottom: 1.5rem;
}

.selectPayment .item:last-child {
  margin: 0;
}

.selectPayment .item input {
  display: none;
}

.selectPayment .item input:checked ~ label {
  border: 1px solid #61D1A2;
}

.selectPayment .item input:checked ~ label .round {
  border: none;
  background: url("./../img/check-ic.svg") no-repeat center;
  background-size: contain;
}

.selectPayment .item input:checked ~ .text {
  display: block;
}

.selectPayment .item label {
  padding: 1.5rem 1rem 1.5rem 3rem;
  position: relative;
  border: 2px solid #EDEEF2;
  width: 100%;
  margin: 0;
  display: block;
  line-height: 1.3;
}

.selectPayment .item label.opacity {
  color: #ccc;
}

.selectPayment .item label .round {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid #EDEEF2;
  position: absolute;
  left: 18px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.selectPayment .item .text {
  display: none;
  margin: 0;
  padding: 0.5rem 0;
  line-height: 1.5;
}

.selectPayment .accent {
  margin-bottom: 1.5rem;
  color: #282A66;
}

.yourData .signature {
  margin: 0 0 1.5rem;
  line-height: 1.5;
}

.yourData .accent {
  color: #282A66;
}

.delivery-other-city .text:last-child {
  margin: 0;
}

.formPhone {
  max-width: 424px;
  margin: auto;
}

.formPhone h2 {
  text-align: center;
}

.formPhone .btn {
  width: 100%;
  margin-bottom: 1.5rem;
}

.formPhone .signature {
  display: block;
  margin-bottom: 0;
  position: relative;
  padding-left: 2rem;
}

.formPhone .signature .ic {
  margin-right: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: auto;
  bottom: auto;
  width: 1.5rem;
  height: 1.5rem;
}

.formPhone .signature .bold {
  margin-left: 5px;
  font-family: "PT Root UI bold";
}

.formPhone .signature .bold:hover {
  color: #00023A;
}

.orderSuccess {
  text-align: center;
  min-height: calc(100vh - 582px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.orderSuccess h2 {
  margin-bottom: 1.5rem !important;
  padding: 0.5rem 0;
}

.orderSuccess .text {
  padding: 0.5rem 0;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 1199px) {
  .formalizeSection .sBar {
    width: 32%;
  }

  .formalizeSection .sContent {
    width: 68%;
    padding-right: 1.5rem;
  }

  .resultOrder {
    padding: 3rem 1.5rem 3.5rem;
  }

  .resultOrder .item h2 {
    font-size: 20px !important;
    padding: 12px 0;
  }
}

@media screen and (max-width: 991px) {
  .formalizeSection .sContent {
    width: 65%;
    padding-right: 1rem;
  }

  .formalizeSection .sBar {
    width: 35%;
  }

  .yourData .signature {
    margin-top: -1.5rem;
    padding: 3px 0;
  }
}

@media screen and (max-width: 767px) {
  .col-lg-4 .form-group {
    max-width: 160px;
  }

  .formalizeSection {
    margin-top: 7.75rem;
  }

  .formalizeSection h2 {
    margin-bottom: 1.5rem !important;
  }

  .formalizeSection .flex {
    flex-wrap: wrap;
  }

  .formalizeSection .sBar {
    width: 100%;
  }

  .formalizeSection .sContent {
    width: 100%;
    padding-right: 0;
    margin-bottom: 1.5rem;
  }

  .tabOrder {
    margin-bottom: 1.5rem;
  }

  .resultOrder {
    padding: 3rem 1.5rem 3.5rem;
  }

  .selectPayment .item .text {
    padding: 1rem 0;
    font-size: 12px;
  }

  .formPhone h2 {
    text-align: left;
  }

  .orderSuccess {
    min-height: auto;
  }
}

/* - place your order */

