.form-group {
  position: relative;
  border: 2px solid $gray-300;
  height: 4.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
  transition: $time;
  background-color: #fff;
  &.fill {
    .placeholder {
      display: block;
      font-size: 12px;
    }
    .form-control {
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
    }
  }
  &:hover {
    box-shadow: $shadow;
    border: 2px solid $gray-500;
  }
  .placeholder {
    margin: 0;
    line-height: 1;
    display: none;
    width: 100%;
    color: $gray-700;
  }
  .form-control {
    width: 100%;
    box-shadow: none !important;
    &::-webkit-input-placeholder {
      color: $gray-700;
    }
    &::-moz-placeholder {
      color: $gray-700;
    }
    &:-moz-placeholder {
      color: $gray-700;
    }
    &:-ms-input-placeholder {
      color: $gray-700;
    }
  }
  &.is-invalid {
    border: 2px solid transparent;
    outline: 1px solid $red !important;
    outline-offset: -1px;
    .invalid-feedback {
      display: block;
    }
  }
  &.error {
    border: 2px solid transparent;
    outline: 1px solid $red !important;
    outline-offset: -1px;
    .invalid-feedback {
      display: none;
    }
  }
  .invalid-feedback {
    position: absolute;
    margin: 0;
    top: calc(100%);
    right: 0;
    text-align: right;
    font-size: 0.75rem;
    color: $red;
  }
  &.was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-image: none;
  }
}

/* + select */
.select2-container--bootstrap4{
  padding: 0 0 0.5rem;
}
.select2-container--bootstrap4 .select2-selection--single{
  transition: $time;
  border-color: #F7F7F8;
  &:hover{
    border-color: $gray-500 !important;
    box-shadow: $shadow;
   .select2-selection__arrow b{
      @include icon($url:'../img/arrBottom-blue.svg');
    }
  }

  height: 4.5rem !important;
}
.select2-container--bootstrap4{
  &.select2-container--open{
    .select2-selection__arrow b{
      transform: rotate(180deg);
    }
  }
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow {
  bottom: 0.5rem !important;
  b{
    border: 0 !important;
    @include icon($url:'../img/arrBottom-gray.svg');
    width: 10px !important;
    height: 10px !important;
  }
}

.select2-container--bootstrap4 .select2-dropdown{
  margin-top: -4px !important;
  border: 2px solid $gray-300 !important;
  overflow: hidden;
  box-shadow: $shadow;
}
.select2-container--bootstrap4.select2-container--focus .select2-selection, .select2-container--bootstrap4.select2-container--open .select2-selection{
  border: 1px solid $gray-500 !important;
}
.select2-container--bootstrap4 .select2-results__option {
  padding: 0.5rem 1.5rem !important;
  border-bottom: 2px solid $gray-300 !important;
  &:last-child{
    border: 0 !important;
  }
}
.select2-container--bootstrap4 .select2-results > .select2-results__options {
  max-height: 268px !important;
}
.select2-container--bootstrap4 .select2-results__option--highlighted[aria-selected]{
  background-color: $gray-300 !important;
  color: $black !important;
}

//footer ~ .select2-container--bootstrap4.dropdown-card.select2-container--open{
//  margin-top: -72px !important;
//}
.select2-container--bootstrap4 .select2-results__option[aria-disabled=true] {
  height: 0;
  padding: 0 !important;
  opacity: 0;
  border: 0 !important;
}
.select2-container--bootstrap4.dropdown-card{

  z-index: 999;
  .select2-selection__rendered{
    color: #080A11 !important;

  }
  .nicescroll-rails.nicescroll-rails-vr{
    display: none !important;
  }
  .select2-results__option--highlighted[aria-selected]{
    background-color: #fff !important;
  }
  .select2-results__option[aria-selected=true] {
    background-color: #fff;
  }
  .select2-dropdown{
    border: none !important;
    border-radius: 0 !important;
  }
  .select2-results__options:after {
    content: '';
    position: absolute;
    width: 100%;
    background: #fff;
    height: 1px;
    bottom: 0;
    z-index: 1;
    left: 0;
    right: 0;
    margin: auto;
  }
  .select2-results__option{
    padding: 10px 1rem !important;
    //border-bottom: 1px solid #EDEEF2 !important;
    border-bottom: none !important;
    position: relative;
    color: #080A11 !important;
    transition: $time;
    margin-top: -1px;
    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      width: calc(100% - 2rem);
      left: 0;
      right: 0;
      margin: auto;
      background: #EDEEF2;
      height: 1px;
      z-index: 1;
    }
    &:hover{
      //background-color: $gray-300 !important;
      background-color: transparent !important;
      &:before,&:after{
        //opacity: 0;
      }
    }
    &:last-child{
      border-bottom: 0 !important;
    }
  }
}

//

/* - select */

/* + datepicker */
.datepicker{
  margin-left: -3.5rem;
  border-radius: 0;
  width: 366px;
  font-size: 1rem;

  .datepicker--pointer{
    display: none;
  }
  .datepicker--nav-title{
    font-family: $ui-bold;
    color: $blue;
    font-size: 1rem;
    line-height: 1.5rem;
    i{
      font-size: inherit;
      font-style: normal;
      font-family: inherit;
      line-height: inherit;
      color: inherit;
      font-size: inherit;
    }
    &:hover{
      background-color: transparent!important;
    }
  }
  .datepicker--nav-action{
    background-image: url(../img/play-blue.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
    &[data-action="prev"]{
      transform: rotate(180deg);
    }
    svg{
      display: none;
    }
  }
  .datepicker--nav{
    padding: 1.5rem 1.5rem 1.5rem;
    border: none;
  }
  .datepicker--days-names {
    margin: 0 auto 6px;
    .datepicker--day-name{
      color: $gray-700;
      text-transform: none;
      font-size: 1rem;
      font-family: $font-family-base;
      line-height: 1.5rem;
    }
  }
  .datepicker--content{
    padding: 0 1.5rem calc(2rem - 10px);
  }
  .datepicker--cell{
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    color: $black;
    font-family: $font-family-base;
    font-size: 1rem;
    z-index: 1;
    &.-other-month-{
      color: $gray-600;
    }
    &.-current-{
      color: $red;
      background-color: transparent;
    }
    &.-focus-{
      background-color: transparent;
      &:before{
        content: '';
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
        border-radius: 100%;
        background-color: $gray-300;
      }
    }
    &.-selected-{
      background-color: transparent;
      color: #fff;
      font-family: $ui-bold;
      &:before{
        content: '';
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        right: 0;
        margin: auto;
        z-index: -1;
        border-radius: 100%;
        background-color: $blue-light !important;
      }
    }
  }
}
/* - datepicker */
