.h1, h1, h2.h1{
  font-size: 3.25rem !important;
  font-family: $cg-light;
  margin-bottom: 3rem !important;
}
.h2, h2{
  font-size: 2rem !important;
  font-family: $cg-bold;
  margin-bottom: 1.5rem !important;
  .ic{
    margin: 0px 1rem -3px 0;
  }
}
.roboto32{
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-family: $ui-bold;
}
.title{
  text-align: center;
  padding: 0 2rem 10px;
  margin-top: 11rem;
  margin-bottom: 4.5rem !important;
  position: relative;
  &.text-white{
    &:before{
      content: '';
      background-color: #fff;
    }
  }
  &:before{
    content: '';
    position: absolute;
    top: calc(100%);
    width: 160px;
    height: 2px ;
    background-color: $gray-300;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.accent{
  font-family: $ui-bold;
}
.signature{
  font-size: 0.75rem;
}

/* + table */
.table{
  margin-bottom: 3rem !important;
}
.table th, .table td{

  border-top: none !important;
  border-bottom: 2px solid $gray-300 !important;
  vertical-align: middle !important;
}
.table th{
  font-weight: normal;
  font-size: 0.75rem;
  color: $gray-700;
  padding: 0.625rem 1.5rem 0.625rem 0 !important;
  width: 224px;
}
.table td{
  font-size: 1rem;
  padding: 0.625rem 0 0.625rem 0 !important;
}
.link{
  color: $gray-700;
  display: table;
  font-family: $ui-bold;
  margin: 0;
}
.grey{
  color: $gray-700;
}
/* - table */
/* + .alert-success */
.alert.alert-success{
  padding: 13px 1rem 13px 3rem;
  position: relative;
  font-family: $ui-bold;
  border-radius: 0;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 20px;
  &.alert-success{
    background-color: $green;
    color: #fff;
    background-image: url("../img/error.svg");
    background-repeat: no-repeat;
    background-position: 1rem center;
    background-size: 1.5rem;
  }
}
.consentLink{
  display: block;
  margin-bottom: 0;
  position: relative;
  padding-left: 2rem;
  .ic {
    margin-right: 0.5rem;
    @include absolute;
    right: auto;
    bottom: auto;
    width: 1.5rem;
    height: 1.5rem;
  }
  .bold {
    margin-left: 5px;
    font-family: $ui-bold;
    &:hover {
      color: $blue;
    }
  }
}
/* - .alert-success */
@media screen and (max-width: 767px) {
  .h1, h1, h2.h1{
    font-size: 2rem !important;
    margin-bottom: 1.5rem !important;
  }
  .h2, h2{
    font-size: 1.25rem !important;
  }
  .roboto32{
    font-size: 1.25rem
  }
  .alert.alert-success{
    padding: 10px 1rem 10px 3rem;
    line-height: 1.5;
    font-size: 12px;


  }
}
@media screen and (max-width: 575px) {
  .table{
    margin-bottom: 1.5rem !important;
    th{
      width: 104px;
    }
  }
}