.tipBox{
  display: none;
  width: 536px;
  padding: 1rem 1.5rem;
  box-shadow: $shadow2;
  position: relative;
  &.mobile, &.mobile.active{
    display: none;

  }
  &.mobile.active{

    @media (max-width: 767px) {
      display: block;
      max-width: 476px;
      position: sticky;
      bottom: 0;
      z-index: 2;
      ~.layerMobile{
        @include absolute();
        background: #292A3D;
        display: block;
        z-index: 1;
        opacity: 0.3;
      }
    }
    @media (max-width: 575px) {
      margin-left: -2rem;
      width: 100vw;
      max-width: 100vw;
      ~.layerMobile{
        width: calc(100% + 4rem);
        left: -2rem;
      }
    }
  }
  *{
    overflow-wrap: break-word;
  }
  &.active{
    display: block;
  }
  h2{
    padding: 1rem 4.5rem 0 0;
  }
  .closeBtn{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 3rem;
    height: 3rem;
  }

}
.loader{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: $blue;
  width: 100%;
  height: 100%;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  .h1{
    transition: 0s;
    display: block;
    font-family: $font-family-base;
  }
}