.iframe-wrap{
  position: relative;
  padding-top: 56%;
  width: 100%;
  iframe{
    @include absolute;
  }
}
.modal{
  .closeBtn{
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    z-index: 2;
  }
}

.addProduct{
  position: fixed;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  width: 90%;
  max-width: 360px;
  z-index: 9999;
  background: #FFFFFF;
  box-shadow: 0px 20px 28px rgba(26, 27, 32, 0.1);
  height: 144px;
  display: none;
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #292A3D;
    z-index: -1;
    opacity: .3;
  }
  .flex{
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
    padding: 1.5rem;
    background: #FFFFFF;
  }
  &.active{
    display: block;
  }
  p{
    line-height: 1.5;
    margin-bottom: 0;
  }
  .close-ic{
    opacity: 0.4;
    background-color: transparent;
    border: none;
  }
  .btn{
    width: 100%;
    margin-top: 1.5rem;
  }
  @media screen and (max-width: 767px) {
    .close-ic{
      display: none;
    }
    .flex{
      position: relative;
      padding-top: 3rem;
      &:before{
        content: '';
        width: 4.5rem;
        height: 2px;
        margin: auto;
        position: absolute;
        top: 1rem;
        left: 0;
        right: 0;
        background-color: #F7F7F8;
      }
    }
  }
}